import React from "react";
import { FormattedMessage } from "react-intl";
import { Box, Flex } from "reflexbox";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTable, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { Head } from "./head";

const StyledTable = styled.table`
    border: none;
    border-spacing: 0;
    width: 100%;
`;

const StyledTBody = styled.tbody`
    border: none;
`;

const Cell = styled.td`
    background: #fff;
    box-shadow: inset 0px -1px 0px 0px ${(props) => (props.selected ? "#0090D1" : "rgb(157, 166, 173)")};
    height: 32px;
    max-height: 32px;
    color: rgb(21, 41, 53);
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    padding-right: 8px;
    padding-left: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: ${(props) => props.textAlign};  
    width: ${(props) => props.width ? props.width : "10%"};
`;

const Footer = styled.tfoot`
    ${Cell} {
        background: rgb(225, 240, 246);
        height: 32px;
        width: 100%;
        max-height: 40px;
    }
`;

const Row = styled.tr`
    height: 34px;
    border: none;
`;

const TickIcon = styled(FontAwesomeIcon)`
    color: rgb(0, 144, 209);
    font-family: FontAwesome5Pro-Solid;
    font-size: 16px;
    font-weight: solid;
    height: 32px;
    letter-spacing: 0px;
    line-height: 15px;
    text-align: center;
    width: 32px;
    vertical-align: sub;
`; 

const DivSelected = styled.div`
    display: inline;
    vertical-align: super;
`;

const TableEmptyIcon = styled(FontAwesomeIcon)`
    color: rgb(228, 234, 240);
    font-family: FontAwesome5Pro-Regular;
    font-size: 80px;
    font-weight: normal;
    height: 80px;
    width: 80px;
`;

const OvalIcon = styled.div`
    width: 48px;
    height: 48px;
    background: rgb(0, 80, 117);
    border: 4px solid rgb(255, 255, 255);
    border-radius: 50%;
    position: relative;
    top: -15%;
    left: 55%;
`;

const CrossIcon = styled(FontAwesomeIcon)`
    width: 20px;
    height: 24px;
    color: rgb(255, 255, 255);
    font-size: 24px;
    font-family: FontAwesome5Pro-Light;
    font-weight: 300;
    text-align: center;
    position: relative;
    top: 25%;
`;

const TdEmptyTable = styled.td`
    text-align: center;
`;

const EmptyTableContainer = styled.div`
    width: 326px;
    height: 207px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 78px;
`;

const EmptyTableMessageContainer = styled.div`
    width: 320px;
    height: 90px;
    color: rgb(0, 80, 117);
    font-size: 16px;
    font-family: Cairo;
    font-weight: 600;
    text-align: center;
    letter-spacing: 0.12px;
    line-height: 24px;
    position: relative;
    top: -30px;
`;

export const Table = ({ columns, footerTotalsLabel, items, onSortingChange, size, selectedItems, service, totalSelected }) => {
    const getHeaderSortingChangeHandler = (column) => () => {
        onSortingChange(column);
    };
    return (
        <StyledTable>
            <thead>
                {columns.map((column) => (
                    <Head
                        key={column.key}
                        filter={column.filter}
                        sorting={column.sorting}
                        onSortingChange={column.sorting ? getHeaderSortingChangeHandler(column) : null}
                        searchEnabled={column.searchEnabled}
                    >
                        {column.title}
                    </Head>
                ))}
            </thead>
            { size > 0 ? (
                    <StyledTBody>
                        {items.map((item) => {
                            return (
                                <Row key={item.key} selected={selectedItems && selectedItems[item.key]}>
                                    {columns.map((column) => {
                                        return (
                                            <Cell
                                                textAlign={column.align || "initial"}
                                                selected={selectedItems && selectedItems[item.key]}
                                                width={column.width ? column.width : null}
                                            >
                                                {column.render
                                                    ? column.render(
                                                        item[column.dataKey],
                                                    )
                                                    : item[column.dataKey]}
                                            </Cell>
                                        );
                                    })}
                                </Row>
                            );
                        })}
                    </StyledTBody>
            ) : null }
            { size > 0 ? (
                    <Footer>
                        <Row>
                            <Cell colSpan={columns.length % 2 === 0 ? columns.length/2 : (columns.length-1)/2}>
                                <Flex justifyContent="flex-start" mr="16px">
                                    <Box>
                                        {
                                            totalSelected && totalSelected > 0 ?
                                                (
                                                    <div>
                                                        <TickIcon icon={faCheck} />
                                                        <DivSelected>
                                                            {service !== "checkup" ? (
                                                                <FormattedMessage
                                                                    id="details.table.footer.total.selected"
                                                                    values={{ total: <b>{totalSelected}</b> }}
                                                                />
                                                            ) : (
                                                                <FormattedMessage
                                                                    id="details.table.footer.total.selected.companies"
                                                                    values={{ total: <b>{totalSelected}</b> }}
                                                                />
                                                            )}

                                                        </DivSelected>
                                                    </div>
                                                ) : ""
                                        }
                                    </Box>
                                </Flex>
                            </Cell>
                            {
                                columns.length % 2 > 0 ? (
                                    <Cell colSpan={columns.length % 2}></Cell>        
                                ) : (null)
                            }
                            <Cell colSpan={columns.length % 2 === 0 ? columns.length/2 : (columns.length-1)/2}>
                                <Flex justifyContent="flex-end" mr="16px">
                                    <Box>
                                        {footerTotalsLabel}
                                    </Box>
                                </Flex>
                            </Cell>                    
                        </Row>
                    </Footer>
            ) : null }
            { size === 0 ? (
                <StyledTBody>
                    <TdEmptyTable colSpan={columns.length}>
                        <EmptyTableContainer>
                            <TableEmptyIcon icon={faTable} />
                            <OvalIcon>
                                <CrossIcon icon={faTimes} />
                            </OvalIcon>
                            <EmptyTableMessageContainer>
                                <FormattedMessage id="details.table.empty.message" />
                            </EmptyTableMessageContainer>
                        </EmptyTableContainer>
                    </TdEmptyTable>
                </StyledTBody> 
            ) : null
            }
        </StyledTable>
    );
};
