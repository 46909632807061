import styled, { css } from "styled-components";
import { Flex } from "reflexbox";

export const RootContainer = styled(Flex)`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    opacity: 1;
    z-index: 1000;
    transition: opacity 0.3s ease;
    transform: translateY(0);
    font-size: 60px;
    color: ${(props) => props.theme.colors.primary.richElectricBlue};
    ${(props) =>
        !props.open &&
        css`
            opacity: 0;
            transition: transform 0.3s ease 0.3s, opacity 0.3s ease;
            transform: translateY(10000px);
        `}
`;
