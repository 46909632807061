import React, { useState } from "react";
import { Box } from "reflexbox";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import {HISTORY_CONSUMPTIONS_ENABLED} from "../../env";
import { ContentSwitcher } from "../../components/general/content-switcher";
import { faAnalytics, faHistory } from "@fortawesome/pro-regular-svg-icons";
import { AnalysisPanel } from "../../components/analysis-panel";
import { HistoryPanel } from "../../components/history-panel";

const CompanyNameText = styled(Box)`
    color: rgb(0, 80, 117);
    font-family: Cairo;
    font-size: 34px;
    line-height: 40px;
    font-weight: 600;
`;

const SwitcherContainer = styled.div`
    margin-bottom: 20px;
`;

export const Details = ({
    itemId,
    itemUuid,
    items,
    userId,
    accessToken,
    service,
    itemName,
    studio,
    refreshToken,
    email,
    packages
}) => {
    const [activeKeyContentSwitch, setActiveKeyContentSwitch] = useState("analysis");
    const handleContentSwitch = () => {
        if (activeKeyContentSwitch === "analysis") {
            setActiveKeyContentSwitch("history")
        } else if (activeKeyContentSwitch === "history") {
            setActiveKeyContentSwitch("analysis")
        }
    };

    return (
        <>
            <div>
                <div>
                    {
                        HISTORY_CONSUMPTIONS_ENABLED === "true" && service !== 'ai' ? <SwitcherContainer>
                            <ContentSwitcher
                                activeKey={activeKeyContentSwitch}
                                onChange={handleContentSwitch}
                                options={[
                                    {
                                        index: 0,
                                        icon: faAnalytics,
                                        key: "analysis",
                                        label: (
                                            <div>
                                                <FormattedMessage id="details.title.consumptions.analysis" />
                                            </div>
                                        )
                                    },
                                    {
                                        index: 1,
                                        icon: faHistory,
                                        key: "history",
                                        label: (
                                            <div>
                                                <FormattedMessage id="details.title.consumptions.history" />
                                            </div>
                                        )
                                    }
                                ]}
                            />
                        </SwitcherContainer> : null
                    }
                    <div>
                        <CompanyNameText>{itemName}</CompanyNameText>
                    </div>
                </div>
                <div>
                    {
                        activeKeyContentSwitch === "analysis" ? (
                            <AnalysisPanel
                                itemId={itemId}
                                itemUuid={itemUuid}
                                userId={userId}
                                accessToken={accessToken}
                                service={service}
                                studio={studio}
                                items={items}
                                refreshToken={refreshToken}
                                email={email}
                                packages={packages}
                            />
                        ) : activeKeyContentSwitch === "history" ? (
                            <HistoryPanel
                                itemId={itemId}
                                itemUuid={itemUuid}
                                userId={userId}
                                accessToken={accessToken}
                                service={service}
                                items={items}
                                refreshToken={refreshToken}
                                email={email}
                            />
                        ) : null
                    }
                </div>
            </div>
        </>
    );
};
