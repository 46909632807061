import {
    GET_STATISTICS_START,
    GET_STATISTICS_END,
    GET_STATISTICS_SUCCESS,
} from "../../actions/statistics";

const initialState = {
    loadings: 0,
    data: {
        size: 0,
        statistics: [],
        notManagedItems: []
    },
};

export const statisticsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_STATISTICS_START: {
            return {
                ...state,
                loadings: state.loadings + 1,
            };
        }
        case GET_STATISTICS_END: {
            return {
                ...state,
                loadings: state.loadings > 0 ? state.loadings - 1 : 0,
            };
        }
        case GET_STATISTICS_SUCCESS: {
            return {
                ...state,
                data: { size: action.size, statistics: action.statistics, notManagedItems: action.notManagedItems},
            };
        }
        default: {
            return state;
        }
    }
};
