import { API_URL, REGISTRY_READ_API_URL } from "../../env";
import { getSanitizedQueryString } from "../../utils";
import { getRestCall } from "../../utils/rest-api-call";

export const GET_STATISTICS_START = "GET_STATISTICS_START_";
export const GET_STATISTICS_END = "GET_STATISTICS_END";
export const GET_STATISTICS_SUCCESS = "GET_STATISTICS_SUCCESS";

export const getStatistics = (
    item,
    userId,
    accessToken,
    service,
    page,
    itemsPerPage,
    from,
    to,
    filteredItem,
    sortBy,
    viewOldBillingCycles,
    refreshToken,
    email,
    managedItems
) => async (dispatch) => {
    dispatch({ type: GET_STATISTICS_START });
    try {
        const sanitizedQueryString = service.toUpperCase() === "INVOICING" ? getSanitizedQueryString({
            userId,
            page,
            itemsPerPage,
            from,
            to,
            "itemIds": filteredItem,
            "sortBy": sortBy && sortBy != null && sortBy.startsWith("company") ? null : sortBy,
            viewOldBillingCycles,
        }) : getSanitizedQueryString({
            userId
        });
        const response = await getRestCall(
            `${API_URL}/api/v1/consumptions/service/${service}/items/${item}/statistics?${sanitizedQueryString}`,
            accessToken,
            null,
            dispatch,
            userId,
            item,
            refreshToken,
            email,
            service.toUpperCase() === "INVOICING" ? "EIP" : "SIG"
        );
        const { statistics, size } = response;
        statistics.forEach(s => {
            const filteredItems = managedItems.filter(i => i.fiscalCode === s.fiscalCode);
            if (filteredItems && filteredItems.length > 0) {
                s.key = filteredItems[0].key;
                s.company = filteredItems[0].value;
                s.vatNumber = filteredItems[0].vatNumber;
            } else {
                s.key = item; //todo fix get the uuid
            }
        });
        const statisticsWithNoCompanyName = statistics.filter(s => s.company === null || s.company === "");
        let notManagedItems = [];
        if (statisticsWithNoCompanyName.length > 0) {
            const cyclesToCallRegistryRead = parseFloat(statisticsWithNoCompanyName.length / 50);
            for (var c = 0; c < cyclesToCallRegistryRead; c++) {
                const queryString = getSanitizedQueryString({
                    idList: statisticsWithNoCompanyName.slice(c * 50, (c * 50) + 50).map(s => s.fiscalCode)
                });
                const response = await getRestCall(
                    `${REGISTRY_READ_API_URL}/api/v2/items?pagination.itemsPerPage=50&pagination.pageNumber=0&packageType=FULL&${queryString}`,
                    accessToken,
                    null,
                    dispatch,
                    userId,
                    item,
                    refreshToken,
                    email,
                    service.toUpperCase() === "INVOICING" ? "EIP" : "SIG"
                );
                if (response && response.items && response.items.length > 0) {
                    response.items.forEach(i => {
                        notManagedItems.push({
                            key: i.item.base.uuid,
                            fiscalCode: i.item.base.identifier.taxId,
                            vatNumber: i.item.base.identifier.vatNumber,
                            value: i.item.base.details.description !== null ?  i.item.base.details.description : i.item.base.details.firstName + " " + i.item.base.details.lastName
                        });
                    });
                }
            }
            statistics.forEach(s => {
                if (s.company === null || s.company === "") {
                    const filteredItems = notManagedItems.filter(i => i.fiscalCode === s.fiscalCode);
                    if (filteredItems && filteredItems.length > 0) {
                        s.key = filteredItems[0].key;
                        s.company = filteredItems[0].value;
                        s.vatNumber = filteredItems[0].vatNumber;

                    }
                }
            });
        }
        dispatch({
            type: GET_STATISTICS_SUCCESS,
            notManagedItems: notManagedItems.sort((a,b) => {
                if (a['value'] > b['value']) {
                    return 1;
                } else if (a['value'] < b['value']) {
                    return -1;
                }
                return 0;
            }),
            statistics:  statistics.sort((a,b) => {
                    if (sortBy && sortBy != null && sortBy.startsWith("company")) {
                        if (sortBy.indexOf("asc") >= 0) {
                            if (a['company'] > b['company']) {
                                return 1;
                            } else if (a['company'] < b['company']) {
                                return -1;
                            }
                        } else {
                            if (a['company'] > b['company']) {
                                return -1;
                            } else if (a['company'] < b['company']) {
                                return 1;
                            }
                        }
                    } else {
                        if (a['company'] > b['company']) {
                            return 1;
                        } else if (a['company'] < b['company']) {
                            return -1;
                        }
                    }
                    return 0;
                }),
            size,
        });
    } catch (error) {
        console.error(
            "there was an error fetching the user's statistics",
            error,
        );
    } finally {
        dispatch({ type: GET_STATISTICS_END });
    }
};
