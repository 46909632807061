import {
    GET_FLOWS_START,
    GET_FLOWS_END,
    GET_FLOWS_SUCCESS,
} from "../../actions/flows";

const initialState = {
    loadings: 0,
    data: {
        flows: [],
    },
};

export const flowsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_FLOWS_START: {
            return {
                ...state,
                loadings: state.loadings + 1,
            };
        }
        case GET_FLOWS_END: {
            return {
                ...state,
                loadings: state.loadings > 0 ? state.loadings - 1 : 0,
            };
        }
        case GET_FLOWS_SUCCESS: {

            return {
                ...state,
                data: { flows: action.response },
            };
        }
        default: {
            return state;
        }
    }
};