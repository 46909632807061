import React from "react";
import { Flex, Box } from "reflexbox";
import styled from "styled-components";
import {StandAloneRootFlex} from "../../styled";

const FamilySquare = styled(Box)`
    width: 12px;
    height: 12px;
    border-radius: 2px;
    background: ${(props) => props.color};
`;

const FamilyLabel = styled(Box)`
    height: 24px;
    color: rgb(0, 80, 117);
    font-size: 16px;
    font-family: Cairo;
    font-weight: 600;
    letter-spacing: 0.11px;
    line-height: 24px;
    width: 120px;
    margin-left: 10px;
`;


export const StandAloneCheckupCard = ({
    family,
    familyReplacement
}) => {
    
    return (
        <StandAloneRootFlex>
            <Flex flexWrap='wrap' width="200px" height="50px" ml="20px" mt="20px">
                <FamilySquare mr="8px" mt="5px" color={"#fc4e3d"} />
                <FamilyLabel>
                    {familyReplacement ? familyReplacement : family}
                </FamilyLabel>
            </Flex>        
        </StandAloneRootFlex>
    );
};