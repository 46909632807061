import { API_URL } from "../../env";
import { getRestCall } from "../../utils/rest-api-call";
import { getAppIdFromService, getSyncConstraintsFromService } from  "../../utils/index";

export const GET_SUBSCRIPTIONS_START = "GET_SUBSCRIPTIONS_START_";
export const GET_SUBSCRIPTIONS_END = "GET_SUBSCRIPTIONS_END";
export const GET_SUBSCRIPTIONS_SUCCESS = "GET_SUBSCRIPTIONS_SUCCESS";

export const getSubscriptions = (itemUuid, userId, accessToken, services, refreshToken, email) => async (
    dispatch,
) => {
    dispatch({ type: GET_SUBSCRIPTIONS_START });
    try {
        const subscriptions = {};
        for (const service of services) {
            const response = await getRestCall(
                `${API_URL}/api/v1/consumptions/service/${service.toUpperCase()}/items/${itemUuid}/subscriptions`,
                accessToken,
                null,
                dispatch,
                userId,
                itemUuid,
                refreshToken,
                email,
                getAppIdFromService(service.toUpperCase())
            );
            switch (service.toUpperCase()) {
                case "INVOICING":
                case "CHECKUP":
                case "AI":   
                    subscriptions[service] = response.map(
                        (subscription) => {
                            const {
                                billingCycleStartDate,
                                billingCycleEndDate,
                                id,
                                total,
                                insertionDate,
                                capacity,
                                family,
                                familyReplacement
                            } = subscription;
                            const overallUsage = total; // - subscription.credit;
                            const regularUsage =
                                overallUsage > capacity ? capacity : overallUsage;
                            const extraUsage =
                                overallUsage - capacity > 0
                                    ? overallUsage - capacity
                                    : 0;
                            return {
                                id,
                                billingCycleStart: billingCycleStartDate,
                                billingCycleEnd: billingCycleEndDate,
                                timestamp: insertionDate,
                                capacity,
                                regularUsage,
                                extraUsage,
                                family,
                                familyReplacement
                            };
                        },
                    );
                    break;
                case "SIGNATURE":
                case "ECOBONUS":
                    let signatureSubscriptions = [];
                    response.forEach((subscription) => {
                        const {
                            billingCycleStartDate,
                            billingCycleEndDate,
                            id,
                            family,
                            familyReplacement,
                            asynchronous,
                            synchronous,
                            insertionDate
                        } = subscription;
                        let obj = {};
                        obj.id = id;
                        obj.billingCycleStart = billingCycleStartDate;
                        obj.billingCycleEnd = billingCycleEndDate;
                        obj.family = family;
                        obj.familyReplacement = familyReplacement;
                        obj.timestamp = insertionDate;
                        if (asynchronous && asynchronous.length > 0) {
                            addValues(obj, asynchronous, "SIGNABLE_DOCUMENTS");
                            addValues(obj, asynchronous, "TIMESTAMPS");
                            addValues(obj, asynchronous, "SMS");
                        } else if (service.toUpperCase() === "SIGNATURE") {
                            obj.isStandalone = true;
                            obj.capacity = 0;
                            obj.regularUsage = 0;
                            obj.extraUsage = 0;
                        }
                        if (synchronous.length > 0) {
                            let arrConstraints = getSyncConstraintsFromService(service);
                            arrConstraints.forEach(constraint => addValues(obj, synchronous, constraint));
                        }
                        if (!obj.isStandalone) {
                            signatureSubscriptions.push(obj);
                        } else {
                            const standaloneSubscription = signatureSubscriptions.filter(s => s.constraint === obj.constraint);
                            if (standaloneSubscription.length === 0) {
                                signatureSubscriptions.push(obj);
                            } else {
                                signatureSubscriptions.filter(s => s.constraint === obj.constraint)[0].capacity += obj.capacity;
                                signatureSubscriptions.filter(s => s.constraint === obj.constraint)[0].regularUsage += obj.regularUsage;
                                signatureSubscriptions.filter(s => s.constraint === obj.constraint)[0].extraUsage += obj.extraUsage;
                            }
                        }
                      });

                    subscriptions[service] = signatureSubscriptions;
                    break;
                default:
                    break;
            }
        }
        dispatch({ type: GET_SUBSCRIPTIONS_SUCCESS, subscriptions });
    } catch (error) {
        console.error(
            "there was an error fetching the user's subscriptions",
            error,
        );
    } finally {
        dispatch({ type: GET_SUBSCRIPTIONS_END });
    }
};

export const getSubscriptionsByService = (itemUuid, userId, accessToken, service, refreshToken, email) => async (
    dispatch,
) => {
    if (!service || service.trim().length === 0) {
        return;
    }
    dispatch({ type: GET_SUBSCRIPTIONS_START });
    try {
        const subscriptions = {};
        const response = await getRestCall(
            `${API_URL}/api/v1/consumptions/service/${service.toUpperCase()}/items/${itemUuid}/subscriptions`,
            accessToken,
            null,
            dispatch,
            userId,
            itemUuid,
            refreshToken,
            email,
            getAppIdFromService(service.toUpperCase())
        );
        switch (service.toUpperCase()) {
            case "INVOICING":
            case "CHECKUP":
                subscriptions[service] = response.map(
                    (subscription) => {
                        const {
                            billingCycleStartDate,
                            billingCycleEndDate,
                            id,
                            total,
                            insertionDate,
                            capacity,
                            family,
                            familyReplacement
                        } = subscription;
                        const overallUsage = total; // - subscription.credit;
                        const regularUsage =
                            overallUsage > capacity ? capacity : overallUsage;
                        const extraUsage =
                            overallUsage - capacity > 0
                                ? overallUsage - capacity
                                : 0;
                        return {
                            id,
                            billingCycleStart: billingCycleStartDate,
                            billingCycleEnd: billingCycleEndDate,
                            timestamp: insertionDate,
                            capacity,
                            regularUsage,
                            extraUsage,
                            family,
                            familyReplacement,
                            isStandalone: family === "CRISI_IMPRESA_PRO"
                        };
                    },
                );
                break;
            case "SIGNATURE":
            case "ECOBONUS":
            case "PAY":
            case "AI":    
                let allSubscriptions = [];
                response.forEach((subscription) => {
                    const {
                        billingCycleStartDate,
                        billingCycleEndDate,
                        id,
                        family,
                        familyReplacement,
                        asynchronous,
                        synchronous,
                        insertionDate,
                        aiFamilies,
                        aiWhiteList
                    } = subscription;
                    let obj = {};
                    obj.id = id;
                    obj.billingCycleStart = billingCycleStartDate;
                    obj.billingCycleEnd = billingCycleEndDate;
                    obj.family = family;
                    obj.familyReplacement = familyReplacement;
                    obj.timestamp = insertionDate;
                    obj.aiWhiteList = aiWhiteList;
                    obj.aiFamilies = aiFamilies; 
                    if (asynchronous && asynchronous.length > 0) {
                        if (service.toUpperCase() === "SIGNATURE") {
                            addValues(obj, asynchronous, "SIGNABLE_DOCUMENTS");
                            addValues(obj, asynchronous, "TIMESTAMPS");
                            addValues(obj, asynchronous, "SMS");
                        } else if (service.toUpperCase() === "PAY") {
                            addValues(obj, asynchronous, "TRANSACTIONS");
                        }
                    } else if (service.toUpperCase() === "SIGNATURE") {
                        obj.isStandalone = true;
                        obj.capacity = 0;
                        obj.regularUsage = 0;
                        obj.extraUsage = 0;
                    }
                    if (synchronous.length > 0) {
                        let arrConstraints = getSyncConstraintsFromService(service);
                        arrConstraints.forEach(constraint => addValues(obj, synchronous, constraint));
                    }
                    if (!obj.isStandalone) {
                        allSubscriptions.push(obj);
                    } else {
                        const standaloneSubscription = allSubscriptions.filter(s => s.constraint === obj.constraint);
                        if (standaloneSubscription.length === 0) {
                            allSubscriptions.push(obj);
                        } else {
                            allSubscriptions.filter(s => s.constraint === obj.constraint)[0].capacity += obj.capacity;
                            allSubscriptions.filter(s => s.constraint === obj.constraint)[0].regularUsage += obj.regularUsage;
                            allSubscriptions.filter(s => s.constraint === obj.constraint)[0].extraUsage += obj.extraUsage;
                        }
                    }
                });

                subscriptions[service] = allSubscriptions;
                break;
            default:
                break;
        }
        dispatch({ type: GET_SUBSCRIPTIONS_SUCCESS, subscriptions });
    } catch (error) {
        console.error(
            "there was an error fetching the user's subscriptions",
            error,
        );
    } finally {
        dispatch({ type: GET_SUBSCRIPTIONS_END });
    }
};

const addValues = (obj, elements, constType) => {
    const values = elements.filter((e) => {
        return e.constraint === constType
    });
    if (values && values.length > 0) {
        const capacity = values[0].capacity; //todo per ora non consideriamo i crediti values[0].credit;
        const regularUsage = values[0].totalConsumed > capacity ?
            capacity : values[0].totalConsumed;
        const extraUsage = values[0].totalConsumed - capacity > 0
                ? values[0].totalConsumed - capacity
                : 0;             
        if (constType === "SIGNABLE_DOCUMENTS" || constType === "TIMESTAMPS" || constType === "TRANSACTIONS" || obj.isStandalone) {
            obj.constraint = values[0].constraint;
            obj.capacity = capacity;
            obj.regularUsage = regularUsage;
            obj.extraUsage = extraUsage;
        } else if (constType === "AI_COMPANIES"){
            obj[constType] = {
                capacity: values[0].capacity,
                regularUsage: values[0].totalConsumed,
                extraUsage: extraUsage,
                coolDownUsage: values[0].coolDown,
            }
        }  else {
            obj[constType] = {
                capacity: values[0].capacity,
                regularUsage: regularUsage,
                extraUsage: extraUsage,
            }
        }         
    }
}