import {
    GET_CONNECTED_ITEMS_ERROR,
    GET_CONNECTED_ITEMS_START,
    GET_CONNECTED_ITEMS_SUCCESS,
} from "../../actions/items";

const initialState = {
    loadings: 0,
    data: [],
};

export const itemsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CONNECTED_ITEMS_START: {
            return {
                ...state,
                loadings: state.loadings + 1,
            };
        }
        case GET_CONNECTED_ITEMS_ERROR: {
            return {
                ...state,
                error: action.payload,
                loadings: 0
            };
        }
        case GET_CONNECTED_ITEMS_SUCCESS: {
            return {
                ...state,
                data: action.items,
                loadings: state.loadings > 0 ? state.loadings - 1 : 0,
                loaded: 1
            };
        }
        default: {
            return state;
        }
    }
};
