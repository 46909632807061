import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Flex, Box } from "reflexbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";

const Text = styled.span`
    color: ${(props) =>
        props.highlighted ? "rgb(0, 144, 209)" : "rgb(90, 104, 114)"};
    font-family: Roboto;
    line-height: 32px;
    font-size: 16px;
`;

const CursorBox = styled(Box)`
    cursor: pointer;
`;

const ActionIcon = styled.button`
    border: none;
    outline: none;
    color: ${(props) =>
        props.disabled
            ? props.theme.colors.primary.columbiaBlue
            : "rgb(0, 144, 209)"};
    font-size: 16px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    width: 32px;
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    background: #fff;
`;

export const Pagination = ({
    checkedAllItems,
    compact,
    page,
    rowsPerPage,
    size,
    onPageChange,
}) => {
    const [currentRecordsRange, setCurrentRecordsRange] = useState([
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
    ]);
    const [pages, setPages] = useState([]);

    useEffect(() => {
        if (!compact) {
            return;
        }
        const from = page * rowsPerPage;
        setCurrentRecordsRange([from, from + rowsPerPage]);
    }, [compact, page, rowsPerPage]);

    useEffect(() => {
        if (!rowsPerPage) {
            return;
        }
        const pages = [];
        const pagesAmount = Math.ceil(size / rowsPerPage);
        for (let i = 0; i < pagesAmount; i++) {
            pages.push(i + 1);
        }
        setPages(pages);
    }, [rowsPerPage, size]);

    const handlePreviousPage = useCallback(() => {
        onPageChange(page - 1, checkedAllItems);
    }, [onPageChange, page, checkedAllItems]);

    const handleNextPage = useCallback(() => {
        onPageChange(page + 1, false);
    }, [onPageChange, page]);

    const getSpecificPageClickHandler = (page) => () => {
        onPageChange(page);
    };

    if (compact) {
        return (
            <Flex height="32px" alignItems="center">
                <Box mr="8px">
                    <Text highlighted>
                        {currentRecordsRange[0]}-{currentRecordsRange[1]}
                    </Text>
                </Box>
                <Box mr="8px">
                    <Text>/</Text>
                </Box>
                <Box mr="8px">
                    <Text>{size}</Text>
                </Box>
                <Box>
                    <ActionIcon
                        disabled={page === 0}
                        onClick={handlePreviousPage}
                    >
                        <FontAwesomeIcon icon={faCaretLeft} />
                    </ActionIcon>
                </Box>
                <Box>
                    <ActionIcon
                        disabled={page + 1 === pages.length}
                        onClick={handleNextPage}
                    >
                        <FontAwesomeIcon icon={faCaretRight} />
                    </ActionIcon>
                </Box>
            </Flex>
        );
    }
    return (
        <Flex height="32px" alignItems="center">
            <Box>
                <ActionIcon disabled={page === 0} onClick={handlePreviousPage}>
                    <FontAwesomeIcon icon={faCaretLeft} />
                </ActionIcon>
            </Box>
            {pages.length > 3 && page > 1 && (
                <>
                    <CursorBox
                        mr="8px"
                        onClick={getSpecificPageClickHandler(0)}
                    >
                        <Text>1</Text>
                    </CursorBox>
                    {page !== 1 && <Box mr="8px">...</Box>}
                </>
            )}
            {pages
                .slice(
                    page > 0
                        ? page === pages.length - 1
                            ? page - 2
                            : page - 1
                        : page,
                    page === 0 ? page + 3 : page + 2,
                )
                .map((localPage) => (
                    <CursorBox
                        mr="8px"
                        onClick={getSpecificPageClickHandler(localPage - 1)}
                    >
                        <Text highlighted={page === localPage - 1}>
                            {localPage}
                        </Text>
                    </CursorBox>
                ))}
            {pages.length > 3 && page < pages.length - 2 && (
                <>
                    {page < pages.length - 1 && (
                        <Box mr="8px">
                            <Text>...</Text>
                        </Box>
                    )}
                    <CursorBox
                        onClick={getSpecificPageClickHandler(pages.length - 1)}
                    >
                        <Text>{pages.length}</Text>
                    </CursorBox>
                </>
            )}
            <Box>
                <ActionIcon
                    disabled={page + 1 === pages.length}
                    onClick={handleNextPage}
                >
                    <FontAwesomeIcon icon={faCaretRight} />
                </ActionIcon>
            </Box>
        </Flex>
    );
};

Pagination.propTypes = {
    compact: PropTypes.bool,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    size: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
};
