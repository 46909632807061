import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const StyledInput = styled.input`
    background: #f2f5f8;
    border: ${(props) =>
        props.border ? "solid 1px #c9d9e8" : "solid 1px #f2f5f8"};
    height: 40px;
    max-height: 40px;
    box-sizing: border-box;
    transition: border 0.3s ease;
    width: 100%;
    padding: ${(props) => (props.icon ? "8px 48px 8px 16px" : "8px 16px")};
    line-height: 18px;
    font-size: 14px;
    font-family: Roboto;
    font-weight: 400;
    outline: none;
    :hover:not(:disabled) {
        border: solid 1px #0090d1;
    }
    :focus {
        border: solid 1px #0090d1;
    }
    ::placeholder {
        color: #9da6ad;
        line-height: 18px;
        font-size: 14px;
        font-family: Roboto;
        font-weight: 400;
    }
`;

const Label = styled.div`
    color: #152935;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    margin-bottom: 12px;
`;

const RelativeWrapper = styled.div`
    position: relative;
`;

const Icon = styled.div`
    width: 40px;
    height: 40px;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Input = ({
    value,
    onChange,
    onClick,
    placeholder,
    faIcon,
    label,
    border,
    iconColor,
    ...rest
}) => {
    return (
        <>
            {label && <Label>{label}</Label>}
            <RelativeWrapper>
                <StyledInput
                    icon={faIcon}
                    value={value}
                    placeholder={placeholder}
                    onChange={onChange}
                    onClick={onClick}
                    border={border}
                    {...rest}
                />
                {faIcon && (
                    <Icon>
                        <FontAwesomeIcon color={iconColor} icon={faIcon} onClick={onClick} />
                    </Icon>
                )}
            </RelativeWrapper>
        </>
    );
};

Input.propTypes = {
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    placeholder: PropTypes.node,
    faIcon: PropTypes.object.isRequired,
    label: PropTypes.node.isRequired,
    border: PropTypes.bool,
};

Input.defaultProps = {
    border: true,
};
