import {
    GET_COMPANY_START,
    GET_COMPANY_END,
    GET_COMPANY_SUCCESS,
} from "../../actions/company";

const initialState = {
    loadings: 0,
    data: {},
};

export const companyReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_COMPANY_START: {
            return {
                ...state,
                loadings: state.loadings + 1,
            };
        }
        case GET_COMPANY_END: {
            return {
                ...state,
                loadings: state.loadings > 0 ? state.loadings - 1 : 0,
            };
        }
        case GET_COMPANY_SUCCESS: {
            return {
                ...state,
                data: action.company,
            };
        }
        default: {
            return state;
        }
    }
};
