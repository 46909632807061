import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Flex, Box } from "reflexbox";
import { FormattedMessage, useIntl } from "react-intl";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import { PieChart, Pie, Cell } from "recharts";
import { useState } from "react";
import {
    RootFlex,
    PackageUsageText,
    InsertionDateText,
    ConsumptionDetailsFlex,
    SideBarFlex,
    DividerBox,
    ExpandIconContainerBox,
    ExpandIcon,
    PieChartBox,
    AbsoluteUsageDataFlex,
    PieUsageText,
    PieUsageHelperText,
    PieChartAxes,
    OtherConsumptionsContainer,
    GreenTextBox,
    UsageConsumptionsTextBox,
    UsageTextBox,
    CapacityTextBox,
    PayCompanyUsedTextBox
} from "../styled";
import { useCallback } from "react";
import {  LegendItem } from "../../legend-item";
import { Tooltip } from "../../tooltip/index";

const TOTAL_USAGE_COLOR = "#0d6b7f";
const EXTRA_USAGE_COLOR = "#da2c38";
const REMAINING_USAGE_COLOR = "#9fc3cd";
const COLORS = ['#ffffff', EXTRA_USAGE_COLOR];

export const PayPackageCard = ({
                                         id,
                                         timestamp,
                                         capacity,
                                         company,
                                         family,
                                         familyReplacement,
                                         regularUsage,
                                         extraUsage,
                                         onUpgrade,
                                         onExpand,
                                         billingCycleStart,
                                         billingCycleEnd,
                                         TSPAY_COMPANIES
                                     }) => {
    const { formatMessage } = useIntl();
    const [pieData, setPieData] = useState([]);
    const [pieExtraData, setPieExtraData] = useState([]);

    useEffect(() => {
        const occupiedValue = regularUsage;
        const rawRemainingValue = capacity - occupiedValue;
        setPieData([
            {
                id: formatMessage({ id: "pie.slice.usage.total" }),
                value: regularUsage,
                fill: TOTAL_USAGE_COLOR,
            },
            {
                id: formatMessage({ id: "pie.slice.usage.remaining" }),
                value: rawRemainingValue > 0 ? rawRemainingValue : 0,
                fill: REMAINING_USAGE_COLOR,
            },
        ]);
        setPieExtraData([
            {
                id: formatMessage({ id: "pie.slice.usage.extra" }),
                value: extraUsage,
                fill: EXTRA_USAGE_COLOR,
            },
        ]);
    }, [extraUsage, regularUsage, capacity, formatMessage, family]);

    const handleExpand = useCallback(() => {
        onExpand(id);
    }, [id, onExpand]);

    return (
        <RootFlex>
            <Flex
                flexDirection="column"
                alignItems="center"
                width="100%"
            >
                <Flex
                    flexWrap='wrap'
                    ml="24px"
                    mt="24px"
                    justifyContent="space-between"
                >
                    <PackageUsageText>
                        <FormattedMessage
                            id="package.card.title"
                            values={{ name: familyReplacement ? familyReplacement : family }}
                        />
                    </PackageUsageText>
                    <InsertionDateText mt="10px">
                        <Box>
                            <FormattedMessage
                                id="package.card.invoicing.period"
                            />
                        </Box>
                        <Box>
                            <FormattedMessage
                                id="package.card.date.insertion.start"
                                values={{
                                    date: billingCycleStart
                                }}
                            />
                        </Box>
                        <Box>
                            <FormattedMessage
                                id="package.card.date.insertion.end"
                                values={{
                                    date: billingCycleEnd
                                }}
                            />
                        </Box>
                    </InsertionDateText>
                </Flex>

                <Flex>
                    <PieChartAxes mr="10px" mt="150px">{0}</PieChartAxes>
                    <PieChartBox>
                        <PieChart width={300} height={180}>
                            <Pie
                                data={pieData}
                                cx={145}
                                cy={170}
                                startAngle={180}
                                endAngle={extraUsage > 0 ? (180 * extraUsage)/capacity : 0}
                                innerRadius={110}
                                outerRadius={130}
                                paddingAngle={1}
                            >
                                {
                                    extraUsage > 0 ?
                                        pieData.map((entry, index) =>
                                            <Cell key={`cell-${index}`} stroke={COLORS[index % COLORS.length]} />) : null
                                }
                            </Pie>
                            {extraUsage > 0 ? (
                                <Pie
                                    data={pieExtraData}
                                    cx={145}
                                    cy={170}
                                    startAngle={0}
                                    endAngle={(180 * extraUsage)/capacity}
                                    innerRadius={110}
                                    outerRadius={140}
                                    paddingAngle={1}
                                >
                                    {
                                        pieExtraData.map((entry, index) =>
                                            <Cell key={`cell-${index}`} stroke="#ffffff" />)
                                    }
                                </Pie>
                            ) : null}
                        </PieChart>
                        <AbsoluteUsageDataFlex
                            flexDirection="column"
                            justifyContent="flex-end"
                            alignItems="center"
                        >
                            <PieUsageText>{regularUsage + extraUsage}</PieUsageText>
                            <PieUsageHelperText>
                                <FormattedMessage id="package.card.pie.transactions" />
                            </PieUsageHelperText>
                        </AbsoluteUsageDataFlex>
                    </PieChartBox>
                    <PieChartAxes mt="150px">{capacity}</PieChartAxes>
                </Flex>
                <Flex marginLeft="25px" justifyContent="center" mb="10px" mt="10px" width="100%">
                    <LegendItem
                        color={TOTAL_USAGE_COLOR}
                        message={<FormattedMessage id="package.card.pie.legend.usage"/>}
                        quantity={regularUsage}
                        percentage={(
                            ((regularUsage) /
                                capacity) *
                            100
                        ).toFixed(2)
                        }
                        showQuantity={true}
                    />
                    <LegendItem
                        color={REMAINING_USAGE_COLOR}
                        message={
                            <FormattedMessage
                                id="package.card.pie.legend.remaining"
                            />}
                        quantity={(capacity - regularUsage).toFixed(2)}
                        percentage={(
                            ((capacity - regularUsage) /
                                capacity) *
                            100
                        ).toFixed(2)
                        }
                        showQuantity={true}
                    />
                    {
                        extraUsage > 0 ? (
                            <LegendItem
                                color={EXTRA_USAGE_COLOR}
                                message={
                                    <FormattedMessage
                                        id="package.card.pie.legend.extra"
                                    />
                                }
                                quantity={extraUsage}
                                percentage={(
                                    ((extraUsage) /
                                        capacity) *
                                    100
                                ).toFixed(2)
                                }
                                showQuantity={true}
                            />
                        ) : null
                    }
                </Flex>
                <ConsumptionDetailsFlex
                    px="24px"
                    pt="15px"
                    width="100%"
                    flex="1"
                    flexDirection="column"
                    flexWrap='wrap'
                >
                    <Flex height="144px">
                        { TSPAY_COMPANIES ? (
                            <OtherConsumptionsContainer
                                pr="24px"
                                justifyContent="space-between"
                                flexWrap='wrap'
                                mt="10px"
                            >
                                <GreenTextBox pr="16px">
                                    <FormattedMessage id="package.card.total.companies" />
                                </GreenTextBox>
                                <UsageConsumptionsTextBox>
                                    <UsageTextBox extra={false}>{TSPAY_COMPANIES.regularUsage}</UsageTextBox>
                                    <CapacityTextBox>{"/" + TSPAY_COMPANIES.capacity}</CapacityTextBox>
                                </UsageConsumptionsTextBox>
                                <PayCompanyUsedTextBox>
                                    <FormattedMessage id="package.card.total.companies.desc" />
                                </PayCompanyUsedTextBox>
                            </OtherConsumptionsContainer>
                        ) : null
                        }
                        { TSPAY_COMPANIES ? (
                            <OtherConsumptionsContainer
                                pr="24px"
                                justifyContent="space-between"
                                flexWrap='wrap'
                                mt="10px"
                            >
                                <GreenTextBox pr="16px">
                                    <FormattedMessage id="package.card.total.companies.extra" />
                                </GreenTextBox>
                                <UsageConsumptionsTextBox>
                                    <UsageTextBox extra={TSPAY_COMPANIES.extraUsage > 0}>{TSPAY_COMPANIES.extraUsage}</UsageTextBox>
                                </UsageConsumptionsTextBox>
                                <PayCompanyUsedTextBox>
                                    <FormattedMessage id="package.card.total.companies.desc" />
                                </PayCompanyUsedTextBox>
                            </OtherConsumptionsContainer>
                        ) : null
                        }
                    </Flex>
                </ConsumptionDetailsFlex>
            </Flex>
            <SideBarFlex
                flexDirection="column"
                justifyContent="flex-end"
                alignItems="center"
            >
                <DividerBox />
                <ExpandIconContainerBox onClick={handleExpand}>
                    <Tooltip
                        label={<FormattedMessage id="package.card.view.detail.tooltip" />}
                        position="top"
                        width="70px">
                        <ExpandIcon icon={faArrowRight} width="14px"/>
                    </Tooltip>
                </ExpandIconContainerBox>
            </SideBarFlex>
        </RootFlex>
    );
};

PayPackageCard.propTypes = {
    id: PropTypes.number.isRequired,
    timestamp: PropTypes.string.isRequired,
    capacity: PropTypes.number.isRequired,
    regularUsage: PropTypes.number.isRequired,
    extraUsage: PropTypes.number.isRequired,
    onUpgrade: PropTypes.func.isRequired,
    onExpand: PropTypes.func.isRequired,
};
