import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Flex, Box } from "reflexbox";
import { Input } from "../input";
import { faCalendar } from "@fortawesome/pro-regular-svg-icons";
import { Picker } from "./picker";
import styled from "styled-components";
import { fadeIn } from "../../commons/styles/animations";
import { useIntl } from "react-intl";

const AbsolutePickerContainer = styled(Flex)`
    position: absolute;
    top: calc(100% + 8px);
    ${(props) => (props.changingFrom ? "left: 0;" : "right: 0;")}
    animation: ${fadeIn} 0.3s;
    z-index: 4;
`;

const CenteredPicker = styled(Picker)`
    margin: 0 auto;
`;

const RelativeFlex = styled(Flex)`
    position: relative;
`;

export const RangeDatePicker = ({
    minimumDate,
    maximumDate,
    value,
    onChange,
    fromInputLabel,
    toInputLabel,
}) => {
    const { formatMessage } = useIntl();

    const [fromDate, toDate] = value;

    const [validatedFromDate, setValidatedFromDate] = useState(fromDate);
    const [validatedToDate, setValidatedToDate] = useState(toDate);

    const [changingFrom, setChangingFrom] = useState(false);
    const [changingTo, setChangingTo] = useState(false);

    useEffect(() => {
        if (fromDate && minimumDate) {
            setValidatedFromDate(
                fromDate.isBefore(minimumDate) ? minimumDate : fromDate,
            );
        }
    }, [fromDate, minimumDate]);

    useEffect(() => {
        if (toDate && maximumDate) {
            setValidatedToDate(
                toDate.isAfter(maximumDate) ? maximumDate : toDate,
            );
        }
    }, [maximumDate, toDate]);

    const handleFromDateChange = useCallback(() => {
        validatedFromDate
            ? setValidatedFromDate(validatedFromDate.format("DD/MM/YYYY"))
            : setValidatedFromDate("");
        if (!validatedFromDate && !validatedToDate) {
            onChange([null, null]);
        }
    }, [onChange, validatedFromDate, validatedToDate]);

    const handleToDateChange = useCallback(() => {
        validatedToDate
            ? setValidatedToDate(validatedToDate.format("DD/MM/YYYY"))
            : setValidatedToDate("");
        if (!validatedFromDate && !validatedToDate) {
            onChange([null, null]);
        }
    }, [onChange, validatedFromDate, validatedToDate]);

    const handleFromDateClick = useCallback(() => {
        setChangingFrom(true);
    }, []);

    const handleToDateClick = useCallback(() => {
        setChangingTo(true);
    }, []);

    const handleChange = useCallback(
        (period) => {
            onChange(period);
        },
        [onChange],
    );

    const handleClose = useCallback((date) => {
        setChangingFrom(false);
        setChangingTo(false);
    }, []);

    return (
        <>
            <RelativeFlex>
                <Box px={12} width={1 / 2}>
                    <Input
                        placeholder={formatMessage({
                            id: "range.picker.from.placeholder",
                        })}
                        label={fromInputLabel}
                        value={validatedFromDate !== null
                                ? validatedFromDate.format("DD/MM/YYYY")
                                : ""}
                        faIcon={faCalendar}
                        onChange={handleFromDateChange}
                        onClick={handleFromDateClick}
                    />
                </Box>
                <Box px={12} width={1 / 2}>
                    <Input
                        placeholder={formatMessage({
                            id: "range.picker.to.placeholder",
                        })}
                        label={toInputLabel}
                        value={
                            validatedToDate !== null
                                ? validatedToDate.format("DD/MM/YYYY")
                                : ""
                        }
                        faIcon={faCalendar}
                        onChange={handleToDateChange}
                        onClick={handleToDateClick}
                    />
                </Box>
                {(changingFrom || changingTo) && (
                    <AbsolutePickerContainer
                        changingFrom={changingFrom}
                        changingTo={changingTo}
                        justifyContent="center"
                    >
                        <Box>
                            <CenteredPicker
                                openFrom={changingFrom}
                                openTo={changingTo}
                                minimumDate={minimumDate}
                                maximumDate={maximumDate}
                                value={[validatedFromDate, validatedToDate]}
                                onChange={handleChange}
                                onClose={handleClose}
                            />
                        </Box>
                    </AbsolutePickerContainer>
                )}
            </RelativeFlex>
        </>
    );
};

RangeDatePicker.propTypes = {
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    minimumDate: PropTypes.object,
    maximumDate: PropTypes.object,
    fromInputLabel: PropTypes.node,
    toInputLabel: PropTypes.node,
};

RangeDatePicker.defaultProps = {
    value: [moment(), moment()],
    minimumDate: moment().subtract("5", "years"),
    maximumDate: moment().startOf('year').add(1, "years").add("10", "days"),
};
