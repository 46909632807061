import fileDownload from 'js-file-download'
import { getRestCall, postRestCall } from "../../utils/rest-api-call";
import { API_URL, ARCHIVER_READ_API_URL, REGISTRY_READ_API_URL } from "../../env";
import { getSanitizedQueryString, downloadBase64, download, downloadTextFile } from "../../utils";

export const DOWNLOAD_STATISTICS_CSV_START = "DOWNLOAD_STATISTICS_CSV_START";
export const DOWNLOAD_STATISTICS_CSV_END = "DOWNLOAD_STATISTICS_CSV_END";

export const downloadStatisticsCsv = (
    item,
    userId,
    accessToken,
    service,
    from,
    to,
    viewOldBillingCycles,
    itemIds,
    refreshToken,
    email,
    managedItems
) => async (dispatch) => {
    dispatch({ type: DOWNLOAD_STATISTICS_CSV_START });
    try {
        const bodyParams = {
            "from": from ? from.format('YYYY-MM-DD') : null,
            "to": to ? to.format('YYYY-MM-DD') : null,
            "viewOldBillingCycles": viewOldBillingCycles ? viewOldBillingCycles : null,
            "itemIds": itemIds ? itemIds : null
        };
        const response = await postRestCall(
            `${API_URL}/api/v1/consumptions/service/${service}/items/${item}/statistics/csv`,
            accessToken,
            null,
            bodyParams,
            dispatch,
            userId,
            item,
            refreshToken,
            email,
            service.toUpperCase() === "INVOICING" ? "EIP" : "SIG");
        const { statistics } = response;
        statistics.forEach(s => {
            const filteredItems = managedItems.filter(i => i.fiscalCode === s.fiscalCode);
            if (filteredItems && filteredItems.length > 0) {
                s.key = filteredItems[0].key;
                s.company = filteredItems[0].value;
                s.vatNumber = filteredItems[0].vatNumber;
            }
        });

        const statisticsWithNoCompanyName = statistics.filter(s => s.company === null || s.company === "");
        if (statisticsWithNoCompanyName.length > 0) {
            let notManagedItems = [];
            const cyclesToCallRegistryRead =  parseFloat(statisticsWithNoCompanyName.length / 50);
            for (var c = 0; c < cyclesToCallRegistryRead; c++) {
                const queryString = getSanitizedQueryString({
                    idList: statisticsWithNoCompanyName.slice(c * 50, (c * 50) + 50).map(s => s.fiscalCode)
                });
                const response = await getRestCall(
                    `${REGISTRY_READ_API_URL}/api/v2/items?pagination.itemsPerPage=50&pagination.pageNumber=0&packageType=FULL&${queryString}`,
                    accessToken,
                    null,
                    dispatch,
                    userId,
                    item,
                    refreshToken,
                    email,
                    service.toUpperCase() === "INVOICING" ? "EIP" : "SIG"
                );
                if (response && response.items && response.items.length > 0) {
                    response.items.forEach(i => {
                        notManagedItems.push({
                            key: i.item.base.uuid,
                            fiscalCode: i.item.base.identifier.taxId,
                            vatNumber: i.item.base.identifier.vatNumber,
                            value: i.item.base.details.description !== null ?  i.item.base.details.description : i.item.base.details.firstName + " " + i.item.base.details.lastName
                        });
                    });
                }
            }
            statistics.forEach(s => {
                if (s.company === null || s.company === "") {
                    const filteredItems = notManagedItems.filter(i => i.fiscalCode === s.fiscalCode);
                    if (filteredItems && filteredItems.length > 0) {
                        s.key = filteredItems[0].key;
                        s.company = filteredItems[0].value;
                        s.vatNumber = filteredItems[0].vatNumber;
                    }
                }
            });
        }

        const invoicingHeader = "Codice fiscale azienda;Ragione sociale;Fatture ricevute;Fatture inviate;Ordini ricevuti;Ordini inviati;Corrispettivi;Totale\n";
        var stringBuffer = "";
        if (service.toUpperCase().indexOf("INVOICING") >= 0) {
            stringBuffer += invoicingHeader;
        }
        if (statistics != null && statistics.length > 0) {
            statistics.forEach(stat => {
                if (service.toUpperCase().indexOf("INVOICING") >= 0) {
                    stringBuffer += stat.fiscalCode + ";";
                    stringBuffer += stat.company + ";";
                    stringBuffer += stat.invoice_recv + ";";
                    stringBuffer += stat.invoice_sent + ";";
                    stringBuffer += stat.order_recv + ";";
                    stringBuffer += stat.order_sent + ";";
                    stringBuffer += stat.receipt_recv + ";";
                    stringBuffer += stat.total + "\n";
                }
            });
        }
        downloadTextFile("statistiche.csv", stringBuffer);
    } catch (error) {
        console.error(
            "there was an error fetching the user's statistics csv",
            error,
        );
    } finally {
        dispatch({ type: DOWNLOAD_STATISTICS_CSV_END });
    }
};

export const DOWNLOAD_DETAILS_CSV_START = "DOWNLOAD_DETAILS_CSV_START";
export const DOWNLOAD_DETAILS_CSV_END = "DOWNLOAD_DETAILS_CSV_END";

export const downloadDetailsCsv = (
    item,
    userId,
    accessToken,
    service,
    from,
    to,
    viewOldBillingCycles,
    hubIds,
    managed,
    refreshToken,
    email,
    filteredPackage,
    filter,
    filteredFlow
) => async (dispatch) => {
    dispatch({ type: DOWNLOAD_DETAILS_CSV_START });
    try {
        const bodyParams = {
            "filteredFlow": filteredFlow && filteredFlow.value ? filteredFlow.value : null,
            "filteredPackage": filteredPackage,
            "from": from,
            "hubIds": hubIds,
            "managed": managed,
            "to": to,
            "userId": userId,
            "viewOldBillingCycles": viewOldBillingCycles,
            "receivedOnly": filter && filter.key === "received" ? "true" : "false",
            "sentOnly": filter &&  filter.key === "sent" ? "true" : "false",
            "smsOnly": filter &&  filter.key === "otpCount" ? "true" : "false",
            "hasTimestampOnly": filter &&  filter.key === "hasTimestamp" ? "true" : "false",
            "filteredCompanyName": filter && filter.key === "companyName" && filter.value !== "" ? filter.value : null,
            "filteredFiscalCode": filter && filter.key === "fiscalCode" && filter.value !== "" ? filter.value : null,
            "filteredVatNumber": filter && filter.key === "vatNumber" && filter.value !== "" ? filter.value : null,
            "filteredHubId": filter && filter.key === "hubId" && filter.value !== "" ? filter.value : null
        };
        const response = await postRestCall(
            `${API_URL}/api/v1/usage/${service}/${item}/details/csv`,
            accessToken,
            null,
            bodyParams,
            dispatch,
            userId,
            item,
            refreshToken,
            email,
            service.toUpperCase() === "INVOICING" ? "EIP" : "SIG"
            );
        if (hubIds === null && managed === null && filteredFlow === null &&
            (!filter || (filter && filter.value === "") || (filter && filter.key === "all")) )  {
            download(response, "download.zip");
        } else {
            downloadBase64(response, "dettagli.csv");
        }
    } catch (error) {
        console.error(
            "there was an error fetching the user's package details csv",
            error,
        );
    } finally {
        dispatch({ type: DOWNLOAD_DETAILS_CSV_END });
    }
};

export const DOWNLOAD_DETAILS_SELECTION_CSV_START = "DOWNLOAD_DETAILS_SELECTION_CSV_START";
export const DOWNLOAD_DETAILS_SELECTION_CSV_END = "DOWNLOAD_DETAILS_SELECTION_CSV_END";

export const downloadDetailsSelectionCsv = (
    item,
    userId,
    accessToken,
    service,
    from,
    to,
    viewOldBillingCycles,
    hubIds,
    managed,
    refreshToken,
    email,
    filteredPackage,
    filter,
    filteredFlow,
    managedItems
) => async (dispatch) => {
    dispatch({ type: DOWNLOAD_DETAILS_SELECTION_CSV_START });
    try {
        const bodyParams = {
            "filteredFlow": filteredFlow && filteredFlow.value ? filteredFlow.value : null,
            "filteredPackage": filteredPackage,
            "from": from,
            "hubIds": hubIds,
            "managed": managed,
            "to": to,
            "userId": userId,
            "viewOldBillingCycles": viewOldBillingCycles,
            "receivedOnly": filter && filter.key === "received" ? "true" : "false",
            "sentOnly": filter &&  filter.key === "sent" ? "true" : "false",
            "smsOnly": filter &&  filter.key === "otpCount" ? "true" : "false",
            "hasTimestampOnly": filter &&  filter.key === "hasTimestamp" ? "true" : "false",
            "filteredCompanyName": filter && filter.key === "companyName" && filter.value !== "" ? filter.value : null,
            "filteredFiscalCode": filter && filter.key === "fiscalCode" && filter.value !== "" ? filter.value : null,
            "filteredVatNumber": filter && filter.key === "vatNumber" && filter.value !== "" ? filter.value : null,
            "filteredHubId": filter && filter.key === "hubId" && filter.value !== "" ? filter.value : null
        };
        const response = await postRestCall(
            `${API_URL}/api/v1/usage/${service}/${item}/selection/details/csv`,
            accessToken,
            null,
            bodyParams,
            dispatch,
            userId,
            item,
            refreshToken,
            email,
            service.toUpperCase() === "INVOICING" ? "EIP" : "SIG"
            );
            
        const { details } = response;
        details.forEach(d => {
            const filteredItems = managedItems.filter(i => i.fiscalCode === d.author || i.vatNumber === d.author);
            if (filteredItems && filteredItems.length > 0) {
                d.companyName = filteredItems[0].value;
                d.vatNumber = filteredItems[0].vatNumber;
                d.fiscalCode = filteredItems[0].fiscalCode;
            }
        });

        const invoicingHeader = "Codice fiscale;Partita iva;Ragione sociale;Data consumo;Identificativo documento;Numero documento;Tipologia;Flusso;\n";
        const signatureHeader = "Codice fiscale proprietario;Partita iva proprietario;Ragione sociale proprietario;Data;Tipo documento;hubId;Num firme;Num firmatari;Num SMS;Marca temporale\n";
        const checkupHeader = "CF Azienda;Partita iva;Ragione sociale;Data consumo\n";
        const payHeader = "Ragione sociale;Codice fiscale;Partita iva;Data consumo;Identificativo transazioni;Totale transazioni;Origine\n";

        let stringBuffer = "";
        if (service.toUpperCase().indexOf("INVOICING") >= 0) {
            stringBuffer += invoicingHeader;
        } else if (service.toUpperCase().indexOf("SIGNATURE") >= 0) {
            stringBuffer += signatureHeader;
        } else if (service.toUpperCase().indexOf("CHECKUP") >= 0) {
            stringBuffer += checkupHeader;
        } else if (service.toUpperCase().indexOf("PAY") >= 0) {
            stringBuffer += payHeader;
        }
        if (details != null) {
            details.forEach(detail => {
                if (service.toUpperCase().indexOf("INVOICING") >= 0) {
                    stringBuffer += detail.fiscalCode + ";";
                    stringBuffer += detail.vatNumber + ";";
                    stringBuffer += detail.companyName + ";";
                    stringBuffer += detail.timestamp + ";";
                    stringBuffer += detail.hubId + ";";
                    stringBuffer += detail.number + ";";
                    stringBuffer += detail.isSent ? "Inviata;" : "Ricevuta;";
                    stringBuffer += detail.flow + "\n";
                } else if (service.toUpperCase().indexOf("SIGNATURE") >= 0) {
                    stringBuffer += detail.owner_fiscal_code + ";";
                    stringBuffer += detail.vatNumber + ";";
                    stringBuffer += detail.companyName + ";";
                    stringBuffer += detail.timestamp + ";";
                    stringBuffer += detail.documentType + ";";
                    stringBuffer += detail.hubId + ";";
                    stringBuffer += detail.signaturesCount + ";";
                    stringBuffer += detail.signersCount + ";";
                    stringBuffer += detail.otpCount + ";";
                    stringBuffer += detail.hasSignatureTimestamp + "\n";
                } else if (service.toUpperCase().indexOf("CHECKUP") >= 0) {
                    stringBuffer += detail.author + ";";
                    stringBuffer += detail.vatNumber + ";";
                    stringBuffer += detail.companyName + ";";
                    stringBuffer += detail.timestamp + "\n";
                } else if (service.toUpperCase().indexOf("PAY") >= 0) {
                    stringBuffer += detail.companyName + ";";
                    stringBuffer += detail.author + ";";
                    stringBuffer += detail.vatNumber + ";";
                    stringBuffer += detail.timestamp + ";";
                    stringBuffer += detail.hubId + ";";
                    stringBuffer += detail.transactionsCount + ";";
                    stringBuffer += detail.source + "\n";
                }
            });
        }
        downloadTextFile("details.csv", stringBuffer);
    } catch (error) {
        console.error(
            "there was an error fetching the user's package details csv",
            error,
        );
    } finally {
        dispatch({ type: DOWNLOAD_DETAILS_SELECTION_CSV_END });
    }
};

export const downloadLocalDetailsSelectionCsv = (
    details,
    service,
    checkedItems,
    managedItems,
    documentsTitles,
) => async (dispatch) => {
    dispatch({ type: DOWNLOAD_DETAILS_SELECTION_CSV_START });
    if (checkedItems && Object.keys(checkedItems).length > 0) {
        if (service === "ai"){
            details = details.filter(d => checkedItems.hasOwnProperty(d.slotId))
        }else if (service !== "checkup") {
            details = details.filter(d => checkedItems.hasOwnProperty(d.hubId))
        } else {
            details = details.filter(d => checkedItems.hasOwnProperty(d.author))
        }
    }
    try {
        details.forEach((d) => {
            const filteredItems = managedItems.filter(
                (i) =>
                    i.fiscalCode === d.author || i.vatNumber === d.author,
            );
            if (filteredItems && filteredItems.length > 0) {
                d.companyName = filteredItems[0].value;
                d.vatNumber = filteredItems[0].vatNumber;
                d.fiscalCode = filteredItems[0].fiscalCode;
            }
            if (documentsTitles) {
                const filteredDocs = documentsTitles.filter(
                    (doc) => doc.code === d.documentType,
                );
                if (filteredDocs && filteredDocs.length > 0) {
                    d.documentType = filteredDocs[0].title;
                }
            }
        });
    
        const invoicingHeader =
            "Codice fiscale;Partita iva;Ragione sociale;Data consumo;Identificativo documento;Numero documento;Tipologia;Flusso;\n";
        const signatureHeader =
            "Codice fiscale proprietario;Partita iva proprietario;Ragione sociale proprietario;Data;Tipo documento;hubId;Num firme;Num firmatari;Num SMS;Marca temporale\n";
        const checkupHeader =
            "CF Azienda;Partita iva;Ragione sociale;Data consumo\n";
        const payHeader =
            "Ragione sociale;Codice fiscale;Partita iva;Data consumo;Identificativo transazioni;Totale transazioni;Origine\n";
        const aiHeader =
            "Codice fiscale;Stato;Data Attivazione;Data Fine Sospensione\n";
    
        let stringBuffer = "";
        if (service.toUpperCase().indexOf("INVOICING") >= 0) {
            stringBuffer += invoicingHeader;
        } else if (service.toUpperCase().indexOf("SIGNATURE") >= 0) {
            stringBuffer += signatureHeader;
        } else if (service.toUpperCase().indexOf("CHECKUP") >= 0) {
            stringBuffer += checkupHeader;
        } else if (service.toUpperCase().indexOf("PAY") >= 0) {
            stringBuffer += payHeader;
        } else if (service.toUpperCase().indexOf("AI") >= 0) {
            stringBuffer += aiHeader;
        }
    
        if (details != null) {
            details.forEach((detail) => {
                const getValueOrDefault = (value) => value != null ? value : '';
    
                if (service.toUpperCase().indexOf("INVOICING") >= 0) {
                    stringBuffer += getValueOrDefault(detail.fiscalCode) + ";";
                    stringBuffer += getValueOrDefault(detail.vatNumber) + ";";
                    stringBuffer += getValueOrDefault(detail.companyName) + ";";
                    stringBuffer += getValueOrDefault(detail.timestamp) + ";";
                    stringBuffer += getValueOrDefault(detail.hubId) + ";";
                    stringBuffer += getValueOrDefault(detail.number) + ";";
                    stringBuffer += detail.sent ? "Inviata;" : "Ricevuta;";
                    stringBuffer += getValueOrDefault(detail.flow) + "\n";
                } else if (service.toUpperCase().indexOf("SIGNATURE") >= 0) {
                    stringBuffer += getValueOrDefault(detail.owner_fiscal_code) + ";";
                    stringBuffer += getValueOrDefault(detail.vatNumber) + ";";
                    stringBuffer += getValueOrDefault(detail.companyName) + ";";
                    stringBuffer += getValueOrDefault(detail.timestamp) + ";";
                    stringBuffer += getValueOrDefault(detail.documentType) + ";";
                    stringBuffer += getValueOrDefault(detail.hubId) + ";";
                    stringBuffer += getValueOrDefault(detail.signaturesCount) + ";";
                    stringBuffer += getValueOrDefault(detail.signersCount) + ";";
                    stringBuffer += getValueOrDefault(detail.otpCount) + ";";
                    stringBuffer += getValueOrDefault(detail.hasSignatureTimestamp) + "\n";
                } else if (service.toUpperCase().indexOf("CHECKUP") >= 0) {
                    stringBuffer += getValueOrDefault(detail.author) + ";";
                    stringBuffer += getValueOrDefault(detail.vatNumber) + ";";
                    stringBuffer += getValueOrDefault(detail.companyName) + ";";
                    stringBuffer += getValueOrDefault(detail.timestamp) + "\n";
                } else if (service.toUpperCase().indexOf("PAY") >= 0) {
                    stringBuffer += getValueOrDefault(detail.companyName) + ";";
                    stringBuffer += getValueOrDefault(detail.author) + ";";
                    stringBuffer += getValueOrDefault(detail.vatNumber) + ";";
                    stringBuffer += getValueOrDefault(detail.timestamp) + ";";
                    stringBuffer += getValueOrDefault(detail.hubId) + ";";
                    stringBuffer += getValueOrDefault(detail.transactionsCount) + ";";
                    stringBuffer += getValueOrDefault(detail.source) + "\n";
                } else if (service.toUpperCase().indexOf("AI") >= 0) {
                    stringBuffer += getValueOrDefault(detail.slotFiscalCode) + ";";
                    stringBuffer += getValueOrDefault(detail.slotStatus) + ";";
                    stringBuffer += getValueOrDefault(detail.dateUpdated) + ";";
                    stringBuffer += getValueOrDefault(detail.freeDate) + ";\n";
                }
            });
        }
        downloadTextFile("details.csv", stringBuffer);
    } catch (error) {
        console.error(
            "there was an error fetching the user's package details csv",
            error,
        );
    } finally {
        dispatch({ type: DOWNLOAD_DETAILS_SELECTION_CSV_END });
    }
    
};

export const DOWNLOAD_HISTORY_FILE_START = "DOWNLOAD_HISTORY_FILE_START";
export const DOWNLOAD_HISTORY_FILE_END = "DOWNLOAD_HISTORY_FILE_END";
export const DOWNLOAD_HISTORY_FILE_SUCCESS = "DOWNLOAD_HISTORY_FILE_SUCCESS";
export const DOWNLOAD_HISTORY_FILE_ERROR = "DOWNLOAD_HISTORY_FILE_ERROR";

export const downloadHistoryCsv = (
    idList,
    fileName,
    item,
    userId,
    accessToken,
    refreshToken,
    email,
    service
) => async (dispatch) => {
    dispatch({ type: DOWNLOAD_HISTORY_FILE_START });
    try {
        const sanitizedQueryString = getSanitizedQueryString({ idList });

        const response = await getRestCall(
            `${ARCHIVER_READ_API_URL}/api/v1/files/download?${sanitizedQueryString}`,
            accessToken,
            null,
            dispatch,
            userId,
            item,
            refreshToken,
            email,
            service.toUpperCase() === "INVOICING" ? "EIP" : "SIG",
            "application/octet-stream"
        );
        fileDownload(response, "download.zip");
        dispatch({ type: DOWNLOAD_HISTORY_FILE_SUCCESS });
    } catch (error) {
        console.error(
            "there was an error downloading historical csv",
            error,
        );
        dispatch({ type: DOWNLOAD_HISTORY_FILE_ERROR, error });
    } finally {
        dispatch({ type: DOWNLOAD_HISTORY_FILE_END });
    }
};

export const DOWNLOAD_ALL_HISTORY_FILE_START = "DOWNLOAD_ALL_HISTORY_FILE_START";
export const DOWNLOAD_ALL_HISTORY_FILE_END = "DOWNLOAD_ALL_HISTORY_FILE_END";
export const DOWNLOAD_ALL_HISTORY_FILE_SUCCESS = "DOWNLOAD_ALL_HISTORY_FILE_SUCCESS";
export const DOWNLOAD_ALL_HISTORY_FILE_ERROR = "DOWNLOAD_ALL_HISTORY_FILE_ERROR";

export const downloadAllHistoryCsv = (
    fileName,
    item,
    userId,
    accessToken,
    refreshToken,
    email,
    service
) => async (dispatch) => {
    dispatch({ type: DOWNLOAD_ALL_HISTORY_FILE_START });
    try {
        const sanitizedQueryStringGetAllFiles = getSanitizedQueryString({
            online: false,
            page: 0,
            itemsPerPage: 999999
        });
        const responseAllFiles = await getRestCall(
            `${API_URL}/api/v1/history/services/${service}/items/${item}/files?${sanitizedQueryStringGetAllFiles}`,
            accessToken,
            null,
            dispatch,
            userId,
            item,
            refreshToken,
            email,
            service.toUpperCase() === "INVOICING" ? "EIP" : "SIG"
        );
        const { fileMetadataItemList } = responseAllFiles;
        const idList = fileMetadataItemList.map(f => f.id);
        const sanitizedQueryString = getSanitizedQueryString({ idList });
        const response = await getRestCall(
            `${ARCHIVER_READ_API_URL}/api/v1/files/download?${sanitizedQueryString}`,
            accessToken,
            null,
            dispatch,
            userId,
            item,
            refreshToken,
            email,
            service.toUpperCase() === "INVOICING" ? "EIP" : "SIG",
            "application/octet-stream"
        );
        fileDownload(response, "download.zip");
        dispatch({ type: DOWNLOAD_ALL_HISTORY_FILE_SUCCESS });
    } catch (error) {
        console.error(
            "there was an error downloading historical csv",
            error,
        );
        dispatch({ type: DOWNLOAD_ALL_HISTORY_FILE_ERROR, error });
    } finally {
        dispatch({ type: DOWNLOAD_ALL_HISTORY_FILE_END });
    }
};