import React, { useState, useCallback, useRef } from "react";
import { Flex, Box } from "reflexbox";
import styled, { css } from "styled-components";
import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import { fadeIn } from "../../commons/styles/animations";

const FlexContainer = styled(Flex)`
    position: relative;
    background: #f2f5f8;
    border: solid 1px #c9d9e8;
    height: 40px;
    padding: 8px 40px 8px 16px;
    transition: border 0.3s ease;
    align-items: center;
    width: 100%;
    min-width: 100%;
    cursor: pointer;
    :hover {
        border: ${(props) => (props.withoutHover ? "none" : "solid 1px #0090d1")};
    }
`;

const OptionLabel = styled(Box)`
    flex-grow: 1;
    width: 100%;
    color: ${(props) => (props.placeholder ? "#9da6ad" : "#000")};
    font-size: 14px;
    line-height: 18px;
    font-family: Roboto;
    font-weight: 400;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

const Icon = styled(Box)`
    position: absolute;
    top: 0;
    right: 0;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
`;

const Dropdown = styled(Flex)`
    position: absolute;
    top: calc(100% + 4px);
    right: 0;
    width: 100%;
    animation: ${fadeIn} 0.3s;
    max-height: 200px;
    overflow-y: auto;
    border: solid 1px #c9d9e8;
    z-index: 4;
`;

const Option = styled(Box)`
    height: 40px;
    background: #fff;
    display: flex;
    color: #152935;
    font-size: 14px;
    font-weight: 400;
    transition: border 0.3s ease, background 0.3s ease, color 0.3s ease;
    cursor: pointer;
    border: solid 1px #fff;
    ${(props) =>
        props.selected &&
        css`
            background: #def0f7;
            color: #0090d1;
            font-weight: 500;
            border: solid 1px #def0f7;
        `};
    :hover {
        border: solid 1px #0090d1;
    }
`;

const Label = styled.div`
    color: #152935;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    margin-bottom: 12px;
`;

export const Select = ({ value, onChange, options, label, placeholder, ...rest }) => {
    const ref = useRef(null);
    const [open, setOpen] = useState(false);

    const handleOutsideClick = useCallback((event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setOpen(false);
            document.removeEventListener("mousedown", handleOutsideClick);
        }
    }, []);

    const handleOpen = useCallback(() => {
        const newOpen = !open;
        setOpen(newOpen);
        if (newOpen) {
            document.addEventListener("mousedown", handleOutsideClick);
        }
    }, [handleOutsideClick, open]);

    const getSelectionHandler = (selectedOption) => () => {
        onChange(selectedOption);
    };

    return (
        <>
            {label && <Label>{label}</Label>}
            <FlexContainer ref={ref} onClick={handleOpen} {...rest}>
                <OptionLabel placeholder={!value}>
                    {value ? (
                        value.label || value.value
                    ) : placeholder ? placeholder : (
                        <FormattedMessage id="select.placeholder" />
                    )}
                </OptionLabel>
                <Icon>
                    <FontAwesomeIcon icon={faChevronDown} />
                </Icon>
                {open && (
                    <Dropdown flexDirection="column">
                        {options.map((option) => (
                            <Option
                                selected={value && option.value === value.value}
                                px="16px"
                                py="8px"
                                key={option.key}
                                onClick={getSelectionHandler(option)}
                            >
                                {option.label || option.value}
                            </Option>
                        ))}
                    </Dropdown>
                )}
            </FlexContainer>
        </>
    );
};
