import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import Select, { Option } from "rc-select";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-regular-svg-icons";

import "./index.css";

/** Search bar with autocompletion. */
export default class Searchbar extends Component {
    static propTypes = {
        /** Specifies the datasource used to populate searchbar's dropdown. */
        datasource: PropTypes.arrayOf(
            PropTypes.shape({ key: PropTypes.string, label: PropTypes.string }),
        ),
        /** Specifies whether the searchbar is disabled or not. */
        disabled: PropTypes.bool,
        /** Specifies the searchbar's placeholder. */
        placeholder: PropTypes.node,
        /** Function called on user's search input. */
        onSearch: PropTypes.func,
        /** Function called on searchbar's input change. */
        onChange: PropTypes.func,
        /** Function called on searchbar's input blur. */
        onBlur: PropTypes.func,
        /** Specifies the searchbar's value. */
        value: PropTypes.string,
        /** Specifies an ptional error message. */
        error: PropTypes.node,
        /** Used to style the component. */
        className: PropTypes.string,
    };

    static defaultProps = {
        datasource: [],
    };

    constructor(props) {
        super(props);
        this.state = {
            value: "",
            open: false,
        };
        this.getSanitizedSelectProps = this.getSanitizedSelectProps.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onClick = this.onClick.bind(this);
        this.onBlur = this.onBlur.bind(this);
    }

    onSelect(value) {
        const { onSearch, datasource } = this.props;
        this.setState({ open: false });
        if (!onSearch) {
            return;
        }
        const datasourceOption = datasource.find(({ key }) => key === value);
        onSearch({
            datasourceKey: datasourceOption && datasourceOption.key,
            value: (datasourceOption && datasourceOption.label) || value,
        });
    }

    onClick() {
        const { onSearch, datasource } = this.props;
        if (!onSearch) {
            return;
        }
        const { value } = this.state;
        const datasourceOption = datasource.find(
            ({ key, label }) => label.toLowerCase() === value.toLowerCase() || key.toLowerCase() === value.toLowerCase()
        );

        onSearch({
            datasourceKey: (datasourceOption && datasourceOption.key),
            value,
        });
    }

    onChange(value) {
        const { onChange, datasource } = this.props;
        const datasourceValueByLabel = datasource.find(
            ({ key, label }) => label.toLowerCase() === value.toLowerCase() || key.toLowerCase() === value.toLowerCase()
        );
        this.setState({
            value,
            open: !!(!datasourceValueByLabel && value),
        });
        if (onChange) {
            onChange(value);
        }
    }

    onBlur() {
        const { onBlur } = this.props;
        this.setState({ open: false });
        if (onBlur) {
            onBlur();
        }
    }

    /**
     * rc-select checks for props existance using the "in" operator.
     * This means that for properties not to be incorrectly specified,
     * they need not to be passed.
     * This method acts as a filter to avoid just that.
     */
    getSanitizedSelectProps() {
        const { value } = this.props;
        const sanitizedProps = {};
        if (value) {
            sanitizedProps.value = value;
        }
        return sanitizedProps;
    }

    render() {
        const {
            datasource,
            disabled,
            placeholder,
            error,
            className,
        } = this.props;
        const { open } = this.state;
        return (
            <Fragment>
                <div
                    className={classNames(
                        className,
                        "arc-Searchbar-container",
                        disabled && "arc-Searchbar-container-disabled",
                        !disabled && error && "arc-Searchbar-container-error",
                    )}
                >
                    <Select
                        combobox
                        prefixCls="arc-Searchbar"
                        disabled={disabled}
                        placeholder={placeholder}
                        onSelect={this.onSelect}
                        onSearch={this.onChange}
                        notFoundContent=""
                        filterOption
                        optionFilterProp="children"
                        optionLabelProp="children"
                        open={open}
                        onBlur={this.onBlur}
                        {...this.getSanitizedSelectProps()}
                    >
                        {datasource.map(({ key, label }) => (
                            <Option key={key}>{label}</Option>
                        ))}
                    </Select>
                    <button
                        className={classNames(
                            "arc-Searchbar-icon-container",
                            disabled
                                ? "arc-Searchbar-icon-container-disabled"
                                : "arc-Searchbar-icon-container-enabled",
                        )}
                        onClick={this.onClick}
                    >
                        <FontAwesomeIcon
                            className="search-icon"
                            icon={faSearch}
                        />
                    </button>
                </div>
                {!disabled && (
                    <div className="arc-Searchbar-error-message">{error}</div>
                )}
            </Fragment>
        );
    }
}
