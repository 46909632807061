import React from "react";
import ReactDOM from "react-dom";
import { ParentApp } from "@mondora/microfrontends";
import { App } from "./containers/app";
import { ENV, ITEM, ITEM_UUID, LANGUAGE, ACCESS_TOKEN, REFRESH_TOKEN, EMAIL, USER_ID, STUDIO } from "./env";
import { ThemeProvider } from "@ts-digital/vrc";
import { createGlobalStyle } from "styled-components";
import { IntlProvider } from "react-intl";
import it from "./i18n/it.json";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { createStore, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import { reducers } from "./reducers";
import { trackEvents } from "./middleware/trackEvents";

const logger = createLogger({ collapsed: true });
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk, logger, trackEvents)));

export let parentApp = null;

const GlobalStyles = createGlobalStyle`
    html,
    body {
        margin: 0;
        background: #fff;
    }
`;

const messages = { it };

const getAppJsx = (
    locale,
    itemId,
    itemUuid,
    userId,
    email,
    accessToken,
    refreshToken,
    studio
) => (
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <IntlProvider locale={locale} messages={messages[locale] || it}>
                    <ThemeProvider>
                        <App
                            email={email}
                            itemId={itemId}
                            itemUuid={itemUuid}
                            accessToken={accessToken}
                            refreshToken={refreshToken}
                            userId={userId}
                            studio={studio}
                        />
                    </ThemeProvider>
                </IntlProvider>
            </BrowserRouter>
        </Provider>
        <GlobalStyles />
    </React.StrictMode>
);

if (ENV !== "production") {
    ReactDOM.render(
        getAppJsx(
            LANGUAGE,
            ITEM,
            ITEM_UUID,
            USER_ID,
            EMAIL,
            ACCESS_TOKEN,
            REFRESH_TOKEN,
            STUDIO
        ),
        document.getElementById("root"),
    );
} else {
    parentApp = new ParentApp({
        onLaunch(launchData) {
            const {
                email,
                language,
                itemId,
                itemUuid,
                userId,
                accessToken,
                refreshToken,
                studio,
            } = launchData;
            ReactDOM.render(
                getAppJsx(
                    language.toLowerCase(),
                    itemId,
                    itemUuid,
                    userId,
                    email,
                    accessToken,
                    refreshToken,
                    studio
                ),
                document.getElementById("root"),
            );
        },
    });
}
