import { API_URL } from "../../env";
import { getRestCall } from "../../utils/rest-api-call";

export const GET_PERMISSIONS_START = "GET_PERMISSIONS_START";
export const GET_PERMISSIONS_END = "GET_PERMISSIONS_END";
export const GET_PERMISSIONS_SUCCESS = "GET_PERMISSIONS_SUCCESS";

export const getPermissions = (itemUuid, accessToken, refreshToken, email, userId) => async (dispatch) => {
    dispatch({ type: GET_PERMISSIONS_START });
    try {
        const response = await getRestCall(
            `${API_URL}/api/v1/usage/${itemUuid}/permissions`,
            accessToken,
            null,
            dispatch,
            userId,
            itemUuid,
            refreshToken,
            email
        );
        dispatch({
            type: GET_PERMISSIONS_SUCCESS,
            permissions: response,
        });
    } catch (error) {
        console.error("error getting permissions", error);
    } finally {
        dispatch({ type: GET_PERMISSIONS_END });
    }
};
