import { getSanitizedQueryString } from "../../utils";
import { getRestCall } from "../../utils/rest-api-call";
import { API_URL } from "../../env";

export const GET_FLOWS_START = "GET_FLOWS_START";
export const GET_FLOWS_END = "GET_FLOWS_END";
export const GET_FLOWS_SUCCESS = "GET_FLOWS_SUCCESS";

export const getFlows = (
    packageId,
    item,
    userId,
    accessToken,
    viewOldBillingCycles,
    refreshToken,
    email,
    service
) => async (dispatch) => {
    dispatch({ type: GET_FLOWS_START });
    try {
        const sanitizedQueryString = getSanitizedQueryString({
            userId,
            viewOldBillingCycles,
        });
        if (packageId) {
            const response = await getRestCall(
                `${API_URL}/api/v1/consumptions/items/${item}/packages/${packageId}/flows?${sanitizedQueryString}`,
                accessToken,
                null,
                dispatch,
                userId,
                item,
                refreshToken,
                email,
                service.toUpperCase() === "INVOICING" ? "EIP" : "SIG"
            );
            dispatch({
                type: GET_FLOWS_SUCCESS,
                response
            });
        } else {
            const response = await getRestCall(
                `${API_URL}/api/v1/consumptions/items/${item}/flows?${sanitizedQueryString}`,
                accessToken,
                null,
                dispatch,
                userId,
                item,
                refreshToken,
                email,
                service.toUpperCase() === "INVOICING" ? "EIP" : "SIG"
            );
            dispatch({
                type: GET_FLOWS_SUCCESS,
                response
            });
        }
    } catch (error) {
        console.error("there was an error fetching the user's consumptions flows", error);
    } finally {
        dispatch({ type: GET_FLOWS_END });
    }
}