export const GAINSIGHT_VIEW_HOME = "GAINSIGHT_VIEW_HOME";

export const trackViewHome = (ownerId, service) => dispatch => {
    dispatch({ type: GAINSIGHT_VIEW_HOME, details: { ownerId, service } });
};

export const GAINSIGHT_VIEW_DETAILS_FROM_PACKAGE = "GAINSIGHT_VIEW_DETAILS_FROM_PACKAGE";

export const trackViewDetailsFromPackage = (ownerId, packageId, service) => dispatch => {
    dispatch({ type: GAINSIGHT_VIEW_DETAILS_FROM_PACKAGE, details: { packageId, ownerId, service } });
};

export const GAINSIGHT_VIEW_DETAILS_FROM_ITEM = "GAINSIGHT_VIEW_DETAILS_FROM_ITEM";

export const trackViewDetailsFromItem = (ownerId, itemsId, service) => dispatch => {
    dispatch({ type: GAINSIGHT_VIEW_DETAILS_FROM_ITEM, details: { itemsId, ownerId, service } });
};

export const GAINSIGHT_EXPORT_CSV_STATISTICS = "GAINSIGHT_EXPORT_CSV_STATISTICS";

export const trackExportCsvStatistics = (ownerId, itemId, service) => dispatch => {
    dispatch({ type: GAINSIGHT_EXPORT_CSV_STATISTICS, details: { itemId, ownerId, service } });
};

export const GAINSIGHT_EXPORT_CSV_DETAILS = "GAINSIGHT_EXPORT_CSV_DETAILS";

export const trackExportCsvDetails = (ownerId, itemId, service) => dispatch => {
    dispatch({ type: GAINSIGHT_EXPORT_CSV_DETAILS, details: {ownerId, itemId, service} });
};

export const GAINSIGHT_CHANGE_DATE_FILTER_DETAILS = "GAINSIGHT_CHANGE_DATE_FILTER_DETAILS";

export const trackChangeDateFilterDetails = (ownerId, itemId, service) => dispatch => {
    dispatch({ type: GAINSIGHT_CHANGE_DATE_FILTER_DETAILS, details: {ownerId, itemId, service} });
};

export const GAINSIGHT_VIEW_OLD_BILLING_CYCLES_DETAILS = "GAINSIGHT_VIEW_OLD_BILLING_CYCLES_DETAILS";

export const trackViewOldBillingCyclesDetails = (ownerId, itemId, service, includeOldCycles) => dispatch => {
    dispatch({ type: GAINSIGHT_VIEW_OLD_BILLING_CYCLES_DETAILS, details: {ownerId, itemId, service, includeOldBillingCycles: includeOldCycles} });
};

export const GAINSIGHT_FILTER_DETAILS_BY_ITEM = "GAINSIGHT_FILTER_DETAILS_BY_ITEM";

export const trackFilterDetailsByItem = (ownerId, itemId, service) => dispatch => {
    dispatch({ type: GAINSIGHT_FILTER_DETAILS_BY_ITEM, details: {ownerId, itemId, service} });
};
