import { API_URL } from "../../env";
import { postRestCall } from "../../utils/rest-api-call";

export const GET_DETAILS_START = "GET_DETAILS_START";
export const GET_DETAILS_END = "GET_DETAILS_END";
export const GET_DETAILS_SUCCESS = "GET_DETAILS_SUCCESS";

export const SORT_DETAILS_START = "SORT_DETAILS_START";
export const SORT_DETAILS_END = "SORT_DETAILS_END";
export const SORT_DETAILS_SUCCESS = "SORT_DETAILS_SUCCESS";

export const RESET_DETAILS_FILTER = "RESET_DETAILS_FILTER";

export const getDetails = (
    itemUuid,
    userId,
    accessToken,
    service,
    page,
    itemsPerPage,
    from,
    to,
    managed,
    sortBy,
    viewOldBillingCycles,
    sentOnly,
    receivedOnly,
    smsOnly,
    hasTimestampOnly,
    filteredPackage,
    refreshToken,
    email,
    filteredFlow,
    filter,
    managedItems
) => async (dispatch) => {
    dispatch({ type: GET_DETAILS_START });
    try {
        const bodyParams = {
            "filteredFlow": filteredFlow,
            "filteredPackage": filteredPackage,
            "from": from ? from.format('YYYY-MM-DD') : null,
            "hasTimestampOnly": hasTimestampOnly,
            "itemsPerPage": itemsPerPage,
            "managed": managed,
            "page": page,
            "receivedOnly": receivedOnly,
            "sentOnly": sentOnly,
            "smsOnly": smsOnly,
            "sortBy": sortBy,
            "to": to ? to.format('YYYY-MM-DD') : null,
            "userId": userId,
            "viewOldBillingCycles": viewOldBillingCycles,
            "filteredCompanyName": filter && filter.key === "companyName" && filter.value !== "" ? filter.value : null,
            "filteredFiscalCode": filter && filter.key === "fiscalCode" && filter.value !== "" ? filter.value : null,
            "filteredVatNumber": filter && filter.key === "vatNumber" && filter.value !== "" ? filter.value : null,
            "filteredHubId": filter && filter.key === "hubId" && filter.value !== "" ? filter.value : null,
        };
        const response = await postRestCall(
            `${API_URL}/api/v1/usage/${service}/${itemUuid}/details`,
            accessToken,
            null,
            bodyParams,
            dispatch,
            userId,
            itemUuid,
            refreshToken,
            email,
            service.toUpperCase() === "INVOICING" ? "EIP" : "SIG"
            );
        const { activationDate, billingCycleStartDate, billingCycleEndDate, details, size } = response;
        details.forEach(d => {
            const filteredItems = managedItems.filter(i => i.fiscalCode === d.author);
            if (filteredItems && filteredItems.length > 0) {
                d.companyName = filteredItems[0].value;
                d.vatNumber = filteredItems[0].vatNumber;
            }
        });
        dispatch({
            type: GET_DETAILS_SUCCESS,
            details,
            size,
            billingCycleStartDate,
            billingCycleEndDate,
            activationDate
        });
    } catch (error) {
        console.error("there was an error fetching the user's details", error);
    } finally {
        dispatch({ type: GET_DETAILS_END });
    }
};

export const sortDetails = (items, sortingBy, filtered, billingCycleStart, billingCycleEnd, activationDate) => async (dispatch) => {
    dispatch({ type: SORT_DETAILS_START });
    const sortingKey = sortingBy.substring(0, sortingBy.indexOf("."));
    const sortingType = sortingBy.substring(sortingBy.indexOf(".")+1);
    const itemReturned = sortingType === "descend" ? items.filter(p => p != null).sort(
        (a,b) => {
            if (a && b && a[sortingKey] && b[sortingKey]) {
                if (a[sortingKey] > b[sortingKey]) {
                    return 1;
                } else if (a[sortingKey] < b[sortingKey]) {
                    return -1;
                }
            }
            return 0;
        }) : items.filter(p => p != null).sort(
        (a,b) => {
            if (a && b && a[sortingKey] && b[sortingKey]) {
                if (a[sortingKey] < b[sortingKey]) {
                    return 1;
                } else if (a[sortingKey] > b[sortingKey]) {
                    return -1;
                }
            }
            return 0;
        });
    const filteredItemsReturned = filtered ? sortingType === "descend" ? filtered.filter(p => p != null).sort(
        (a,b) => {
            if (a && b && a[sortingKey] && b[sortingKey]) {
                if (a[sortingKey] > b[sortingKey]) {
                    return 1;
                } else if (a[sortingKey] < b[sortingKey]) {
                    return -1;
                }
            }
            return 0;
        }) : filtered.filter(p => p != null).sort(
        (a,b) => {
            if (a && b && a[sortingKey] && b[sortingKey]) {
                if (a[sortingKey] < b[sortingKey]) {
                    return 1;
                } else if (a[sortingKey] > b[sortingKey]) {
                    return -1;
                }
            }
            return 0;
        }) : [];
    dispatch({
        type: SORT_DETAILS_SUCCESS,
        details: itemReturned,
        filtered: filteredItemsReturned,
        billingCycleStart,
        billingCycleEnd,
        activationDate
    });
    dispatch({ type: SORT_DETAILS_END });
};

export const resetDetailsFilter = (details, billingCycleStart, billingCycleEnd, activationDate) => async (dispatch) => {
    dispatch({
        type: RESET_DETAILS_FILTER,
        details: details,
        billingCycleStart: billingCycleStart,
        billingCycleEnd: billingCycleEnd,
        activationDate: activationDate
    });
};
