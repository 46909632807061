import PropTypes from "prop-types";
import React from "react";
import {
    AlertPrimaryButton,
    AlertSecondaryButton,
    AlertTertiaryButton,
    DefaultPrimaryButton,
    DefaultSecondaryButton,
    DefaultTertiaryButton,
    GhostPrimaryButton,
    GhostSecondaryButton,
    GhostTertiaryButton,
    ElevatedFlex,
} from "./styled";
import Typography, { TypographyVariants } from "../typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "reflexbox";

const componentsMap = {
    alert: {
        primary: AlertPrimaryButton,
        secondary: AlertSecondaryButton,
        tertiary: AlertTertiaryButton,
    },
    default: {
        primary: DefaultPrimaryButton,
        secondary: DefaultSecondaryButton,
        tertiary: DefaultTertiaryButton,
    },
    ghost: {
        primary: GhostPrimaryButton,
        secondary: GhostSecondaryButton,
        tertiary: GhostTertiaryButton,
    },
};
/**
 * `Button` styled component.
 *
 * All additional props are directly passed to the underlying `button` HTML element.
 *
 * To override `button` DOM node use `as` prop (see [here](https://www.styled-components.com/docs/api#as-polymorphic-prop)).
 */
export const Button = ({
    children,
    colorScheme,
    kind,
    size,
    disabled,
    onClick,
    faIcon,
    ...rest
}) => {
    const ChosenComponent = componentsMap[colorScheme][kind];
    return (
        <ChosenComponent
            colorScheme={colorScheme}
            kind={kind}
            size={size}
            disabled={disabled}
            onClick={onClick}
            {...rest}
        >
            <ElevatedFlex>
                {faIcon && (
                    <Box mr="8px">
                        <Typography
                            variant={TypographyVariants.BUTTONS_AND_CTAS}
                        >
                            <FontAwesomeIcon icon={faIcon} />
                        </Typography>
                    </Box>
                )}
                <Box>
                    <Typography variant={TypographyVariants.BUTTONS_AND_CTAS}>
                        {children}
                    </Typography>
                </Box>
            </ElevatedFlex>
        </ChosenComponent>
    );
};

Button.defaultProps = {
    colorScheme: "default",
    kind: "primary",
    size: "medium",
};

Button.propTypes = {
    /** @ignore */
    children: PropTypes.node,
    /** Specifies the button's color. */
    colorScheme: PropTypes.oneOf(["default", "ghost", "alert"]),
    /** Specifies the button's kind.*/
    kind: PropTypes.oneOf(["primary", "secondary", "tertiary"]),
    /** Specifies the button' size. */
    size: PropTypes.oneOf(["small", "medium", "large"]),
    /** Specifies whether the button must be disabled or not. */
    disabled: PropTypes.bool,
    /** Specifies on-click calling function */
    onClick: PropTypes.func,
};
