import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Flex, Box } from "reflexbox";
import { FormattedMessage, useIntl } from "react-intl";
import {
    RootFlex,
    PackageUsageText,
    InsertionDateText,
    PieChartAxes,
    PieChartBox,
    AbsoluteUsageDataFlex,
    PieUsageText, PieUsageHelperTextSmall
} from "../styled";
import { LegendItem } from "../../legend-item";
import { Pie, PieChart } from "recharts";

const TOTAL_USAGE_COLOR = "#0d6b7f";
const HIGH_USAGE_COLOR = "#7745ec";
const REMAINING_USAGE_COLOR = "#9fc3cd";

export const EcobonusPackageCard = ({
         family,
         familyReplacement,
         LOW_VALUE_FILES,
         HIGH_VALUE_FILES,
         billingCycleStart,
         billingCycleEnd
     }) => {

    const { formatMessage } = useIntl();
    const [pieData, setPieData] = useState([]);

    useEffect(() => {
        const highRegularUsage = HIGH_VALUE_FILES ? HIGH_VALUE_FILES.regularUsage : 0;
        const highRemainingValue = HIGH_VALUE_FILES ? HIGH_VALUE_FILES.capacity - highRegularUsage : 0;

        if (LOW_VALUE_FILES && HIGH_VALUE_FILES) {
            setPieData([
                {
                    id: "high",
                    value: HIGH_VALUE_FILES.regularUsage,
                    fill: HIGH_USAGE_COLOR,
                },
                {
                    id: formatMessage({ id: "pie.slice.usage.remaining" }),
                    value: 10 + HIGH_VALUE_FILES.capacity - HIGH_VALUE_FILES.regularUsage - 1,
                    fill: REMAINING_USAGE_COLOR,
                },
                {
                    id: "a",
                    value: 0.1,
                    fill: REMAINING_USAGE_COLOR,
                },
                {
                    id: "b",
                    value: 0.1,
                    fill: REMAINING_USAGE_COLOR,
                },
                {
                    id: "c",
                    value: 0.1,
                    fill: REMAINING_USAGE_COLOR,
                }
            ]);
        } else if (HIGH_VALUE_FILES) {
            setPieData([
                {
                    id: formatMessage({ id: "pie.slice.usage.total" }),
                    value: HIGH_VALUE_FILES.regularUsage,
                    fill: TOTAL_USAGE_COLOR,
                },
                {
                    id: formatMessage({ id: "pie.slice.usage.remaining" }),
                    value: highRemainingValue > 0 ? highRemainingValue : 0,
                    fill: REMAINING_USAGE_COLOR,
                },
            ]);
        }
    }, [LOW_VALUE_FILES, HIGH_VALUE_FILES, formatMessage, family]);

    return (
        <RootFlex>
            <Flex
                flexDirection="column"
                alignItems="center"
                width="100%">
                <Flex
                    flexWrap='wrap'
                    ml="24px"
                    mt="24px"
                    justifyContent="space-between"
                >
                    <PackageUsageText width="380px">
                        <FormattedMessage
                            id="package.card.title"
                            values={{ name: familyReplacement ? familyReplacement : family }}
                        />
                    </PackageUsageText>
                    <Box/>
                    <InsertionDateText>
                        <Box>
                            <FormattedMessage
                                id="package.card.invoicing.period"
                            />
                        </Box>
                        <Box>
                            <FormattedMessage
                                id="package.card.date.insertion.start"
                                values={{
                                    date: billingCycleStart,
                                }}
                            />
                        </Box>
                        <Box>
                            <FormattedMessage
                                id="package.card.date.insertion.end"
                                values={{
                                    date: billingCycleEnd,
                                }}
                            />
                        </Box>
                    </InsertionDateText>
                </Flex>

                <Flex>
                    {!LOW_VALUE_FILES && <PieChartAxes mr="0px" mt="150px">{0}</PieChartAxes>}
                    <PieChartBox>
                        <PieChart width={300} height={180}>
                            <Pie
                                data={pieData}
                                cx={145}
                                cy={170}
                                startAngle={180}
                                endAngle={0}
                                innerRadius={110}
                                outerRadius={130}
                                paddingAngle={1}
                                dataKey="value"
                            >
                            </Pie>
                        </PieChart>
                        <AbsoluteUsageDataFlex
                            flexDirection="column"
                            justifyContent="flex-end"
                            alignItems="center"
                        >
                            <PieUsageText>{
                                (LOW_VALUE_FILES ? LOW_VALUE_FILES.regularUsage : HIGH_VALUE_FILES ? HIGH_VALUE_FILES.regularUsage : 0)
                                +
                                (LOW_VALUE_FILES ? LOW_VALUE_FILES.extraUsage : HIGH_VALUE_FILES ? HIGH_VALUE_FILES.extraUsage : 0)}</PieUsageText>
                            <PieUsageHelperTextSmall>
                                {
                                    LOW_VALUE_FILES ? (
                                        <FormattedMessage id="package.card.pie.low.files" />
                                    ) : HIGH_VALUE_FILES ? (
                                        <FormattedMessage id="package.card.pie.high.files" />
                                    ) : null
                                }
                            </PieUsageHelperTextSmall>
                        </AbsoluteUsageDataFlex>
                    </PieChartBox>
                    {!LOW_VALUE_FILES &&
                    <PieChartAxes
                        mt="150px">{LOW_VALUE_FILES ? LOW_VALUE_FILES.capacity : HIGH_VALUE_FILES ? HIGH_VALUE_FILES.capacity : 0}</PieChartAxes>
                    }
                </Flex>
                {
                    LOW_VALUE_FILES && HIGH_VALUE_FILES ?
                        (
                            <Flex marginLeft="25px" justifyContent="center" mb="24px" mt="24px" width="100%">
                               <LegendItem
                                   color={REMAINING_USAGE_COLOR}
                                   message={
                                       <FormattedMessage
                                           id="package.card.pie.legend.ecobonus.remaining"
                                       />}
                                   showQuantity={false}
                               />
                               <LegendItem
                                   color={LOW_VALUE_FILES && HIGH_VALUE_FILES ? HIGH_USAGE_COLOR : TOTAL_USAGE_COLOR}
                                   message={<FormattedMessage id="package.card.pie.legend.ecobonus.high.value"/>}
                                   quantity={HIGH_VALUE_FILES.regularUsage}
                                   total={HIGH_VALUE_FILES.capacity}
                                   showQuantity={true}
                               />
                           </Flex>
                        ) : HIGH_VALUE_FILES ?
                        (
                            <Flex marginLeft="25px" justifyContent="center" mb="24px" mt="24px" width="100%">
                                <LegendItem
                                    color={TOTAL_USAGE_COLOR}
                                    message={<FormattedMessage id="package.card.pie.legend.ecobonus.high.usage"/>}
                                    quantity={HIGH_VALUE_FILES.regularUsage}
                                    percentage={(
                                        HIGH_VALUE_FILES.regularUsage / HIGH_VALUE_FILES.capacity * 100
                                    ).toFixed(2)
                                    }
                                    showQuantity={true}
                                />
                                <LegendItem
                                    color={REMAINING_USAGE_COLOR}
                                    message={
                                        <FormattedMessage
                                            id="package.card.pie.legend.ecobonus.high.remaining"
                                        />}
                                    quantity={(HIGH_VALUE_FILES.capacity - HIGH_VALUE_FILES.regularUsage).toFixed(2)}
                                    percentage={(
                                        (HIGH_VALUE_FILES.capacity - HIGH_VALUE_FILES.regularUsage) /
                                        (HIGH_VALUE_FILES.capacity * 100)
                                    ).toFixed(2)
                                    }
                                    showQuantity={true}
                                />
                            </Flex>
                        ) : null
                    }
            </Flex>
        </RootFlex>
    );
};

EcobonusPackageCard.propTypes = {
    id: PropTypes.number.isRequired,
    timestamp: PropTypes.string.isRequired,
    capacity: PropTypes.number.isRequired,
    regularUsage: PropTypes.number.isRequired,
    extraUsage: PropTypes.number.isRequired,
    onUpgrade: PropTypes.func.isRequired,
    onExpand: PropTypes.func.isRequired,
};
