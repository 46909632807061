import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Root = styled.a`
    height: 56px;
    min-width: 56px;
    text-decoration: none;
    position: fixed;
    bottom: 24px;
    right: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Roboto;
    font-size: 16px;
    border-radius: 28px;
    border: none;
    outline: none;
    padding: 0 16px;
    z-index: 20;
    cursor: pointer;
    background: #fff;
    box-shadow: 0 4px 8px 0 rgba(21, 41, 53, 0.32);
    transition: box-shadow 0.3s ease;
    &:active {
        box-shadow: 0 2px 4px 0 rgba(21, 41, 53, 0.32);
    }
`;

export const Content = styled.span`
    color: #fff;
    z-index: 1;
    color: rgb(0, 144, 209);
    font-family: Roboto;
    font-size: 16px;
`;

export const Icon = styled(FontAwesomeIcon)`
    margin-right: 8px;
`;
