import React from "react";
import PropTypes from "prop-types";
import {
    CheckboxContainer,
    HiddenCheckbox,
    StyledCheckbox,
    StyledCheckBoxIcon,
    StyledLabel,
    StyledSpan,
} from "./styled";
import { faCheck, faMinus } from "@fortawesome/pro-regular-svg-icons";

export const Checkbox = ({
    checked,
    label,
    id,
    disabled,
    onChange,
    onClick,
    indeterminate,
}) => {
    const handleChange = (event) => {
        onChange(event.target.checked, event);
    };

    const handleClick = (event) => {
        if (onClick) {
            onClick(event.target.checked, event);
        }        
    };

    return (
        <CheckboxContainer>
            <StyledLabel disabled={disabled}>
                <HiddenCheckbox
                    checked={checked}
                    id={id}
                    disabled={disabled}
                    onChange={handleChange}
                    onClick={handleClick}
                />
                <StyledCheckbox checked={checked} disabled={disabled}>
                    {checked && (
                        <StyledCheckBoxIcon
                            icon={indeterminate ? faMinus : faCheck}
                            disabled={disabled}
                        />
                    )}
                </StyledCheckbox>
                {label && <StyledSpan>{label}</StyledSpan>}
            </StyledLabel>
        </CheckboxContainer>
    );
};

Checkbox.defaultProps = {
    indeterminate: false,
    id: null,
    disabled: false,
    onChange: () => {},
};

Checkbox.propTypes = {
    /** Checked status */
    checked: PropTypes.bool,
    /** Specifies whether the checkbox must be disabled or not. */
    disabled: PropTypes.bool,
    /** Specifies on-change calling function */
    onChange: PropTypes.func,
    /** Specifies the label of the component */
    label: PropTypes.node,
    /** Specifies the id for the input and label */
    id: PropTypes.string,
    /** Specifies whether checkbox is indeterminate */
    indeterminate: PropTypes.bool,
};
