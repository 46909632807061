import React, { useState, useCallback, useEffect, useMemo } from "react";
import { Box, Flex } from "reflexbox";
import styled from "styled-components";
import moment from "moment";
import { FormattedMessage, useIntl } from "react-intl";
import { DateFilters } from "../../components/date-filters";
import { faFileExport, faFilter } from "@fortawesome/pro-regular-svg-icons";
import { Button } from "../../components/button";
import { useSelector, useDispatch } from "react-redux";
import { getDetails, sortDetails, resetDetailsFilter } from "../../actions/details";
import { downloadLocalDetailsSelectionCsv } from "../../actions/csv";
import { getConnectedItems } from "../../actions/items";
import { Chip } from "../../components/chip";
import { UniversalSpinner } from "../../components/universal-spinner";
import { useQuery } from "../../hooks";
import { Select } from "../../components/select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Table } from "../../components/table";
import { getDetailsTableColumnsSpecification } from "../../commons/table-handler";
import { getFilters } from "../../commons/chip";
import { Pagination } from "../../components/pagination";
import { Checkbox } from "../../components/checkbox";
import { getFlows } from "../../actions/flows";
import {
    trackChangeDateFilterDetails,
    trackExportCsvDetails, trackFilterDetailsByItem,
    trackViewDetailsFromPackage, trackViewOldBillingCyclesDetails
} from "../../actions/gainsightEvents";
import { Input } from "../input";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { DrawerSlotDetails } from "./drawerSlotDetails";

const FilterTextBox = styled(Box)`
    color: rgb(21, 41, 53);
    font-family: Roboto;
    font-size: 16px;
`;

const StyledSelect = styled(Select)`
    width: 560px;
    display: block;
`;

const StyledSelectAutoWidth = styled(Select)`
    height: 40px;
    border: none;
`;

export const AnalysisPanel = ({
                            itemId,
                            itemUuid,
                            userId,
                            accessToken,
                            service,
                            studio,
                            refreshToken,
                            email,
                            packages
                        }) => {
    const query = useQuery();
    const { formatMessage } = useIntl();
    const dispatch = useDispatch();
    const [checkedItems, setCheckedItems] = useState({});
    const [totalSelected, setTotalSelected] = useState(0);
    const [checkedAllItems, setCheckedAllItems] = useState(false);

    const filteredItemFromQuery = query.get("item");
    const filteredPackageIdFromQuery = query.get("package");

    const handleSelectItems = useCallback( (checked, event) => {
        if (checked) {
            setCheckedItems({...checkedItems, [event.target.id] : checked });
        } else {
            delete checkedItems[event.target.id];
            setCheckedItems(checkedItems);
        }
        checked ? setTotalSelected(totalSelected + 1) : setTotalSelected(totalSelected - 1);
    }, [checkedItems, totalSelected]);

    const { flows, documentTypes, statistics} = useSelector( (state) => (
        {
            flows: [{key: "Tutti", label: "Tutti", value: null}].concat(state.flows.data.flows.map((f) => {
                return {
                    key: f,
                    label: f,
                    value: f
                }
            })),
            flowsLoading: !!state.flows.loadings,
            documentTypes: state.documentTypes ? state.documentTypes.data.documentTypes : [],
            statistics: state.statistics.data.statistics && state.statistics.data.statistics.length > 0 ?
                state.statistics.data.statistics : []
           // items: [...state.items.data, ...state.statistics.data.notManagedItems]  //&& state.statistics.data.notManagedItems.length > 0 ? state.items.data.concat(state.statistics.data.notManagedItems) : state.items.data
        }
    ));

    const notManagedItems = useSelector((state) => state.statistics.data.notManagedItems);
    const items = useSelector((state) => state.items.data);


    const { activationDate, billingCycleStart, billingCycleEnd, detailsSize, details, detailsFiltered } = useSelector((state) => ({
        activationDate: state.details.data.activationDate,
        billingCycleStart: state.details.data.billingCycleStartDate,
        billingCycleEnd: state.details.data.billingCycleEndDate,
        detailsFiltered: state.details.data.filtered,
        detailsSize: state.details.data.size,
        details: state.details.data.details && state.details.data.details.length > 0 ? state.details.data.details.map((detail) => ({
            ...detail,
            /*actions: (
                <Tooltip label={<FormattedMessage id="details.table.column.actions.tooltip" />} width="70px">
                    <TableDetailActionIcon
                        icon={faFileInvoice}
                        onClick={() => {
                            history.replace(`/item=${detail.key}`); //todo correct url to view invoice
                        }}
                    />
                </Tooltip>
            ),*/ //todo enable in T1
            hasTimestamp: detail.hasSignatureTimestamp ? "SI" : "NO",
            key: service === "ai" ? detail.slotId : service !== "checkup" ? detail.hubId : detail.author,
            documentTypeTitle:
                service === "signature" ?
                    documentTypes.find((docType) => {
                        return docType.code === detail.documentType
                    }) ? documentTypes.find((docType) => {
                        return docType.code === detail.documentType
                    }).title : <FormattedMessage id="details.table.column.documentType.unknown.title" /> : "",
            selected: (
                <Checkbox
                    id={service === "ai" ? detail.slotId : service !== "checkup" ? detail.hubId : detail.author}
                    checked={service === "ai" ? checkedItems[detail.slotId] : service !== "checkup" ? checkedItems[detail.hubId] : checkedItems[detail.author]}
                    onChange={handleSelectItems} />
            )
        })) : []
    }));

    const detailsLoading = useSelector((state) => state.details.loadings);

    const [includeOldInvoicingCycles, setIncludeOldInvoicingCycles] = useState(false,);
    const [dateRange, setDateRange] = useState([null, null]);
    const [previousDateRange, setPreviousDateRange] = useState([null, null]);
    const [detailsPage, setDetailsPage] = useState(0);
    const [detailsSorting, setDetailsSorting] = useState("timestamp.descend");
    const [filter, setFilter] = useState({key: "all"});
    const [filters, setFilters] = useState([]);
    const [filteredFlow, setFilteredFlow] = useState(null);
    const [filteredItem, setFilteredItem] = useState(null);
    const [filteredPackage, setFilteredPackage] = useState(null);
    const [itemsWithResetOption, setItemsWithResetOption] = useState(statistics);
    const [labelExportButton, setLabelExportButton] = useState(<FormattedMessage id="details.button.export.all" />);
    const [currentPackage, setCurrentPackage] = useState(null);
    const [filteredCompanyName, setFilteredCompanyName] = useState(null);
    const [filteredFiscalCode, setFilteredFiscalCode] = useState(null);
    const [filteredVatNumber, setFilteredVatNumber] = useState(null);
    const [filteredHubId, setFilteredHubId] = useState(null);
    const[filteredDetails, setFilteredDetails] = useState(null);
    const[itemFilteredDetails, setItemFilteredDetails] = useState(null);
    const[filteredDetailsSize, setFilteredDetailsSize] = useState(null);


    const [drawerOpen, setDrawerOpen] = useState(false);
       const [selectedSlotId, setSelectedSlotId] = useState(null);
   
       const toggleDrawer =
           (open, slotId = null) =>
           () => {
               setDrawerOpen(open);
               if (slotId !== null) {
                   setSelectedSlotId(slotId);
               }
           };
    useEffect( () => {
        if (accessToken && email && itemId && userId) {
            dispatch(getFlows(filteredPackage, itemId, userId, accessToken, includeOldInvoicingCycles, refreshToken, email, service));
        }
    }, [accessToken, filteredPackage, refreshToken, email, itemId, userId, includeOldInvoicingCycles, dispatch, service]);

    useEffect( () => {
        if (packages) {
            const currentPackage = packages.filter((pkg) => {
                return parseInt(pkg.id) === parseInt(filteredPackageIdFromQuery)})[0];
            if (currentPackage) {
                setCurrentPackage(currentPackage);
                setFilteredPackage(filteredPackageIdFromQuery);
                if (!dateRange[0] && !dateRange[1]) {
                    setDateRange([
                        moment(currentPackage.billingCycleStart, 'DD/MM/YYYY'),
                        moment()
                    ]);
                }
            }
        }
    }, [dateRange, packages, filteredPackageIdFromQuery]);

    useEffect( () => {
        if (currentPackage) {
            dispatch(trackViewDetailsFromPackage(itemId, currentPackage, service));
        }
    }, [itemId, currentPackage, service, dispatch]);

    useEffect(() => {
        const allItems = items.concat(notManagedItems);
        if (allItems.length > 0) {
            const filter = allItems.find((item) => (item.key === filteredItemFromQuery || item.fiscalCode === filteredItemFromQuery));
            const itemFiltered = filteredItemFromQuery && filter ? filter : null;
            setFilteredItem(itemFiltered);
        } else {
            setFilteredItem(null);
        }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [filteredItemFromQuery, items, notManagedItems]);

    useEffect( () => {
        (totalSelected && totalSelected > 0 && totalSelected < detailsSize) ||
        (filteredItem && filteredItem.value !== "Tutte") ||
        (filter && (filter.key !== "all" && ((filter.key === "sent" || filter.key === "received" ||
            filter.key === "otpCount" || filter.key === "hasTimestamp" ||
            filter.key === "companyName" || filter.key === "fiscalCode" || filter.key === "vatNumber" || filter.key === "hubId")
            && filter.value !== "") )) ||
        (filteredFlow && filteredFlow.value) ||
        (currentPackage && dateRange[0] && dateRange[1] &&
            (dateRange[0].format('DD/MM/YYYY') !== currentPackage.billingCycleStart ||
                dateRange[1].format('DD/MM/YYYY') !== moment().format('DD/MM/YYYY'))) ?
            setLabelExportButton(<FormattedMessage id="details.button.export.selection" />)
            : setLabelExportButton(<FormattedMessage id="details.button.export.all" />)
    }, [currentPackage, dateRange, filter, filteredFlow, filteredItem, totalSelected, detailsSize]);

    useEffect(() => {
        if (service) {
            setFilters(getFilters(service));
        }
    }, [service]);

    useEffect(() => {
        setItemsWithResetOption([
            {
                key: null,
                value: formatMessage({ id: "details.items.option.all" }),
            },
            ...items.concat(notManagedItems).sort((a,b) => {
                if (a['value'] > b['value']) {
                    return 1;
                } else if (a['value'] < b['value']) {
                    return -1;
                }
                return 0;
            })
        ]);
    }, [items, formatMessage, notManagedItems]);

    useEffect( () => {
        if (filteredDetails && filteredDetails.length > 0) {
            filteredDetails.forEach(fd => {
                const checked = service === "ai" ? checkedItems[fd.slotId] : service !== "checkup" ? checkedItems[fd.hubId] : checkedItems[fd.author];
                fd.selected = (
                    <Checkbox
                        id={service === "ai" ? fd.slotId : service !== "checkup" ? fd.hubId : fd.author}
                        checked={checked}
                        onChange={handleSelectItems} />
                );
                if (service === "signature") {
                    fd.hasTimestamp = fd.hasSignatureTimestamp ? "SI" : "NO";
                    fd.documentTypeTitle = documentTypes.find((docType) => {
                        return docType.code === fd.documentType
                    }) ? documentTypes.find((docType) => {
                        return docType.code === fd.documentType
                    }).title : <FormattedMessage id="details.table.column.documentType.unknown.title" />
                }
                fd.key = service === "ai" ? fd.slotId : service !== "checkup" ? fd.hubId : fd.author;
            })
        }
    }, [filteredDetails, service, checkedItems, totalSelected, documentTypes, handleSelectItems]);

    const handleDateChange = useCallback((dateRange) => {
        setDateRange(dateRange);
        if (dateRange[0] > previousDateRange[0] || dateRange[1] < previousDateRange[1]) {
            const filteredDet = details.filter(d => moment(d.timestamp, 'YYYY-MM-DD') >= dateRange[0]
            && moment(d.timestamp, 'YYYY-MM-DD') <= dateRange[1]);
            setFilteredDetails(filteredDet);
            setFilteredDetailsSize(filteredDet.length);
        } else {
            setFilteredDetails(null);
            setFilteredDetailsSize(null);
        }
        dispatch(trackChangeDateFilterDetails(itemId, filteredItem, service));
    }, [dispatch, details, filteredItem, itemId, service, previousDateRange]);

    const handleViewOldBillingCyclesChange = useCallback((includeOldCycles) => {
        setPreviousDateRange(dateRange);
        if (includeOldInvoicingCycles) {
            setDateRange([
                moment(currentPackage.billingCycleStart, 'DD/MM/YYYY'),
                moment()
            ]);
        }
        setIncludeOldInvoicingCycles(includeOldCycles);
        dispatch(trackViewOldBillingCyclesDetails(itemId, filteredItem, service, includeOldCycles));
    }, // eslint-disable-next-line react-hooks/exhaustive-deps 
    [dispatch, itemId, filteredItem, service, dateRange]);

    const handleDetailsTableSelectAll = useCallback( (checked) => {
        setCheckedAllItems(checked);
        if (checked) {
            const updatedCheckedItems = filteredDetails && filteredDetails.length > 0 ? filteredDetails.reduce((acc, detail) => {
                if (!checkedItems.hasOwnProperty(detail.key)) {
                    acc[detail.key] = true;
                }
                return acc;
            }, {...checkedItems}) : details.reduce((acc, detail) => {
                if (!checkedItems.hasOwnProperty(detail.key)) {
                    acc[detail.key] = true;
                }
                return acc;
            }, {...checkedItems});
            setCheckedItems(updatedCheckedItems);
            setTotalSelected(Object.keys(updatedCheckedItems).length);
        } else {
            if (filteredDetails && filteredDetails.length > 0) {
                filteredDetails.forEach((det) => {
                    if (checkedItems.hasOwnProperty(det.key)) {
                        delete checkedItems[det.key];
                    }
                });
            } else {
                details.forEach((det) => {
                    if (checkedItems.hasOwnProperty(det.key)) {
                        delete checkedItems[det.key];
                    }
                });
            }
            setCheckedItems(checkedItems);
            setTotalSelected(Object.keys(checkedItems).length);
        }
    }, [checkedItems, details, filteredDetails]);

    const handleClickDetailsTableSelectAll = useCallback( (checked) => {
        handleDetailsTableSelectAll(!checked);
    }, [handleDetailsTableSelectAll]);

    const handleFilteredFlowChange = useCallback(
        (option) => {
            let filteredDet = details;
            if (dateRange[0] > previousDateRange[0] || dateRange[1] < previousDateRange[1]) {
                filteredDet = filteredDet.filter(d => moment(d.timestamp, 'YYYY-MM-DD') >= dateRange[0]
                    && moment(d.timestamp, 'YYYY-MM-DD') <= dateRange[1]);
            }
            if (filter) {
                switch (filter.key) {
                    case "sent":
                        filteredDet = filteredDet.filter(d => d.sent === true);
                        break;
                    case "received":
                        filteredDet = filteredDet.filter(d => d.sent !== true);
                        break;
                    default:
                        break;
                }
            }
            if (filteredItem && filteredItem.value !== formatMessage({ id: "details.items.option.all" }) && filteredItem.key) {
                filteredDet = filteredDet.filter(d => d.author === filteredItem.fiscalCode);
            }
            setFilteredFlow(option);
            if (option.key !== "Tutti") {
                filteredDet = filteredDet.filter(d => d.flow === option.key);
            }
            setFilteredDetails(filteredDet);
            setFilteredDetailsSize(filteredDet.length);
        },
        [formatMessage, details, filteredItem, filter, dateRange, previousDateRange]
    );

    const detailsColumns = useMemo(() => {
        const selectedItems = filteredDetails && filteredDetails.length > 0 ?
            filteredDetails.filter((s) => { return s.selected.props.checked })
            : details.filter((s) => { return s.selected.props.checked });
        const selectAllCheckbox = (
            <Checkbox
                id={"all"}
                indeterminate={selectedItems.length !== details.length}
                checked={checkedAllItems || selectedItems.length > 0}
                onChange={handleDetailsTableSelectAll} onClick={handleClickDetailsTableSelectAll}
            />
        );
        const flowSelect = (
            <StyledSelectAutoWidth
                placeholder={"Seleziona"}
                value={filteredFlow}
                options={flows}
                onChange={handleFilteredFlowChange}
                withoutHover={true}
            />
        );
        const companyNameFilterInputText = (
            <Input
                border={0}
                onChange={event => {
                    setFilteredCompanyName(event.target.value);
                    if (event.target.value === "") {
                        setFilter({key: "companyName", "value": event.target.value});
                        setFilteredDetails(null);
                        setFilteredDetailsSize(null);
                    }
                }}
                onKeyPress={event => {
                    if (event.key === "Enter") {
                        setDetailsPage(0);
                        setFilter({key: "companyName", "value": filteredCompanyName});
                        const det = filteredCompanyName && filteredCompanyName.trim().length > 0 ?
                            details.filter(d => d.companyName.toLowerCase() === filteredCompanyName.toLowerCase()) : details;
                        setFilteredDetails(det);
                        setFilteredDetailsSize(det.length);
                    }
                }}
                onClick={(event) => {
                    if (event.target.value !== "") {
                        setDetailsPage(0);
                        setFilter({key: "companyName", "value": filteredCompanyName});
                        const det = filteredCompanyName && filteredCompanyName.trim().length > 0 ?
                            details.filter(d => d.companyName.toLowerCase() === filteredCompanyName.toLowerCase()) : details;
                        setFilteredDetails(det);
                        setFilteredDetailsSize(det.length);
                    }
                }}
                faIcon={faSearch}
                iconColor={"#0090d1"}
                placeholder={"Cerca"}
                value={filteredCompanyName}
            />
        );

        const fiscalCodeFilterInputText = (
            <Input
                border={0}
                onChange={event => {
                    setFilteredFiscalCode(event.target.value);
                    if (event.target.value === "") {
                        setFilter({key: "fiscalCode", "value": event.target.value});
                        setFilteredDetails(null);
                        setFilteredDetailsSize(null);
                    }
                }}
                onKeyPress={event => {
                    if (event.key === "Enter") {
                        setDetailsPage(0);
                        setFilter({key: "fiscalCode", value: filteredFiscalCode});
                        const det = filteredFiscalCode && filteredFiscalCode.trim().length > 0 ?
                            details.filter(d => d.author === filteredFiscalCode) : details;
                        setFilteredDetails(det);
                        setFilteredDetailsSize(det.length);
                    }
                }}
                onClick={(event) => {
                    if (event.target.value !== "") {
                        setDetailsPage(0);
                        setFilter({key: "fiscalCode", value: filteredFiscalCode});
                        const det = filteredFiscalCode && filteredFiscalCode.trim().length > 0 ?
                            details.filter(d => d.author === filteredFiscalCode) : details;
                        setFilteredDetails(det);
                        setFilteredDetailsSize(det.length);
                    }
                }}
                faIcon={faSearch}
                iconColor={"#0090d1"}
                placeholder={"Cerca"}
                value={filteredFiscalCode}
            />
        );

        const vatNumberFilterInputText = (
            <Input
                border={0}
                onChange={event => {
                    setFilteredVatNumber(event.target.value);
                    if (event.target.value === "") {
                        setFilter({key: "vatNumber", "value": event.target.value});
                        setFilteredDetails(null);
                        setFilteredDetailsSize(null);
                    }
                }}
                onKeyPress={event => {
                    if (event.key === "Enter") {
                        setDetailsPage(0);
                        setFilter({key: "vatNumber", value: filteredVatNumber});
                        const det = filteredVatNumber && filteredVatNumber.trim().length > 0 ?
                            details.filter(d => d.vatNumber === filteredVatNumber) : details;
                        setFilteredDetails(det);
                        setFilteredDetailsSize(det.length);
                    }
                }}
                onClick={(event) => {
                    if (event.target.value !== "") {
                        setDetailsPage(0);
                        setFilter({key: "vatNumber", value: filteredVatNumber});
                        const det = filteredVatNumber && filteredVatNumber.trim().length > 0 ?
                            details.filter(d => d.vatNumber === filteredVatNumber) : details;
                        setFilteredDetails(det);
                        setFilteredDetailsSize(det.length);
                    }
                }}
                faIcon={faSearch}
                iconColor={"#0090d1"}
                placeholder={"Cerca"}
                value={filteredVatNumber}
            />
        );

        const hubIdFilterInputText = (
            <Input
                border={0}
                onChange={event => {
                    setFilteredHubId(event.target.value);
                    if (event.target.value === "") {
                        setFilter({key: "hubId", "value": event.target.value});
                        setFilteredDetails(null);
                        setFilteredDetailsSize(null);
                    }
                }}
                onKeyPress={event => {
                    if (event.key === "Enter") {
                        setDetailsPage(0);
                        setFilter({key: "hubId", value: filteredHubId});
                        const det = filteredHubId && filteredHubId.trim().length > 0 ?
                            details.filter(d => d.hubId === filteredHubId) : details;
                        setFilteredDetails(det);
                        setFilteredDetailsSize(det.length);
                    }
                }}
                onClick={(event) => {
                    if (event.target.value !== "") {
                        setDetailsPage(0);
                        setFilter({key: "hubId", value: filteredHubId});
                        const det = filteredHubId && filteredHubId.trim().length > 0 ?
                            details.filter(d => d.hubId === filteredHubId) : details;
                        setFilteredDetails(det);
                        setFilteredDetailsSize(det.length);
                    }
                }}
                faIcon={faSearch}
                iconColor={"#0090d1"}
                placeholder={"Cerca"}
                value={filteredHubId}
            />
        );
        const slotDetails = (slotId) => (
            <Button kind="tertiary" onClick={toggleDrawer(true, slotId)}>
                <FormattedMessage id="details.button.title" />
            </Button>
        );
        return getDetailsTableColumnsSpecification(service, selectAllCheckbox, flowSelect, companyNameFilterInputText,
            fiscalCodeFilterInputText, vatNumberFilterInputText, hubIdFilterInputText, slotDetails)
    }, [service, checkedAllItems, handleDetailsTableSelectAll, details, filteredDetails, filteredFlow, flows,
        handleClickDetailsTableSelectAll, handleFilteredFlowChange, filteredCompanyName, filteredFiscalCode, filteredVatNumber, filteredHubId]);


    const memoizedFilteredItem = useMemo(() => filteredItem, [filteredItem]);
    const memoizedFilteredPackage = useMemo(() => filteredPackage, [filteredPackage]);

    useEffect(() => {
        const shouldFetchDetails =
            service &&
            accessToken &&
            itemUuid && 
            userId &&
            memoizedFilteredPackage 
        if (shouldFetchDetails) {
            dispatch(
                getDetails(
                    itemUuid,
                    userId,
                    accessToken,
                    service,
                    detailsPage,
                    10,
                    dateRange[0],
                    dateRange[1],
                    (memoizedFilteredItem &&
                        memoizedFilteredItem.value !== formatMessage({ id: "details.items.option.all" }) &&
                        memoizedFilteredItem.key ? memoizedFilteredItem.key : null),
                    detailsSorting,
                    includeOldInvoicingCycles,
                    filter.key === "sent",
                    filter.key === "received",
                    filter.key === "otpCount",
                    filter.key === "hasTimestamp",
                    memoizedFilteredPackage,
                    refreshToken,
                    userId,
                    filteredFlow ? filteredFlow.value : null,
                    filter,
                    items.concat(notManagedItems)
                )
            );

            setFilteredDetails(null);
            setFilteredDetailsSize(null);
            if (service === 'ai'){
                setFilter({key: "all"})
            }
            setPreviousDateRange(dateRange);
        }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [
        dateRange,
        accessToken,
        memoizedFilteredItem,
        memoizedFilteredPackage,
        formatMessage,
        dispatch,
        itemUuid,
        service,
        userId,
        refreshToken,
        email
    ]);

    useEffect(() => {
        dispatch(sortDetails(details, detailsSorting, filteredDetails, billingCycleStart, billingCycleEnd, activationDate));
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [detailsSorting, dispatch]);

    const handleExport = useCallback(() => {
        dispatch(downloadLocalDetailsSelectionCsv(
            filteredDetails && filteredDetails.length > 0 ? filteredDetails : details,
            service,
            checkedItems,
            items.concat(notManagedItems),
            documentTypes
            )
        );
        dispatch(trackExportCsvDetails(itemId, filteredItem, service));
    }, [
        dispatch,
        itemId,
        service,
        filteredItem,
        items,
        notManagedItems,
        details,
        filteredDetails,
        checkedItems,
        documentTypes
    ]);

    const handleFilteredItemChange = useCallback(
        (option) => {
            setDetailsPage(0);
            setFilteredItem(option);
            const previousFilteredItem = filteredItem;
            if (option.value !== "Tutte") {
                let filteredDet = details.filter(d => d.author === option.fiscalCode);
                if (filteredDet && filteredDet.length === 0) {
                    setFilteredDetails(null);
                    setFilteredDetailsSize(null);
                    dispatch(
                        getDetails(
                            itemUuid,
                            userId,
                            accessToken,
                            service,
                            detailsPage,
                            10,
                            dateRange[0],
                            dateRange[1],
                            option.key,
                            detailsSorting,
                            includeOldInvoicingCycles,
                            filter.key === "sent",
                            filter.key === "received",
                            filter.key === "otpCount",
                            filter.key === "hasTimestamp",
                            filteredPackage,
                            refreshToken,
                            userId,
                            filteredFlow ? filteredFlow.value : null,
                            filter,
                            items
                        )
                    );
                } else {
                    if (filter) {
                        switch (filter.key) {
                            case "sent":
                                filteredDet = filteredDet.filter(d => d.sent === true);
                                break;
                            case "received":
                                filteredDet = filteredDet.filter(d => d.sent !== true);
                                break;
                            default:
                                break;
                        }
                    }
                    if (filteredFlow && filteredFlow.key !== "Tutti") {
                        filteredDet = filteredDet.filter(d => d.flow === filteredFlow.key);
                    }
                    setFilteredDetails(filteredDet);
                    setFilteredDetailsSize(filteredDet.length);
                    setItemFilteredDetails(filteredDet);
                }
            } else if (previousFilteredItem && previousFilteredItem !== option.value) {
                setFilteredDetails(null);
                setFilteredDetailsSize(null);
                dispatch(
                    getDetails(
                        itemUuid,
                        userId,
                        accessToken,
                        service,
                        detailsPage,
                        10,
                        dateRange[0],
                        dateRange[1],
                        null,
                        detailsSorting,
                        includeOldInvoicingCycles,
                        filter.key === "sent",
                        filter.key === "received",
                        filter.key === "otpCount",
                        filter.key === "hasTimestamp",
                        filteredPackage,
                        refreshToken,
                        userId,
                        filteredFlow ? filteredFlow.value : null,
                        filter,
                        items
                    )
                );
            } else {
                let filteredDet = details;
                if (filter) {
                    switch (filter.key) {
                        case "sent":
                            filteredDet = filteredDet.filter(d => d.sent === true);
                            break;
                        case "received":
                            filteredDet = filteredDet.filter(d => d.sent !== true);
                            break;
                        default:
                            break;
                    }
                }
                if (filteredFlow && filteredFlow.key !== "Tutti") {
                    filteredDet = filteredDet.filter(d => d.flow === filteredFlow.key);
                }
                setFilteredDetails(filteredDet);
                setFilteredDetailsSize(filteredDet.length);
            }
            dispatch(trackFilterDetailsByItem(itemId, option.key, service));
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [itemId, dispatch, service, details, itemFilteredDetails],
    );

    const handleDetailsSorting = useCallback(
        (column) => {
            const oldColumnIndex = detailsColumns.findIndex(
                (columnWithOrdering) => column.key === columnWithOrdering.key,
            );
            const currentColumnSorting = column.sorting;
            let newColumnSorting = "none";
            if (currentColumnSorting === "none") {
                newColumnSorting = "descend";
            } else if (currentColumnSorting === "descend") {
                newColumnSorting = "ascend";
            }
            column.sorting = newColumnSorting;
            detailsColumns[oldColumnIndex] = column;
            setDetailsSorting(`${column.sortingKey}.${newColumnSorting}`);
        },
        [detailsColumns],
    );

    const handleDetailsPageChange = useCallback(
        (page, checkedAllItems) => {
            setDetailsPage(page);
            setCheckedAllItems(checkedAllItems);
        }, []
    );

    useEffect(() => {
        if (service && service.trim().length > 0) {
            if (service.toUpperCase() === "INVOICING") {
                const from = dateRange != null && dateRange.length > 0 && dateRange[0] != null ? dateRange[0].format('YYYY-MM-DD') : null;
                const to = dateRange != null && dateRange.length > 1 && dateRange[1] != null ? dateRange[1].format('YYYY-MM-DD') : null;
                if (from < previousDateRange[0] && to > previousDateRange[1]) {
                    dispatch(getConnectedItems(service, itemId, accessToken, userId, refreshToken, email, from, to));
                }
            }
        }
    }, [accessToken, dispatch, itemId, userId, service, refreshToken, email, dateRange, previousDateRange]);

    return (
        <>
            <Flex flexDirection="column">
                <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    mb="68px"
                >
                    <Flex flexWrap="wrap">
                        {(studio || (statistics && statistics.length > 0)) && (service === "invoicing") && (
                            <Box>
                                <StyledSelect
                                    label={
                                        <FormattedMessage id="details.select.managed.label" />
                                    }
                                    value={filteredItem}
                                    options={itemsWithResetOption.map((item) => {
                                        item.label = item.company;
                                        return item;
                                    })}
                                    onChange={handleFilteredItemChange}
                                />
                            </Box>
                        )}
                    </Flex>
                    <Flex alignItems="space-between">
                        <Flex
                            flexDirection="column"
                            justifyContent="center"
                        >
                                <Box>
                                    <DateFilters
                                        service={service}
                                        includeOldInvoicingCycles={
                                            includeOldInvoicingCycles
                                        }
                                        onIncludeOldInvoicingCyclesChange={
                                            handleViewOldBillingCyclesChange
                                        }
                                        dateRange={dateRange}
                                        minimumDate={currentPackage ?
                                            includeOldInvoicingCycles ?
                                                moment(activationDate, 'DD/MM/YYYY') :
                                                moment(currentPackage.billingCycleStart, 'DD/MM/YYYY')
                                            : includeOldInvoicingCycles ?
                                                moment(activationDate, 'DD/MM/YYYY') :
                                                moment(billingCycleStart, 'DD/MM/YYYY')}
                                        maximumDate={moment()}
                                        onDateRangeChange={handleDateChange}
                                    />
                                </Box>
                        </Flex>
                    </Flex>
                </Flex>
                <Flex
                    alignItems="flex-end"
                    justifyContent="space-between"
                    mb="16px"
                >
                    {service !== "checkup" && service !== "pay" ?
                        (<Flex flexDirection="column">
                            <FilterTextBox mb="16px">
                                <FontAwesomeIcon icon={faFilter} />
                                {"  "}
                                <FormattedMessage id="details.filters.title" />
                            </FilterTextBox>
                            <Flex>
                                {filters.map((f) => (
                                    <Box mr="16px">
                                        <Chip
                                            selected={filter.key === f.key}
                                            onClick={() => {
                                                setFilter(f);
                                                dispatch(resetDetailsFilter(details, billingCycleStart, billingCycleEnd, activationDate));
                                                let filteredDet = details;
                                                if (filteredFlow && filteredFlow.key !== "Tutti") {
                                                    filteredDet = filteredDet.filter(d => d.flow === filteredFlow.key);
                                                }
                                                if (filteredItem && filteredItem.value !== formatMessage({ id: "details.items.option.all" }) && filteredItem.key) {
                                                    filteredDet = filteredDet.filter(d => d.author === filteredItem.fiscalCode);
                                                }
                                                switch (f.key) {
                                                    case "sent":
                                                        const sentDet = filteredDet.filter(d => d.sent === true);
                                                        setFilteredDetails(sentDet);
                                                        setFilteredDetailsSize(sentDet.length);
                                                        break;
                                                    case "received":
                                                        const recDet = filteredDet.filter(d => d.sent !== true);
                                                        setFilteredDetails(recDet);
                                                        setFilteredDetailsSize(recDet.length);
                                                        break;
                                                    case "otpCount":
                                                        const smsDet = details.filter(d => d.otpCount > 0);
                                                        setFilteredDetails(smsDet);
                                                        setFilteredDetailsSize(smsDet.length);
                                                        break;
                                                    case "hasTimestamp":
                                                        const tsDet = details.filter(d => d.hasSignatureTimestamp === true);
                                                        setFilteredDetails(tsDet);
                                                        setFilteredDetailsSize(tsDet.length);
                                                        break;
                                                    case "SOSPESA":
                                                        const blockedDet =
                                                        details.filter((d) =>d.slotStatus === "SOSPESA");
                                                        setFilteredDetails(blockedDet);
                                                        setFilteredDetailsSize(blockedDet.length);
                                                        setDetailsPage(0);
                                                        break;
                                                    case "ATTIVA":
                                                        const usatoDet =
                                                        details.filter((d) =>d.slotStatus ==="ATTIVA" );
                                                        setFilteredDetails(usatoDet);
                                                        setFilteredDetailsSize(usatoDet.length);
                                                        setDetailsPage(0);
                                                        break;
                                                    default:
                                                        setFilteredDetails(itemFilteredDetails && itemFilteredDetails.length > 0
                                                            ? itemFilteredDetails : details);
                                                        setFilteredDetailsSize(itemFilteredDetails && itemFilteredDetails.length > 0
                                                            ? itemFilteredDetails.length : details.length);
                                                        break;
                                                }
                                            }}
                                            label={f.title}
                                        />
                                    </Box>
                                ))}
                            </Flex>
                        </Flex>) : (<Flex flexDirection="column"> </Flex>)}
                    <Flex>
                        <Box>
                            <Button
                                kind="tertiary"
                                faIcon={faFileExport}
                                disabled={filteredDetailsSize === null ? detailsSize === 0 : filteredDetailsSize === 0}
                                onClick={handleExport}
                            >
                                { labelExportButton }
                            </Button>
                        </Box>
                    </Flex>
                </Flex>
                <Flex>
                    <Box width="100%">
                        <Table
                            service={service}
                            columns={detailsColumns}
                            footerTotalsLabel={
                                service === "ai" ? 
                                <FormattedMessage
                                        id="details.table.footer.slots.total"
                                        values={{ total: <b>{filteredDetailsSize === null ? detailsSize : filteredDetailsSize}</b> }}
                                    /> : service !== "checkup" ?
                                    <FormattedMessage
                                        id="details.table.footer.total"
                                        values={{ total: <b>{filteredDetailsSize === null ? detailsSize : filteredDetailsSize}</b> }}
                                    /> :
                                    <FormattedMessage
                                        id="details.table.footer.total.companies"
                                        values={{ total: <b>{filteredDetailsSize === null ? detailsSize : filteredDetailsSize}</b> }}
                                    />
                            }
                            items={detailsFiltered && detailsFiltered.length > 0 ? detailsFiltered.slice(detailsPage * 10, 10 + (detailsPage * 10)) :
                                filteredDetails && filteredDetails.length > 0 ?
                                filteredDetails.slice(detailsPage * 10, 10 + (detailsPage * 10))
                                : details.slice(detailsPage * 10, 10 + (detailsPage * 10))}
                            selectedItems={checkedItems}
                            size={detailsFiltered && detailsFiltered.length > 0 ? detailsFiltered.length :
                                filteredDetails !== null && filteredDetailsSize ? filteredDetailsSize : detailsSize}
                            totalSelected={totalSelected}
                            onSortingChange={handleDetailsSorting}
                        />
                    </Box>
                </Flex>
                {(filteredDetailsSize === null && detailsSize > 0) || (filteredDetailsSize !== null && filteredDetailsSize > 0) ? (
                    <Flex width="100%" justifyContent="flex-end">
                        <Box>
                            <Pagination
                                checkedAllItems={checkedAllItems}
                                page={detailsPage}
                                rowsPerPage={10}
                                size={filteredDetailsSize === null ? detailsSize : filteredDetailsSize}
                                onPageChange={handleDetailsPageChange}
                            />
                        </Box>
                    </Flex>
                ) : null}
            </Flex>
            <UniversalSpinner open={detailsLoading === 1} />
            <DrawerSlotDetails
                drawerOpen={drawerOpen}
                toggleDrawer={toggleDrawer}
                slotDetails={details}
                selectedSlotId={selectedSlotId}
            />
        </>
    );
};
