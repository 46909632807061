import styled from "styled-components";
import { Flex, Box } from "reflexbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const RootFlex = styled(Flex)`
    box-shadow: 0px 2px 8px 0px rgba(21, 41, 53, 0.32);
    width: 560px;
    height: 520px;
`;

export const RootFlexBorderLess = styled(Flex)`
    width: 512px;
    height: 500px;
    padding: 12px;
`;

export const StandAloneRootFlex = styled(Box)`
    box-shadow: 0px 2px 8px 0px rgba(21, 41, 53, 0.32);
    width: 220px;
    height: 220px;  
    margin-right: 23px !important;
    margin-bottom: 23px !important;
`;

export const PackageUsageText = styled(Box)`
    color: rgb(0, 80, 117);
    font-family: Cairo;
    font-size: 24px;
    font-weight: 600;
    width: 360px;
    height: 32px;
    letter-spacing: 0.18px;
    line-height: 32px;
`;

export const InsertionDateText = styled(Box)`
    color: rgb(157, 166, 173);
    font-family: Roboto;
    font-size: 14px;
    font-weight: bold;
    height: 70px;
    letter-spacing: 0.25px;
    line-height: 24px;
    width: 400px;    
    padding-top: 22px;
`;

export const SideBarFlex = styled(Flex)`
    background: rgb(242, 245, 248);
    border-radius: 0px;
    width: 48px;
`;

export const DividerBox = styled(Box)`
    background: rgb(201, 217, 232);
    height: 1px;
    width: 40px;
`;

export const ExpandIconContainerBox = styled(Box)`
    height: 48px;
    width: 48px;
    text-align: center;
    cursor: pointer;
`;

export const ExpandIcon = styled(FontAwesomeIcon)`
    color: rgb(0, 144, 209);
    font-size: 16px;
    height: 48px;
`;

export const ConsumptionDetailsFlex = styled(Flex)`
    border-top: solid 1px rgb(201, 217, 232);
`;

export const ConsumptionDetailsEcobonusFlex = styled(Flex)`
    border-top: solid 1px rgb(201, 217, 232);
    border-bottom: solid 1px rgb(201, 217, 232);
`;

export const LightTextBox = styled(Box)`
    color: rgb(90, 104, 114);
    font-family: Roboto;
    font-size: 14px;
    line-height: 16px;
    overflow: hidden;
    white-space: pre-wrap;
    text-overflow: ellipsis;
`;

export const GreenTextBox = styled(Box)`
    color: rgb(0, 80, 117);
    font-family: Cairo;
    font-size: 12.8px;
    line-height: 19.2px;
    overflow: hidden;
    white-space: pre-wrap;
    text-overflow: ellipsis;
    width: 100%;
`;

export const OtherConsumptionsContainer = styled(Flex)`
    overflow: hidden;
    white-space: pre-wrap;
    text-overflow: ellipsis;
    padding-left: 20px;
    width: 180px;
    height: 144px;
`;

export const ConsumptionsTextBox = styled(Box)`
    color: ${(props) => (props.extra ? "rgb(216, 40, 41)" : "rgb(21, 41, 53)")};
    font-family: Roboto;
    font-size: 20px;
    font-weight: 700;
`;

export const UsageConsumptionsTextBox = styled(Box)`
    font-family: Roboto;
    font-size: 28.8px;
    font-weight: bold;
    width: 100%;
    height: 20px;
`;
export const UsageTextBox = styled.span`
    color: ${(props) => (props.extra ? "rgb(218, 44, 56)" : "rgb(0, 80, 117)")};
    width: 50%;
`;

export const CapacityTextBox = styled.span`
    color: rgb(0, 80, 117);
    width: 50%;
`;

export const UsedTextBox = styled(Box)`
    color: rgb(157, 166, 173);
    font-family: Cairo;
    font-size: 10px;
    font-weight: normal;
    width: 42px;
`;

export const PayCompanyUsedTextBox = styled(Box)`
    color: rgb(157, 166, 173);
    font-family: Cairo;
    font-size: 10px;
    font-weight: normal;
    width: 102px;
`;

export const PieChartBox = styled(Box)`
    position: relative;
    width: 300px;
    height: 180px;
`;

export const PieChartAxes = styled(Box)`
    color: rgb(157, 166, 173);
    font-family: Roboto;
    font-size: 14px;
    font-weight: bold;
    height: 25px;
    letter-spacing: 0.25px;
    line-height: 24px;
    text-align: right;
    width: 18px;
`;

export const AbsoluteUsageDataFlex = styled(Flex)`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
`;

export const PieUsageText = styled(Box)`
    color: rgb(0, 80, 117);
    font-family: Cairo;
    font-weight: bold;
    font-size: 36px;
    line-height: 48px;
`;

export const PieUsageHelperText = styled(Box)`
    color: rgb(0, 80, 117);
    font-family: Cairo;
    font-weight: 600;
    font-size: 24px;
    line-height: 20px;
`;

export const PieUsageHelperTextSmall = styled(Box)`
    color: rgb(157, 166, 173);
    font-family: Roboto;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.25px;
`;

export const ResumeBoxTitle = styled(Box)`
    width: 422px;
    height: 24px;
    color: rgb(0, 80, 117);
    font-size: 14px;
    font-family: Cairo;
    font-weight: bold;
    line-height: 24px;
`;

export const ResumeBoxText = styled(Box)`
    width: 422px;
    height: 100px;
    color: rgb(0, 80, 117);
    font-size: 14px;
    font-family: Cairo;
    line-height: 24px;
`;

export const UnorderedListStyled = styled.ul`
    display: block;
    list-style-type: disc;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
`;


export const Drawer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 300px;
  background: linear-gradient(135deg, #ffffff, #f0f0f0);
  box-shadow: -4px 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px 0 0 8px;
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(100%)')};
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  z-index: 1000;
  padding: 20px;
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: ${({ open }) => (open ? 'block' : 'none')};
  transition: background-color 0.3s ease-in-out;
  z-index: 999;
`;

export const DrawerContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const Header = styled.h2`
  margin-bottom: 20px;
  font-size: 1.5em;
  color: #333;
  text-align: center;
`;

export const Row = styled.div`
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DataLabel = styled.span`
  font-weight: bold;
  color: #555;
`;

export const DataValue = styled.span`
  color: #777;
`;
