import React from "react";
import PropTypes from "prop-types";
import { Flex, Box } from "reflexbox";
import { Switch } from "../switch";
import { FormattedMessage } from "react-intl";
import { RangeDatePicker } from "../range-picker";

export const DateFilters = ({
    service,
    includeOldInvoicingCycles,
    onIncludeOldInvoicingCyclesChange,
    dateRange,
    minimumDate,
    maximumDate,
    onDateRangeChange,
}) => {
    return (
        <Flex alignItems="center">
            {service !== "ai" && (
                <Box alignSelf="flex-end" mr={16}>
                    <Switch
                        label={
                            <FormattedMessage id="details.header.old.billing.cycles.switch.label" />
                        }
                        value={includeOldInvoicingCycles}
                        onChange={onIncludeOldInvoicingCyclesChange}
                    />
                </Box>
            )}
            <Box>
                <RangeDatePicker
                    maximumDate={maximumDate}
                    minimumDate={minimumDate}
                    value={dateRange}
                    onChange={onDateRangeChange}
                    fromInputLabel={
                        <FormattedMessage id="details.header.range.picker.from.label" />
                    }
                    toInputLabel={
                        <FormattedMessage id="details.header.range.picker.to.label" />
                    }
                />
            </Box>
        </Flex>
    );
};

DateFilters.propTypes = {
    includeOldInvoicingCycles: PropTypes.bool,
    onIncludeOldInvoicingCyclesChange: PropTypes.func.isRequired,
    dateRange: PropTypes.array.isRequired,
    onDateRangeChange: PropTypes.func.isRequired,
};
