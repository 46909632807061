import { API_URL } from "../../env";
import { getRestCall } from "../../utils/rest-api-call";
import { getSanitizedQueryString } from "../../utils";

export const GET_FILES_METADATA_START = "GET_FILES_METADATA_START";
export const GET_FILES_METADATA_END = "GET_FILES_METADATA_END";
export const GET_FILES_METADATA_SUCCESS = "GET_FILES_METADATA_SUCCESS";

export const getFilesMetadata = (
    item,
    userId,
    accessToken,
    service,
    page,
    itemsPerPage,
    from,
    to,
    online,
    refreshToken,
    email
) => async (dispatch) => {
    dispatch({ type: GET_FILES_METADATA_START });
    try {
        const sanitizedQueryString = getSanitizedQueryString({
            online,
            page,
            itemsPerPage
        });
        const response = await getRestCall(
            `${API_URL}/api/v1/history/services/${service}/items/${item}/files?${sanitizedQueryString}`,
            accessToken,
            null,
            dispatch,
            userId,
            item,
            refreshToken,
            email,
            service.toUpperCase() === "INVOICING" ? "EIP" : "SIG"
        );
        const { fileMetadataItemList, size, numberOfPages } = response;
        dispatch({
            type: GET_FILES_METADATA_SUCCESS,
            fileMetadataItemList,
            size,
            numberOfPages
        });
    } catch (error) {
        console.error("there was an error fetching files metadata", error);
    } finally {
        dispatch({ type: GET_FILES_METADATA_END });
    }
};
