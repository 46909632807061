import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Flex, Box } from "reflexbox";
import { FormattedMessage, useIntl } from "react-intl";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import { PieChart, Pie, Cell } from "recharts";
import { Button } from "../../button";
import { useState } from "react";
import {
    RootFlex,
    PackageUsageText,
    InsertionDateText,
    ConsumptionDetailsFlex,
    GreenTextBox,
    UsageConsumptionsTextBox,
    SideBarFlex,
    DividerBox,
    ExpandIconContainerBox,
    ExpandIcon,
    PieChartBox,
    AbsoluteUsageDataFlex,
    PieUsageText,
    PieUsageHelperText,
    UsedTextBox,
    OtherConsumptionsContainer, PieChartAxes, UsageTextBox, CapacityTextBox
} from "../styled";
import { useCallback } from "react";
import { LegendItem, ExlamationCircleIcon, CustomTooltip, TitleTooltip, TextTooltip } from "../../legend-item";
import { Tooltip } from "../../tooltip/index";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

const TOTAL_USAGE_COLOR = "#0d6b7f";
const TOTAL_TMP_USAGE_COLOR = "#4763ae";
const EXTRA_USAGE_COLOR = "#da2c38";
const REMAINING_USAGE_COLOR = "#9fc3cd";
const REMAINING_TMP_USAGE_COLOR = "#a0b3e5";
const COLORS = ['#ffffff', EXTRA_USAGE_COLOR];

export const SignaturePackageCard = ({
    id,
    timestamp,
    capacity,
    constraint,
    family,
    familyReplacement,
    regularUsage,
    extraUsage,
    MASSIVE_SIGNATURES,
    QUALIFIED_SIGNATURES,
    SMS,
    onUpgrade,
    onExpand,
    billingCycleStart,
    billingCycleEnd
}) => {
    const { formatMessage } = useIntl();
    const [pieData, setPieData] = useState([]);
    const [pieExtraData, setPieExtraData] = useState([]);
    const [isUpgradeButtonVisible] = useState(false);
    useEffect(() => {
        const occupiedValue = regularUsage;
        const rawRemainingValue = capacity - occupiedValue;
        setPieData([
            {
                id: formatMessage({ id: "pie.slice.usage.total" }),
                value: regularUsage,
                fill: constraint && constraint === 'TIMESTAMPS' ? TOTAL_TMP_USAGE_COLOR : TOTAL_USAGE_COLOR,
            },
            {
                id: formatMessage({ id: "pie.slice.usage.remaining" }),
                value: rawRemainingValue > 0 ? rawRemainingValue : 0,
                fill: constraint && constraint === 'TIMESTAMPS' ? REMAINING_TMP_USAGE_COLOR : REMAINING_USAGE_COLOR,
            },
        ]);
        setPieExtraData([
            {
                id: formatMessage({ id: "pie.slice.usage.extra" }),
                value: extraUsage,
                fill: EXTRA_USAGE_COLOR,    
            },
        ]);
    }, [extraUsage, regularUsage, capacity, formatMessage, constraint]);
    
    const handleUpgrade = useCallback(() => {
        onUpgrade(id);
    }, [id, onUpgrade]);

    const handleExpand = useCallback(() => {
        onExpand(id);
    }, [id, onExpand]);

    return (
        <RootFlex>
            <Flex
                flexDirection="column"
                alignItems="center"
                width="100%">
                <Flex
                    flexWrap='wrap'
                    ml="24px"
                    mt="24px"
                    justifyContent="space-between"
                >
                    <PackageUsageText>
                        <FormattedMessage
                            id="package.card.title"
                            values={{ name: familyReplacement ? familyReplacement : family }}
                        />
                    </PackageUsageText>
                    <Box>
                        {
                            isUpgradeButtonVisible &&
                            <Button
                                kind="primary"
                                size="small"
                                onClick={handleUpgrade}
                            >
                                <FormattedMessage id="package.card.upgrade" />
                            </Button>
                        }
                    </Box>
                    <InsertionDateText>
                        <Box>
                            <FormattedMessage
                                id="package.card.invoicing.period"
                            />
                        </Box>
                        <Box>
                            <FormattedMessage
                                id="package.card.date.insertion.start"
                                values={{
                                    date: billingCycleStart,
                                }}
                            />
                        </Box>
                        <Box>
                            <FormattedMessage
                                id="package.card.date.insertion.end"
                                values={{
                                    date: billingCycleEnd,
                                }}
                            />
                        </Box>
                    </InsertionDateText>
                </Flex>
                <Flex>
                    <PieChartAxes mr="10px" mt="150px">{0}</PieChartAxes>
                    <PieChartBox mb="10px">
                        <PieChart width={300} height={180}>
                            <Pie
                                data={pieData}
                                cx={145}
                                cy={170}
                                startAngle={180}
                                endAngle={extraUsage > 0 ? (180 * extraUsage)/capacity : 0}
                                innerRadius={110}
                                outerRadius={130}
                                paddingAngle={1}
                            >
                                {
                                    extraUsage > 0 ?
                                        pieData.map((entry, index) =>
                                            <Cell key={`cell-${index}`} stroke={COLORS[index % COLORS.length]} />) : null
                                }
                            </Pie>
                            {extraUsage > 0 ? (
                                <Pie
                                    data={pieExtraData}
                                    cx={145}
                                    cy={170}
                                    startAngle={0}
                                    endAngle={(180 * extraUsage)/capacity}
                                    innerRadius={110}
                                    outerRadius={140}
                                    paddingAngle={1}
                                >
                                    {
                                        pieExtraData.map((entry, index) =>
                                            <Cell key={`cell-${index}`} stroke="#ffffff" />)
                                    }
                                </Pie>
                            ) : null}
                        </PieChart>
                        <AbsoluteUsageDataFlex
                            flexDirection="column"
                            justifyContent="flex-end"
                            alignItems="center"
                        >
                            <PieUsageText>{regularUsage + extraUsage}</PieUsageText>
                            <PieUsageHelperText>
                                {
                                    constraint && constraint === 'TIMESTAMPS' ?
                                        (<FormattedMessage id="package.card.pie.timestamps" />) :
                                        (<FormattedMessage id="package.card.pie.documents" />)
                                }
                            </PieUsageHelperText>
                        </AbsoluteUsageDataFlex>
                    </PieChartBox>
                    <PieChartAxes mt="150px">{capacity}</PieChartAxes>
                </Flex>
                <Flex marginLeft="25px" justifyContent="center" mb="24px" mt="10px">
                    <LegendItem
                        color={constraint && constraint === 'TIMESTAMPS' ? TOTAL_TMP_USAGE_COLOR : TOTAL_USAGE_COLOR}
                        message={<FormattedMessage id="package.card.pie.legend.usage"/>}
                        quantity={regularUsage}
                        percentage={(
                            ((regularUsage) /
                                capacity) *
                            100
                            ).toFixed(2)
                        }
                        showQuantity={true}
                    />
                    <LegendItem
                        color={constraint && constraint === 'TIMESTAMPS' ? REMAINING_TMP_USAGE_COLOR : REMAINING_USAGE_COLOR}
                        message={
                            <FormattedMessage
                                id="package.card.pie.legend.remaining"
                            />}
                        quantity={(capacity - regularUsage).toFixed(2)}
                        percentage={(
                                ((capacity - regularUsage) /
                                    capacity) *
                                100
                                ).toFixed(2)
                            }
                        showQuantity={true}
                    />
                    {
                        extraUsage > 0 ? (
                            <LegendItem
                                color={EXTRA_USAGE_COLOR}
                                message={
                                    <FormattedMessage
                                        id="package.card.pie.legend.extra"
                                    />
                                }
                                quantity={extraUsage}
                                percentage={(
                                    ((extraUsage) /
                                        capacity) *
                                    100
                                ).toFixed(2)
                                }
                                showQuantity={true}
                            />
                        ) : null
                    }
                </Flex>
                <ConsumptionDetailsFlex
                    width="100%"
                    flex="1"
                    flexDirection="column"
                    justifyContent="center"
                >
                    
                    { constraint && constraint === 'TIMESTAMPS' ?
                        (
                            <Flex width="100%">
                                {/*<Flex
                                    pr="24px"
                                    width="100%"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <LightTextBox pr="16px">
                                        <FormattedMessage id="package.card.total.timestamps" />
                                    </LightTextBox>
                                    <ConsumptionsTextBox>
                                        {regularUsage}
                                    </ConsumptionsTextBox>
                                </Flex>
                                <Flex
                                    pl="24px"
                                    width="100%"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <LightTextBox pr="16px">
                                        <FormattedMessage id="package.card.total.timestamps.extra" /> 
                                    </LightTextBox>
                                    <ConsumptionsTextBox extra>
                                        {extraUsage}
                                    </ConsumptionsTextBox>
                                </Flex>*/}
                            </Flex>
                        ) : 
                            (
                                <Flex height="144px">
                                    { QUALIFIED_SIGNATURES ? (
                                            <OtherConsumptionsContainer
                                                pr="24px"
                                                justifyContent="space-between"
                                                flexWrap='wrap'
                                                mt="10px"
                                            >
                                                <GreenTextBox pr="16px">
                                                    <FormattedMessage id="package.card.total.feqremote" />
                                                    {
                                                        QUALIFIED_SIGNATURES.extraUsage > 0 ?
                                                            (
                                                                <Box ml="50px" mt="-25px">
                                                                    <ExlamationCircleIcon icon={faExclamationCircle} data-tip data-for="extraUsageQualifiedSignatureTip" />
                                                                    <CustomTooltip
                                                                        backgroundColor="rgb(0, 80, 117)"
                                                                        className="custom-tooltip"
                                                                        id="extraUsageQualifiedSignatureTip"
                                                                        place="bottom"
                                                                        effect="solid">
                                                                        <TitleTooltip><FormattedMessage id="extrausage.tooltip.title" /></TitleTooltip>
                                                                        <p>
                                                                            <TextTooltip>
                                                                                <FormattedMessage
                                                                                    id="extrausage.signature.tooltip.text"
                                                                                    values={{ number: QUALIFIED_SIGNATURES.extraUsage}}
                                                                                />
                                                                            </TextTooltip>
                                                                        </p>
                                                                    </CustomTooltip>
                                                                </Box>
                                                            ) : null
                                                    }
                                                </GreenTextBox>
                                                <UsageConsumptionsTextBox>
                                                    <UsageTextBox extra={QUALIFIED_SIGNATURES.extraUsage > 0}>{QUALIFIED_SIGNATURES.regularUsage + QUALIFIED_SIGNATURES.extraUsage}</UsageTextBox>
                                                    <CapacityTextBox>{"/" + QUALIFIED_SIGNATURES.capacity}</CapacityTextBox>
                                                </UsageConsumptionsTextBox>
                                                <UsedTextBox>
                                                    <FormattedMessage id="package.card.total.used" />
                                                </UsedTextBox>                                                
                                            </OtherConsumptionsContainer>
                                        ) : null
                                    }
                                        
                                        { MASSIVE_SIGNATURES ? (
                                            <OtherConsumptionsContainer
                                                pr="24px"
                                                justifyContent="space-between"
                                                flexWrap='wrap'
                                                mt="10px"
                                            >
                                                <GreenTextBox pr="16px">
                                                    <FormattedMessage id="package.card.total.feqauto" />
                                                    {
                                                        MASSIVE_SIGNATURES.extraUsage > 0 ?
                                                            (
                                                                <Box ml="70px" mt="-25px">
                                                                    <ExlamationCircleIcon icon={faExclamationCircle} data-tip data-for="extraUsageAutomaticSignatureTip" />
                                                                    <CustomTooltip
                                                                        backgroundColor="rgb(0, 80, 117)"
                                                                        className="custom-tooltip"
                                                                        id="extraUsageAutomaticSignatureTip"
                                                                        place="bottom"
                                                                        effect="solid">
                                                                        <TitleTooltip><FormattedMessage id="extrausage.tooltip.title" /></TitleTooltip>
                                                                        <p>
                                                                            <TextTooltip>
                                                                                <FormattedMessage
                                                                                    id="extrausage.signature.tooltip.text"
                                                                                    values={{ number: MASSIVE_SIGNATURES.extraUsage}}
                                                                                />
                                                                            </TextTooltip>
                                                                        </p>
                                                                    </CustomTooltip>
                                                                </Box>
                                                            ) : null
                                                    }
                                                </GreenTextBox>
                                                <UsageConsumptionsTextBox>
                                                    <UsageTextBox extra={MASSIVE_SIGNATURES.extraUsage > 0}>{MASSIVE_SIGNATURES.regularUsage + MASSIVE_SIGNATURES.extraUsage}</UsageTextBox>
                                                    <CapacityTextBox>{"/" + MASSIVE_SIGNATURES.capacity}</CapacityTextBox>
                                                </UsageConsumptionsTextBox>
                                                <UsedTextBox>
                                                    <FormattedMessage id="package.card.total.used" />
                                                </UsedTextBox>                                                
                                            </OtherConsumptionsContainer>
                                        ) : null
                                    }
                                        
                                    { SMS ? (
                                            <OtherConsumptionsContainer
                                                pr="24px"
                                                justifyContent="space-between"
                                                flexWrap='wrap'
                                                mt="10px"
                                            >
                                                <GreenTextBox pr="16px">
                                                    <Box height={"40px"}>
                                                        <FormattedMessage id="package.card.total.sms" />
                                                    </Box>
                                                    {
                                                        SMS.extraUsage > 0 ?
                                                            (
                                                                <Box ml="30px" mt="-25px">
                                                                    <ExlamationCircleIcon icon={faExclamationCircle} data-tip data-for="extraUsageSMSTip" />
                                                                    <CustomTooltip
                                                                        backgroundColor="rgb(0, 80, 117)"
                                                                        className="custom-tooltip"
                                                                        id="extraUsageSMSTip"
                                                                        place="bottom"
                                                                        effect="solid">
                                                                        <TitleTooltip><FormattedMessage id="extrausage.tooltip.title" /></TitleTooltip>
                                                                        <p>
                                                                            <TextTooltip>
                                                                                <FormattedMessage
                                                                                    id="extrausage.signature.tooltip.text"
                                                                                    values={{ number: SMS.extraUsage}}
                                                                                />
                                                                            </TextTooltip>
                                                                        </p>
                                                                    </CustomTooltip>
                                                                </Box>
                                                            ) : null
                                                    }
                                                </GreenTextBox>    
                                                <UsageConsumptionsTextBox>
                                                    <UsageTextBox extra={SMS.extraUsage > 0}>{SMS.regularUsage + SMS.extraUsage}</UsageTextBox>
                                                    <CapacityTextBox>{"/" + SMS.capacity}</CapacityTextBox>
                                                </UsageConsumptionsTextBox>
                                                <UsedTextBox>
                                                    <FormattedMessage id="package.card.total.used" />
                                                </UsedTextBox>
                                            </OtherConsumptionsContainer>
                                        ) : null
                                    }
                                    
                                </Flex>
                            )
                        }
                        

                    
                </ConsumptionDetailsFlex>
            </Flex>
            <SideBarFlex
                flexDirection="column"
                justifyContent="flex-end"
                alignItems="center"
            >
                <DividerBox />
                <ExpandIconContainerBox onClick={handleExpand}>
                    <Tooltip label={<FormattedMessage id="package.card.view.detail.tooltip" />} width="70px">
                        <ExpandIcon icon={faArrowRight} width="14px"/>
                    </Tooltip>    
                </ExpandIconContainerBox>
            </SideBarFlex>
        </RootFlex>
    );
};

SignaturePackageCard.propTypes = {
    id: PropTypes.number.isRequired,
    timestamp: PropTypes.string.isRequired,
    capacity: PropTypes.number.isRequired,
    regularUsage: PropTypes.number.isRequired,
    extraUsage: PropTypes.number.isRequired,
    onUpgrade: PropTypes.func.isRequired,
    onExpand: PropTypes.func.isRequired,
};
