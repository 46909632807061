import React from "react";
import PropTypes from "prop-types";
import { typographyVariants } from "./styled";

const Typography = ({ children, variant }) => {
    const Component = typographyVariants[variant];
    return <Component>{children}</Component>;
};

export const TypographyVariants = Object.freeze({
    H1_LEAD: "h1Lead",
    H2_LEAD: "h2Lead",
    H3_LEAD: "h3Lead",
    H1: "h1",
    H2: "h2",
    H3: "h3",
    H4: "h4",
    H5: "h5",
    SUBTITLE_LARGE: "subtitleLarge",
    SUBTITLE_REGULAR: "subtitleRegular",
    BODY_LARGE: "bodyLarge",
    BODY_REGULAR: "bodyRegular",
    BUTTONS_AND_CTAS: "buttonsAndCtas",
    DESCRIPTIVE: "descriptive",
    DESCRIPTIVE_TAG: "descriptiveTag",
    EYEBROW: "eyebrow"
});

Typography.propTypes = {
    children: PropTypes.node,
    variant: PropTypes.oneOf(Object.values(TypographyVariants))
};

Typography.defaultProps = {
    variant: TypographyVariants.BODY_REGULAR
};

export default Typography;
