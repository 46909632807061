import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { Flex, Box } from "reflexbox";
import { FormattedMessage, useIntl } from "react-intl";
import { faArrowRight, faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import { PieChart, Pie } from "recharts";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LegendItem } from "../../legend-item";
import { Tooltip } from "../../tooltip/index";
import {
    RootFlex,
    PackageUsageText,
    InsertionDateText,
    ConsumptionDetailsFlex,
    SideBarFlex,
    DividerBox,
    ExpandIconContainerBox,
    ExpandIcon,
    PieChartBox,
    AbsoluteUsageDataFlex,
    PieUsageText,
    PieUsageHelperText,
    PieChartAxes,
} from "../styled";

const TOTAL_USAGE_COLOR = "#0d6b7f";
const REMAINING_USAGE_COLOR = "#9fc3cd";
const BLOCKED_USAGE_COLOR = "#d6a8a3";

const FamilyTooltip = styled.ul`
    position: absolute;
    background: white;
    border: 1px solid #ccc;
    padding: 10px;
    list-style: none;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 10;
    font-size: 12px;
    width: 280px;
`;

const FamilyTooltipItem = styled.li`
    ::before {
        content: "• ";
        color: black;
    }
`;

export const AiPackageCard = ({
    id,
    family,
    onExpand,
    billingCycleStart,
    billingCycleEnd,
    AI_COMPANIES,
    aiWhiteList,
    aiFamilies,
}) => {
    const blocked = AI_COMPANIES.coolDownUsage;
    const regularUsage = AI_COMPANIES.regularUsage;
    const capacity = AI_COMPANIES.capacity;
    const extraUsage = 0;

    const { formatMessage } = useIntl();
    const [pieData, setPieData] = useState([]);
    const [isTooltipVisible, setIsTooltipVisible] = useState(false);
    const [tooltipItems, setTooltipItems] = useState([]);

    useEffect(() => {
        const occupiedValue = regularUsage;
        const rawRemainingValue = capacity - occupiedValue;
        setPieData([
            {
                id: formatMessage({ id: "pie.slice.usage.total" }),
                value: regularUsage,
                fill: TOTAL_USAGE_COLOR,
            },
            {
                id: formatMessage({ id: "pie.slice.usage.bloccati" }),
                value: blocked,
                fill: BLOCKED_USAGE_COLOR,
            },
            {
                id: formatMessage({ id: "pie.slice.usage.remaining" }),
                value: rawRemainingValue > 0 ? rawRemainingValue : 0,
                fill: REMAINING_USAGE_COLOR,
            },
        ]);

        const occurrences = {};
        const items = [];

        aiFamilies.forEach((item) => {
            occurrences[item] = (occurrences[item] || 0) + 1;
        });

        for (const [item, count] of Object.entries(occurrences)) {
            if (count > 1) {
                items.push({ item, count });
            } else {
                items.push({ item, count: 0 });
            }
        }

        setTooltipItems(items);
    }, [regularUsage, blocked, capacity, formatMessage, family, aiFamilies]);

    const handleExpand = useCallback(() => {
        onExpand(id);
    }, [id, onExpand]);

    const handleMouseEnter = () => {
        setIsTooltipVisible(true);
    };

    const handleMouseLeave = () => {
        setIsTooltipVisible(false);
    };

    return (
        <RootFlex>
            <Flex flexDirection="column" alignItems="center" width="100%">
                <Flex
                    flexWrap="wrap"
                    ml="24px"
                    mt="24px"
                    justifyContent="space-between"
                >
                    <PackageUsageText>
                        <FormattedMessage id="package.card.ai.title" />
                        <Box
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                            style={{
                                display: "inline-block",
                                position: "relative",
                            }}
                        >
                            <FontAwesomeIcon
                                icon={faInfoCircle}
                                size="sm"
                                style={{
                                    marginLeft: "4px",
                                    color: TOTAL_USAGE_COLOR,
                                }}
                            />
                            {isTooltipVisible && (
                                <FamilyTooltip>
                                    {tooltipItems.map((item, index) => (
                                        <FamilyTooltipItem key={index}>
                                            {item.item}
                                            {item.count > 1 &&
                                                `(N.${item.count})`}
                                        </FamilyTooltipItem>
                                    ))}
                                </FamilyTooltip>
                            )}
                        </Box>
                    </PackageUsageText>
                    {aiWhiteList && (
                        <FormattedMessage id="package.card.ai.white.list" />
                    )}
                    <Box mr="36px" width="94px"></Box>
                    <InsertionDateText mt="10px">
                        <Box>
                            <FormattedMessage id="package.card.invoicing.period" />
                        </Box>
                        <Box>
                            <FormattedMessage
                                id="package.card.date.insertion.start"
                                values={{
                                    date: billingCycleStart,
                                }}
                            />
                        </Box>
                        <Box>
                            <FormattedMessage
                                id="package.card.date.insertion.end"
                                values={{
                                    date: billingCycleEnd,
                                }}
                            />
                        </Box>
                    </InsertionDateText>
                </Flex>

                <Flex>
                    {capacity !== -1 ? (
                        <>
                            <PieChartAxes mr="10px" mt="150px">
                                {0}
                            </PieChartAxes>
                            <PieChartBox>
                                <PieChart width={300} height={180}>
                                    <Pie
                                        data={pieData}
                                        cx={145}
                                        cy={170}
                                        startAngle={180}
                                        endAngle={
                                            extraUsage > 0
                                                ? (180 * extraUsage) / capacity
                                                : 0
                                        }
                                        innerRadius={110}
                                        outerRadius={130}
                                        paddingAngle={1}
                                    ></Pie>
                                </PieChart>
                                <AbsoluteUsageDataFlex
                                    flexDirection="column"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <PieUsageText>
                                        {regularUsage + blocked}
                                    </PieUsageText>
                                    {regularUsage > 0 && blocked > 0 && (
                                        <PieUsageHelperText>
                                            <FormattedMessage id="package.card.pie.attive.sospese" />
                                        </PieUsageHelperText>
                                    )}
                                    {regularUsage > 0 && blocked === 0 && (
                                        <PieUsageHelperText>
                                            <FormattedMessage id="package.card.pie.attive" />
                                        </PieUsageHelperText>
                                    )}
                                    {blocked > 0 && regularUsage === 0 && (
                                        <PieUsageHelperText>
                                            <FormattedMessage id="package.card.pie.sospeso" />
                                        </PieUsageHelperText>
                                    )}
                                </AbsoluteUsageDataFlex>
                            </PieChartBox>
                            <PieChartAxes mt="150px">{capacity}</PieChartAxes>
                        </>
                    ) : (
                        <>
                            <PieChartAxes mr="10px" mt="150px">
                                {0}
                            </PieChartAxes>
                            <PieChartBox>
                                <PieChart width={300} height={180}>
                                    <Pie
                                        data={[{ name: "Unlimited", value: 1 }]}
                                        cx={145}
                                        cy={170}
                                        startAngle={0}
                                        endAngle={360}
                                        innerRadius={110}
                                        outerRadius={130}
                                        fill="#00C49F"
                                    />
                                    <Pie
                                        data={[{ name: "Empty", value: 1 }]}
                                        cx={145}
                                        cy={170}
                                        startAngle={0}
                                        endAngle={360}
                                        innerRadius={100}
                                        outerRadius={110}
                                        fill="#FFF"
                                    />
                                </PieChart>
                                <AbsoluteUsageDataFlex
                                    flexDirection="column"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <PieUsageText>
                                        {regularUsage + blocked}
                                    </PieUsageText>
                                    {regularUsage > 0 && (
                                        <PieUsageHelperText>
                                            <FormattedMessage id="package.card.pie.attive.sospese" />
                                        </PieUsageHelperText>
                                    )}
                                </AbsoluteUsageDataFlex>
                            </PieChartBox>
                            <PieChartAxes mt="150px">
                                <FormattedMessage id="package.card.pie.unlimited" />
                            </PieChartAxes>
                        </>
                    )}
                </Flex>
                <Flex
                    marginLeft="25px"
                    justifyContent="center"
                    mb="24px"
                    mt="24px"
                    width="100%"
                >
                    {capacity !== -1 ? (
                        <ConsumptionDetailsFlex
                            width="100%"
                            display="flex"
                            flexDirection="column"
                        >
                            <FormattedMessage id="package.card.total.slot.status.types" />
                            <div
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    paddingLeft: "10px",
                                    paddingTop: "10px",
                                }}
                            >
                                <LegendItem
                                    color={TOTAL_USAGE_COLOR}
                                    message={
                                        <FormattedMessage id="package.card.pie.attive" />
                                    }
                                    quantity={regularUsage}
                                    percentage={(
                                        (regularUsage / capacity) *
                                        100
                                    ).toFixed(2)}
                                    showQuantity={true}
                                />

                                <LegendItem
                                    color={BLOCKED_USAGE_COLOR}
                                    message={
                                        <FormattedMessage id="package.card.pie.sospese" />
                                    }
                                    quantity={blocked}
                                    showQuantity={true}
                                />

                                <LegendItem
                                    color={REMAINING_USAGE_COLOR}
                                    message={
                                        <FormattedMessage id="package.card.pie.disponibili" />
                                    }
                                    quantity={(
                                        capacity -
                                        (regularUsage + blocked)
                                    ).toFixed(2)}
                                    percentage={(
                                        ((capacity - regularUsage + blocked) /
                                            capacity) *
                                        100
                                    ).toFixed(2)}
                                    showQuantity={true}
                                />
                            </div>
                        </ConsumptionDetailsFlex>
                    ) : (
                        <>
                            <ConsumptionDetailsFlex
                                width="100%"
                                display="flex"
                                flexDirection="column"
                            >
                                <FormattedMessage id="package.card.total.slot.status.types" />
                                <div
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        paddingLeft: "20px",
                                        paddingTop: "10px",
                                    }}
                                >
                                    <LegendItem
                                        color={TOTAL_USAGE_COLOR}
                                        message={
                                            <FormattedMessage id="package.card.pie.attive" />
                                        }
                                        quantity={regularUsage}
                                        percentage={(
                                            (regularUsage / capacity) *
                                            100
                                        ).toFixed(2)}
                                        showQuantity={true}
                                    />

                                    <LegendItem
                                        color={BLOCKED_USAGE_COLOR}
                                        message={
                                            <FormattedMessage id="package.card.pie.sospese" />
                                        }
                                        quantity={blocked}
                                        showQuantity={true}
                                    />
                                </div>
                            </ConsumptionDetailsFlex>
                        </>
                    )}
                </Flex>
            </Flex>
            <SideBarFlex
                flexDirection="column"
                justifyContent="flex-end"
                alignItems="center"
            >
                <DividerBox />
                <ExpandIconContainerBox onClick={handleExpand}>
                    <Tooltip
                        label={
                            <FormattedMessage id="package.card.view.detail.tooltip" />
                        }
                        position="top"
                        width="70px"
                    >
                        <ExpandIcon icon={faArrowRight} width="14px" />
                    </Tooltip>
                </ExpandIconContainerBox>
            </SideBarFlex>
        </RootFlex>
    );
};

AiPackageCard.propTypes = {
    id: PropTypes.number.isRequired,
    timestamp: PropTypes.string.isRequired,
    capacity: PropTypes.number.isRequired,
    regularUsage: PropTypes.number.isRequired,
    onUpgrade: PropTypes.func.isRequired,
    onExpand: PropTypes.func.isRequired,
};
