import {
    GET_PERMISSIONS_START,
    GET_PERMISSIONS_END,
    GET_PERMISSIONS_SUCCESS,
} from "../../actions/permissions";

const initialState = {
    loadings: 0,
    data: {},
};

export const permissionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PERMISSIONS_START: {
            return {
                ...state,
                loadings: state.loadings + 1,
            };
        }
        case GET_PERMISSIONS_END: {
            return {
                ...state,
                loadings: state.loadings > 0 ? state.loadings - 1 : 0,
            };
        }
        case GET_PERMISSIONS_SUCCESS: {
            return {
                ...state,
                data: action.permissions,
            };
        }
        default: {
            return state;
        }
    }
};
