import { Box, Flex } from "reflexbox";
import React from "react";
import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";

const LegendSquare = styled(Box)`
    width: 12px;
    height: 12px;
    border-radius: 2px;
    background: ${(props) => props.color};
`;

const LegendText = styled(Box)`
    color: rgb(21, 41, 53);
    font-family: Roboto;
    font-size: 12px;
    line-height: 14px;
    width: 120px;
    height: 24px;
    letter-spacing: 0.25px;
    font-weight: normal;
`;

const LegendItemContainer = styled(Flex)`
    padding: 5px;
`;

export const ExlamationCircleIcon = styled(FontAwesomeIcon)`
    width: 32px;
    height: 32px;
    color: rgb(253, 185, 39);
    font-size: 16px;
    font-weight: solid;
    text-align: center;
    cursor: pointer;
`;

export const CustomTooltip = styled(ReactTooltip).attrs({
    className: "custom-tooltip"
})`
    &.custom-tooltip {
        opacity: 1 !important;
        width: 288px;
    }
`;

export const TitleTooltip = styled.div`
    color: rgb(255, 255, 255);
    font-family: Roboto;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 12px;
    margin-top: 16px;
`;

export const TextTooltip = styled.div`
    color: rgb(255, 255, 255);
    font-family: Roboto;
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0.4px;
    line-height: 16px;
`;

function cleanDecimals(number) {
    const value = typeof number === "number"
        ? number.toFixed(2) : number;
    try {
        return value.replaceAll(".00", "");
    } catch (e) {
        return value;
    }
}

export const LegendItem = ({ color, message, quantity, percentage, total, showQuantity }) => {
    const qty = quantity ? cleanDecimals(quantity) : "0";
    //const perc = percentage ? cleanDecimals(percentage) : "0";
    return (
        <LegendItemContainer>
            <LegendSquare mr="8px" color={color} />
            <LegendText>
                {message}
                <Flex width="100px">
                    <Box>{showQuantity ? qty : null}{total ? "/" + total : ""}</Box>
                    {color === "#da2c38" ? (
                        <Box ml="5px" mt="-10px">
                            <ExlamationCircleIcon icon={faExclamationCircle} data-tip data-for="extraUsageTip" />
                            <CustomTooltip
                                backgroundColor="rgb(0, 80, 117)"
                                className="custom-tooltip"
                                id="extraUsageTip"
                                place="bottom"
                                effect="solid">
                                <TitleTooltip><FormattedMessage id="extrausage.tooltip.title" /></TitleTooltip>
                                <p>
                                    <TextTooltip>
                                        <FormattedMessage
                                            id="extrausage.tooltip.text"
                                            values={{ number: qty }}
                                        />
                                    </TextTooltip>
                                </p>
                            </CustomTooltip>
                        </Box>
                    ) : null}
                </Flex>
            </LegendText>
        </LegendItemContainer>
    )
};
