import React, {
    useState,
    useEffect,
    useCallback,
    useRef,
    useLayoutEffect,
} from "react";
import PropTypes from "prop-types";
import moment from "moment";
import 'moment/locale/it';
import { Flex, Box } from "reflexbox";
import { Select } from "../../select";
import { useIntl, FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { Button } from "../../button";
import {
    RootContainer,
    NextPreviousIcon,
    WeekdayBox,
    DayBox,
    AbsoluteRangeLimitIndicator,
    AbsoluteRangeLimitBackground,
} from "./styled";

export const Picker = ({
    openFrom,
    openTo,
    minimumDate,
    maximumDate,
    value,
    onChange,
    onClose,
    ...rest
}) => {
    const [from, to] = value;
    const { formatMessage } = useIntl();
    const containerRef = useRef(null);

    const [selectOptions, setSelectOptions] = useState([]);
    const [selectValue, setSelectValue] = useState(null);

    const [days, setDays] = useState([]);

    const [selectedFrom, setSelectedFrom] = useState(from);
    const [selectedTo, setSelectedTo] = useState(to);

    //const [selectingFrom, setSelectingFrom] = useState(false);
    const [selectingTo, setSelectingTo] = useState(false);

    // based on minimum and maximum possible dates, extracts the
    // options to be shown in the month / year select
    useEffect(() => {
        const options = [];
        const minimumYear = minimumDate.year();
        let startYear = maximumDate.year();
        moment.locale("it");
        for (let year = startYear; year >= minimumYear; year--) {
            for (let month = 11; month >= 0; month--) {
                const date = moment([year, month, minimumDate.get('date')]);
                if (date.isBetween(minimumDate, maximumDate, 'month', '[]')) {
                    options.push({
                        key: "" + year + month,
                        value: date.format("MM-YYYY"),
                        label: date.format("MMMM YYYY"),
                    });
                }
            }
        }
        setSelectOptions(options);
    }, [formatMessage, maximumDate, minimumDate]);

    // changing selected month/year option on date change
    useEffect(() => {
        if (openTo && selectOptions && selectOptions.length > 0 && selectedTo) {
            const formattedValue = selectedTo.format("MM-YYYY");
            setSelectValue(
                selectOptions.find((option) => {
                    return option.value === formattedValue;
                }),
            );
        } else if (openFrom && selectOptions && selectOptions.length > 0 && selectedFrom) {
            const formattedValue = selectedFrom.format("MM-YYYY");
            setSelectValue(
                selectOptions.find((option) => {
                    return option.value === formattedValue;
                }),
            );
        }
    }, [openFrom, openTo, selectOptions, selectedFrom, selectedTo, selectingTo]);

    // on month/year selection extracts the days to be rendered,
    // along with day of the week information
    useLayoutEffect(() => {
        moment.locale("it");
        const days = [];
        const placeholderDate = selectValue
            ? moment(selectValue.value, "MM-YYYY")
            : moment();
        const daysInMonth = placeholderDate.daysInMonth();
        // if the month starts mid week, add placeholders
        // to fill from first weekday (makes rendering easier)
        for (let i = 0; i < placeholderDate.date(1).weekday(); i++) {
            days.push(null);
        }
        for (let i = 1; i <= daysInMonth; i++) {
            const specificDate = placeholderDate.date(i);
            days.push({
                number: i,
                selected:
                    selectedFrom &&
                    selectedTo &&
                    specificDate.isBetween(
                        selectedFrom,
                        selectedTo,
                        "day",
                        "[]",
                    ),
                date: specificDate.clone(),
            });
        }
        for (let i = placeholderDate.date(daysInMonth).weekday(); i < 6; i++) {
            days.push(null);
        }
        setDays(days);
    }, [selectValue, selectedFrom, selectedTo]);

    const handleClick = useCallback(
        (event) => {
            if (containerRef.current.contains(event.target)) {
                return;
            }
            document.removeEventListener("mousedown", handleClick);
            onClose();
        },
        [onClose],
    );

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, [handleClick]);

    const handleMonthYearChange = useCallback((monthYear) => {
        setSelectValue(monthYear);
    }, []);

    const getDayClickHandler = (day) => () => {
        if (!day) {
            return;
        }
        if (openTo && day.date.isAfter(selectedFrom)) {
            setSelectedTo(day.date);
            setSelectingTo(!selectingTo);
        } else {
            setSelectingTo(selectingTo);
            setSelectedFrom(day.date);
        }
    };

    const handlePreviousMonth = useCallback(() => {
        const currentMonthIndex = selectOptions.findIndex(
            (option) => option === selectValue,
        );
        if (
            currentMonthIndex >= 0 &&
            currentMonthIndex + 1 < selectOptions.length
        ) {
            setSelectValue(selectOptions[currentMonthIndex + 1]);
        }
    }, [selectOptions, selectValue]);

    const handleNextMonth = useCallback(() => {
        const currentMonthIndex = selectOptions.findIndex(
            (option) => option === selectValue,
        );
        const previousMonthIndex = currentMonthIndex - 1;
        if (previousMonthIndex >= 0) {
            setSelectValue(selectOptions[previousMonthIndex]);
        }
    }, [selectOptions, selectValue]);

    const handleConfirm = useCallback(() => {
        onChange([selectedFrom, selectedTo]);
        onClose();
    }, [onChange, onClose, selectedFrom, selectedTo]);

    return (
        <RootContainer
            flexDirection="column"
            alignItems="center"
            p={16}
            ref={containerRef}
            {...rest}
        >
            <Flex width="100%" mb={16} mt={16}>
                <NextPreviousIcon
                    minWidth={40}
                    width={40}
                    height={40}
                    onClick={handlePreviousMonth}
                >
                    <FontAwesomeIcon icon={faCaretLeft} />
                </NextPreviousIcon>
                <Box width="100%" flexGrow="1">
                    <Select
                        value={selectValue}
                        options={selectOptions}
                        onChange={handleMonthYearChange}
                    />
                </Box>
                <NextPreviousIcon
                    minWidth={40}
                    width={40}
                    height={40}
                    onClick={handleNextMonth}
                >
                    <FontAwesomeIcon icon={faCaretRight} />
                </NextPreviousIcon>
            </Flex>
            <Flex justifyContent="center" width="100%" mb="8px" px={16}>
                {moment.weekdaysShort().map((weekday, index) => (
                    <WeekdayBox p="8px" key={index}>
                        {weekday.charAt(0)}
                    </WeekdayBox>
                ))}
            </Flex>
            <Flex
                mb="8px"
                px={16}
                justifyContent="center"
                width="100%"
                flexWrap="wrap"
            >
                {days.map((day, index) => {
                    const rangeStart =
                        day &&
                        selectedFrom &&
                        selectedFrom.isSame(day.date, "day");
                    const rangeEnd =
                        day && selectedTo && selectedTo.isSame(day.date, "day");
                    const disabled =
                        day &&
                        (day.date.isBefore(minimumDate, "day") ||
                            day.date.isAfter(maximumDate, "day"));
                    return (
                        <DayBox
                            disabled={disabled}
                            width={1 / 7}
                            my="4px"
                            valid={!!day}
                            rangeStart={rangeStart}
                            rangeEnd={rangeEnd}
                            selected={day && day.selected}
                            key={index}
                            onClick={
                                disabled ? () => {} : getDayClickHandler(day)
                            }
                        >
                            {rangeStart || rangeEnd ? (
                                <>
                                    <AbsoluteRangeLimitIndicator
                                        start={rangeStart}
                                        end={rangeEnd}
                                        roundBorders={
                                            !(selectedFrom && selectedTo) ||
                                            selectedFrom.isSame(
                                                selectedTo,
                                                "day",
                                            )
                                        }
                                    >
                                        {day && day.number}
                                    </AbsoluteRangeLimitIndicator>
                                    {selectedFrom &&
                                        selectedTo &&
                                        !selectedFrom.isSame(
                                            selectedTo,
                                            "day",
                                        ) && (
                                            <AbsoluteRangeLimitBackground
                                                start={rangeStart}
                                                end={rangeEnd}
                                            />
                                        )}
                                </>
                            ) : (
                                day && day.number
                            )}
                        </DayBox>
                    );
                })}
            </Flex>
            <Flex width="100%" justifyContent="space-between">
                <Box>
                    <Button kind="tertiary" onClick={onClose}>
                        <FormattedMessage id="range.date.picker.button.cancel" />
                    </Button>
                </Box>
                <Box>
                    <Button onClick={handleConfirm}>
                        <FormattedMessage id="range.date.picker.button.confirm" />
                    </Button>
                </Box>
            </Flex>
        </RootContainer>
    );
};

Picker.propTypes = {
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    minimumDate: PropTypes.object,
    maximumDate: PropTypes.object,
    value: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};

Picker.defaultProps = {
    value: [moment(), moment()],
    minimumDate: moment().subtract("5", "years"),
    maximumDate: moment(),
};
