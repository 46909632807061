import { parentApp } from "./../index";

let refreshingToken = false;
let tokenRefreshPromise;

async function tokenRefreshCall(email, dispatch) {
    const response = parentApp.call("getNewAccessToken");
    dispatch({
        type: "LOGIN_SUCCESS",
        payload: {
            loginAuth: {
                securityToken: response.applicationToken,
                id: email,
            }
        }
    });
    return response;
}

export async function refreshToken(email, dispatch) {
    if (!refreshingToken) {
        refreshingToken = true;
        tokenRefreshPromise = tokenRefreshCall(email, dispatch);
    }

    const response = await tokenRefreshPromise;
    refreshingToken = false;

    return response.token;
}
