import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { Flex, Box } from "reflexbox";
import styled from "styled-components";
import { FormattedMessage, useIntl } from "react-intl";
import { InvoicingPackageCard } from "../../components/package-card/invoicing";
import { SignaturePackageCard } from "../../components/package-card/signature";
import { StandAloneCard } from "../../components/package-card/signature/stand-alone";
import { EcobonusPackageCard } from "../../components/package-card/ecobonus";
import { useSelector, useDispatch } from "react-redux";
import {
    trackViewDetailsFromItem,
    trackViewHome,
    trackExportCsvStatistics,
} from "../../actions/gainsightEvents";
import { Button } from "../../components/button";
import {
    faFileExport,
    faArrowDown,
    faArrowUp,
} from "@fortawesome/pro-regular-svg-icons";
import { useCallback } from "react";
import { downloadStatisticsCsv } from "../../actions/csv";
import { useHistory } from "react-router-dom";
import Searchbar from "../../components/searchbar";
import { FloatingActionButton } from "../../components/floating-action-button";
import { useRef } from "react";
import { Table } from "../../components/table";
import { TableActionIcon } from "../../components/table-action-icon";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import { Pagination } from "../../components/pagination";
import { getStatisticsTableColumnsSpecification } from "../../commons/table-handler";
import { Checkbox } from "../../components/checkbox";
import { RapidView } from "../../components/rapid-view";
import { RootFlexBorderLess } from "../../components/package-card/styled";
import { CheckupPackageCard } from "../../components/package-card/checkup";
import { PayPackageCard } from "../../components/package-card/pay";
import { StandAloneCheckupCard } from "../../components/package-card/checkup/stand-alone";
import { AiPackageCard } from "../../components/package-card/ai";

const YourSubscriptionsText = styled(Box)`
    color: rgb(0, 80, 117);
    font-family: Cairo;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    text-transform: uppercase;
`;

const CompanyNameText = styled(Box)`
    color: rgb(0, 80, 117);
    font-family: Cairo;
    font-size: 34px;
    line-height: 40px;
    font-weight: 600;
`;

export const Home = ({
    company,
    packages,
    itemId,
    userId,
    email,
    accessToken,
    refreshToken,
    service,
    itemName,
    studio,
    filteredItem,
    setFilteredItem,
    statisticsPage,
    setStatisticsPage,
    statisticsSorting,
    setStatisticsSorting,
}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const statisticsTableRef = useRef(null);
    const { formatMessage } = useIntl();

    const [
        floatingActionButtonFaIcon,
        setFloatingActionButtonFaIcon,
    ] = useState(faArrowDown);
    const [
        floatingActionButtonMessage,
        setFloatingActionButtonMessage,
    ] = useState(<FormattedMessage id="statistics.table.fab.managed" />);
    const [floatingActionButtonHref, setFloatingActionButtonHref] = useState(
        "#statistics-table",
    );
    const [checkedItems, setCheckedItems] = useState({});
    const [totalSelected, setTotalSelected] = useState(0);
    const [checkedAllItems, setCheckedAllItems] = useState(false);

    useEffect( () => {
        dispatch(trackViewHome(itemId, service));
    }, [itemId, service, dispatch]);

    const trackViewDetailsByItem = useCallback( (managedItem) => {
            dispatch(trackViewDetailsFromItem(itemId, managedItem, service));
    }, [itemId, service, dispatch]);

    const handleSelectItems = useCallback( (checked, event) => {
            if (checked) {
            setCheckedItems({...checkedItems, [event.target.id] : checked });
            } else {
                delete checkedItems[event.target.id];
                setCheckedItems(checkedItems);
            }
        checked ? setTotalSelected(totalSelected + 1) : setTotalSelected(totalSelected - 1);
    }, [checkedItems, totalSelected]);

    const { statisticsSize, statistics, items } = useSelector((state) => ({
        itemsLoaded: state.items.loaded,
        itemsLoading: state.items.loadings,
        items: state.items.data,
        signatureRapidView: state.subscriptions.data.signature,
        statisticsSize: state.statistics.data.size,
        statistics: state.statistics.data.statistics && state.statistics.data.statistics.length > 0 ? 
            state.statistics.data.statistics.map((statistic) => ({
                      ...statistic,
                      actions: (
                          <TableActionIcon
                              data-testid={statistic.fiscalCode}
                              icon={faArrowRight}
                              onClick={() => {
                                  trackViewDetailsByItem(statistic.fiscalCode);
                            history.replace(`/details?item=${statistic.fiscalCode}&package=${packages[0].id}`);
                              }}
                          />
                      ),
                      selected: (
                          <Checkbox
                              id={statistic.fiscalCode}
                              checked={checkedItems[statistic.fiscalCode]}
                        onChange={handleSelectItems} />
                )
            })) : [],
    }));

    const handleStatisticsTableSelectAll = useCallback( (checked) => {
            setCheckedAllItems(checked);
            if (checked) {
            const updatedCheckedItems = statistics.reduce((acc, statistic) => {
                if (!checkedItems.hasOwnProperty(statistic.fiscalCode)) {
                            acc[statistic.fiscalCode] = true;
                        }
                        return acc;
            }, {...checkedItems});
                setCheckedItems(updatedCheckedItems);
                setTotalSelected(Object.keys(updatedCheckedItems).length);
            } else {
                statistics.forEach((stat) => {
                    if (checkedItems.hasOwnProperty(stat.fiscalCode)) {
                        delete checkedItems[stat.fiscalCode];
                    }
                });
                setCheckedItems(checkedItems);
                setTotalSelected(Object.keys(checkedItems).length);
            }
    }, [checkedItems, statistics]);

    const handleClickStatisticsTableSelectAll = useCallback( (checked) => {
            handleStatisticsTableSelectAll(!checked);
    }, [handleStatisticsTableSelectAll]);

    const statisticsColumns = useMemo(() => {
        const selectedItems = statistics.filter((s) => { return s.selected.props.checked });
        const selectAllCheckbox = (
            <Checkbox
                id={"all"}
                indeterminate={selectedItems.length !== statistics.length}
                checked={checkedAllItems || selectedItems.length > 0}
                onChange={handleStatisticsTableSelectAll} onClick={handleClickStatisticsTableSelectAll}
            />
        );
        return getStatisticsTableColumnsSpecification(service, selectAllCheckbox);
    }, [service, checkedAllItems, handleStatisticsTableSelectAll, statistics, handleClickStatisticsTableSelectAll]);

    const checkWindowBottoming = () => {
        if (
            statisticsTableRef.current &&
            statisticsTableRef.current.getBoundingClientRect().bottom <=
                window.innerHeight + 100
        ) {
            setFloatingActionButtonFaIcon(faArrowUp);
            setFloatingActionButtonMessage(
                <FormattedMessage id="statistics.table.fab.packages" />,
            );
            setFloatingActionButtonHref("#packages-list");
        } else {
            setFloatingActionButtonFaIcon(faArrowDown);
            setFloatingActionButtonMessage(
                <FormattedMessage id="statistics.table.fab.managed" />,
            );
            setFloatingActionButtonHref("#statistics-table");
        }
    };

    useEffect(() => {
        document.addEventListener("resize", checkWindowBottoming);
        document.addEventListener("scroll", checkWindowBottoming);
        return () => {
            document.removeEventListener("scroll", checkWindowBottoming);
            document.addEventListener("resize", checkWindowBottoming);
        };
    }, []);

    const handleFilteredItemChange = useCallback((newFilteredItem) => {
            if (!newFilteredItem.value) {
                // the searchbar was reset
                setFilteredItem(null);
            } else if (newFilteredItem.datasourceKey) {
                setFilteredItem(newFilteredItem);
            }
    }, [setFilteredItem]);

    const handleExport = useCallback(() => {
        let selectedItems = [];
        Object.keys(checkedItems).forEach((key) => {
            if (checkedItems[key]) {
                selectedItems.push(key);
            }
        });
        let selectedItemsFiscalCodes = "";
        if (selectedItems && selectedItems.length > 0) {
            selectedItems.forEach(si => {
                const itemFiltered = items.filter(i => i.key === si);
                if (itemFiltered && itemFiltered.length > 0) {
                    selectedItemsFiscalCodes += itemFiltered[0].fiscalCode + ",";
                }
            });
        }
        dispatch(
            downloadStatisticsCsv(
                itemId,
                userId,
                accessToken,
                service,
                null,
                null,
                false,
                selectedItems && selectedItems.length > 0 ? selectedItemsFiscalCodes + selectedItems.toString() : null,
                refreshToken,
                email,
                items
            ),
        );
        dispatch(trackExportCsvStatistics(itemId, selectedItems, service));
    }, [accessToken, checkedItems, dispatch, itemId, service, userId, refreshToken, email, items]);

    const handleExpand = useCallback(
        (id) => {
            history.replace(`/details?package=${id}`);
        },
        [history],
    );

    const handleStatisticsSorting = useCallback(
        (column) => {
            const oldColumnIndex = statisticsColumns.findIndex(
                (columnWithOrdering) => column.key === columnWithOrdering.key,
            );
            const currentColumnSorting = column.sorting;
            let newColumnSorting = "none";
            if (currentColumnSorting === "none") {
                newColumnSorting = "descend";
            } else if (currentColumnSorting === "descend") {
                newColumnSorting = "ascend";
            }
            column.sorting = newColumnSorting;
            statisticsColumns[oldColumnIndex] = column;
            setStatisticsSorting(`${column.sortingKey}.${newColumnSorting}`);
        },
        [statisticsColumns, setStatisticsSorting],
    );

    const handleStatisticsPageChange = useCallback(
        (page, checkedAllItems) => {
            setStatisticsPage(page);
            setCheckedAllItems(checkedAllItems);
        }, [setStatisticsPage]
    );

    const standalonePackages = packages.filter((p) => {
        return p.isStandalone === true
    });

    function updatePackageUsage(packages, fromFamily, toFamily) {
        let fromPackageIndex = packages.findIndex(p => p.family === fromFamily);
        let toPackageIndex = packages.findIndex(p => p.family === toFamily);

        if (fromPackageIndex !== -1 && toPackageIndex !== -1) {
            
            if (fromPackageIndex > toPackageIndex) {
                [fromPackageIndex, toPackageIndex] = [toPackageIndex, fromPackageIndex];
            }

            const extraUsage = packages[fromPackageIndex].extraUsage || 0;

            packages[toPackageIndex].regularUsage += extraUsage;

            if (extraUsage > 0) {
                packages[fromPackageIndex].extraUsage = 0;
            }
        }
    }

    updatePackageUsage(packages, "FATTURE_ATTIVE", "FATTURE_ATTIVE_PASSIVE");
    updatePackageUsage(packages, "FATTURE_ATTIVE_PASSIVE", "FATTURE_ATTIVE");
    updatePackageUsage(packages, "FATTURE_PASSIVE", "FATTURE_ATTIVE_PASSIVE");
    updatePackageUsage(packages, "FATTURE_ATTIVE_PASSIVE", "FATTURE_PASSIVE");

    const mainPackages = packages.filter((p) => {
        return !p.isStandalone;
    });

    return (
        <Flex
            flexDirection="column"
            justifyContent="space-between"
            mb="68px"
            id="packages-list"
        >
            {service.toUpperCase() === "SIGNATURE" ? (
                <RapidView companyName={itemName} />
            ) : null
            }
            <Flex flexDirection="column"  pt={service === "signature" ? "30px" : "0px"}>
                <YourSubscriptionsText mb="8px">
                    <FormattedMessage id="home.title.subscriptions.your" />
                </YourSubscriptionsText>
                <CompanyNameText mb="24px">{itemName}</CompanyNameText>
            </Flex>
            <Flex flexWrap="wrap" width="100%" mb="40px">
                {mainPackages.map((subscription) => (
                    <Box key={subscription.id} p="12px">
                        {
                            service.toUpperCase() === "INVOICING" ? (
                            <InvoicingPackageCard
                                {...subscription}
                                company={company}
                                onExpand={handleExpand}
                            />
                        ) : service.toUpperCase() === "SIGNATURE" ? (
                            <SignaturePackageCard
                                {...subscription}
                                onExpand={handleExpand}
                            />
                        ) : service.toUpperCase() === "CHECKUP" ? (
                            <CheckupPackageCard
                                {...subscription}
                                company={company}
                                onExpand={handleExpand}
                            />
                        ) : service.toUpperCase() === "ECOBONUS" ? (
                                <EcobonusPackageCard
                                    {...subscription}
                                />
                        ) : service.toUpperCase() === "PAY" ? (
                            <PayPackageCard
                                {...subscription}
                                onExpand={handleExpand}
                            />
                        ) : service.toUpperCase() === "AI" ? (
                            <AiPackageCard
                                {...subscription}
                                onExpand={handleExpand}
                            />
                        ) : null}
                    </Box>
                ))
                }
                { service.toUpperCase() === "SIGNATURE" || service.toUpperCase() === "CHECKUP" ?
                    <RootFlexBorderLess flexWrap={"wrap"}>
                        {
                            standalonePackages.map((subscription) => (
                                service.toUpperCase() === "SIGNATURE" ?
                                    <StandAloneCard {...subscription} /> : service.toUpperCase() === "CHECKUP" ? <StandAloneCheckupCard {...subscription} /> : null
                            ))
                        }
                    </RootFlexBorderLess>
                    : null
                }
                {/* TODO: re enable once the environment-related dashboard is a thing */}
                {/* <Box p="12px">
                    <EnvironmentCard />
                </Box> */}
            </Flex>
            {((studio && statistics && statistics.length > 0) || (statistics && statistics.length > 0)) && (service === "invoicing") && (
                    <>
                        <FloatingActionButton
                            faIcon={floatingActionButtonFaIcon}
                            href={floatingActionButtonHref}
                        >
                            {floatingActionButtonMessage}
                        </FloatingActionButton>
                        <div ref={statisticsTableRef}>
                            <Flex
                                id="statistics-table"
                                alignItems="flex-end"
                                justifyContent="space-between"
                                mb="36px"
                            >
                                <Flex flexDirection="column">
                                    <YourSubscriptionsText mb="8px">
                                        <FormattedMessage id="statistics.header.subscriptions.yours" />
                                    </YourSubscriptionsText>
                                <CompanyNameText>{itemName}</CompanyNameText>
                                </Flex>
                                <Flex>
                                    <Box mr="16px">
                                        <Button
                                            kind="tertiary"
                                            faIcon={faFileExport}
                                            disabled={statistics.length === 0}
                                            onClick={handleExport}
                                        >
                                        { (totalSelected && totalSelected > 0 && totalSelected < statisticsSize) ? (
                                                <FormattedMessage id="statistics.button.export.selection" />
                                            ) : (
                                                <FormattedMessage id="statistics.button.export.all" />
                                            ) 
                                        }

                                        </Button>
                                    </Box>
                                    <Box>
                                        <Searchbar
                                        datasource={statistics.map((item) => {
                                            item.label = item.company + " (" + item.vatNumber + ")";
                                                    return item;
                                        })}
                                            onSearch={handleFilteredItemChange}
                                            placeholder={formatMessage({
                                            id:
                                                "statistics.managed.searchbar.placeholder",
                                            })}
                                        />
                                    </Box>
                                </Flex>
                            </Flex>
                            <Box width="100%">
                                <Table
                                    columns={statisticsColumns}
                                footerTotalsLabel={<FormattedMessage
                                            id="statistics.table.footer.total"
                                    values={{ total: <b>{statisticsSize}</b> }}
                                />}
                                items={statistics.slice(statisticsPage * 10, 10 + (statisticsPage * 10))}
                                    selectedItems={checkedItems}
                                    size={statisticsSize}
                                    totalSelected={totalSelected}
                                    onSortingChange={handleStatisticsSorting}
                                />
                            </Box>
                            {statisticsSize > 0 ? (
                                <Flex width="100%" justifyContent="flex-end">
                                    <Box>
                                        <Pagination
                                            checkedAllItems={checkedAllItems}
                                            page={statisticsPage}
                                            rowsPerPage={10}
                                            size={statisticsSize}
                                    onPageChange={handleStatisticsPageChange}
                                        />
                                    </Box>
                                </Flex>
                            ) : null}
                        </div>
                    </>
                )}
        </Flex>
    );
};

Home.propTypes = {
    packages: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            timestamp: PropTypes.string.isRequired,
            capacity: PropTypes.number.isRequired,
            regularUsage: PropTypes.number.isRequired,
            extraUsage: PropTypes.number.isRequired,
        }).isRequired,
    ).isRequired,
    itemId: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    accessToken: PropTypes.string.isRequired,
    service: PropTypes.string.isRequired,
    studio: PropTypes.bool.isRequired,
};

Home.defaultProps = {
    packages: [],
};
