import React, { useState, useCallback, useEffect, useMemo } from "react";
import { Box, Flex } from "reflexbox";
import { FormattedMessage } from "react-intl";
import { faFileExport } from "@fortawesome/pro-regular-svg-icons";
import { Button } from "../../components/button";
import { useSelector, useDispatch } from "react-redux";
import { getFilesMetadata } from "../../actions/files";
import { downloadHistoryCsv, downloadAllHistoryCsv } from "../../actions/csv";
import { UniversalSpinner } from "../../components/universal-spinner";
//import { useQuery } from "../../hooks";
import { Table } from "../../components/table";
import { getHistoryDetailsTableColumnsSpecification } from "../../commons/table-handler";
import { Pagination } from "../../components/pagination";

import { TableActionIcon } from "../../components/table-action-icon";
import { TooltipEnhanced } from "../../components/tooltip/tooltip";
import { Checkbox } from "../../components/checkbox";

export const HistoryPanel = ({
                                  itemId,
                                  itemUuid,
                                  userId,
                                  accessToken,
                                  service,
                                  items,
                                  refreshToken,
                                  email
                              }) => {
    //const query = useQuery();
    //const { formatMessage } = useIntl();
    const dispatch = useDispatch();
    const [checkedItems, setCheckedItems] = useState({});
    const [totalSelected, setTotalSelected] = useState(0);
    const [checkedAllItems, setCheckedAllItems] = useState(false);

    //const itemFromQuery = query.get("item");

    const handleSelectItems = useCallback( (checked, event) => {
        if (checked) {
            setCheckedItems({...checkedItems, [event.target.id] : checked });
        } else {
            delete checkedItems[event.target.id];
            setCheckedItems(checkedItems);
        }
        checked ? setTotalSelected(totalSelected + 1) : setTotalSelected(totalSelected - 1);
    }, [checkedItems, totalSelected]);

    const { filesSize, files, filesLoading } = useSelector((state) => ({
        filesPages: state.historyDetails.data.numberOfPages,
        filesSize: state.historyDetails.data.size,
        files: state.historyDetails.data.fileMetadataItemList && state.historyDetails.data.fileMetadataItemList.length > 0 ?
            state.historyDetails.data.fileMetadataItemList.map((file) => ({
            ...file,
                exportCsv: (
                <TooltipEnhanced content={<FormattedMessage id="historyDetails.table.column.exportCsv.tooltip" />} direction="left">
                    <TableActionIcon
                        icon={faFileExport}
                        onClick={() => {
                            dispatch(
                                downloadHistoryCsv([file.id], file.name, itemUuid, userId, accessToken, refreshToken, email, service)
                            );
                            }
                        }
                    />
                </TooltipEnhanced>
            ),
            key: file.id,
            selected: (
                <Checkbox
                    id={file.id}
                    checked={checkedItems[file.id]}
                    onChange={handleSelectItems} />
            )
        })) : [],
        filesLoading: !!state.historyDetails.loadings
    }));

    const [dateRange] = useState([null, null]);
    const [filesPage, setFilesPage] = useState(0);
    //const [filesSorting, setFilesSorting] = useState("timestamp.descend");
    //const [filter, setFilter] = useState({key: "all"});
    //const [filters, setFilters] = useState([]);
    //const [itemsWithResetOption, setItemsWithResetOption] = useState(items);
    const [labelExportButton, setLabelExportButton] = useState(<FormattedMessage id="details.button.export.all" />);
    const [currentPackage] = useState(null);
    const [online] = useState(false);
    //const [item, setItem] = useState(null);

    /*useEffect(() => {
        if (itemFromQuery) {
            //const filter = items.find((item) => (item.key === filteredItemFromQuery || item.fiscalCode === filteredItemFromQuery));
            setItem(itemFromQuery);
        } else {
            setItem(null);
        }
    }, [itemFromQuery, setItem]);*/

    useEffect( () => {
        (totalSelected && totalSelected > 0) ?
            setLabelExportButton(<FormattedMessage id="details.button.export.selection" />)
            : setLabelExportButton(<FormattedMessage id="details.button.export.all" />)
    }, [currentPackage, totalSelected]);

    /*useEffect(() => {
        setItemsWithResetOption([
            {
                key: null,
                value: formatMessage({ id: "details.items.option.all" }),
            },
            ...items,
        ]);
    }, [items, formatMessage, setItemsWithResetOption]);*/


    const handleFilesTableSelectAll = useCallback( (checked) => {
        setCheckedAllItems(checked);
        if (checked) {
            const updatedCheckedItems = files.reduce((acc, detail) => {
                if (!checkedItems.hasOwnProperty(detail.key)) {
                    acc[detail.key] = true;
                }
                return acc;
            }, {...checkedItems});
            setCheckedItems(updatedCheckedItems);
            setTotalSelected(Object.keys(updatedCheckedItems).length);
        } else {
            files.forEach((det) => {
                if (checkedItems.hasOwnProperty(det.key)) {
                    delete checkedItems[det.key];
                }
            });
            setCheckedItems(checkedItems);
            setTotalSelected(Object.keys(checkedItems).length);
        }
    }, [checkedItems, files]);

    const handleClickFilesTableSelectAll = useCallback( (checked) => {
        handleFilesTableSelectAll(!checked);
    }, [handleFilesTableSelectAll]);


    useEffect(() => {
        if (service && accessToken && itemId && userId) {
            dispatch(
                getFilesMetadata(
                    itemId,
                    userId,
                    accessToken,
                    service,
                    filesPage,
                    10,
                    dateRange[0],
                    dateRange[1],
                    online,
                    refreshToken,
                    email != null ? email : userId
                )
            );
        }
    }, [
        dateRange,
        accessToken,
        filesPage,
        dispatch,
        itemId,
        service,
        userId,
        refreshToken,
        email,
        online
    ]);

    const filesColumns = useMemo(() => {
        const selectedItems = files.filter((s) => { return s.selected.props.checked });
        const selectAllCheckbox = (
            <Checkbox
                id={"all"}
                indeterminate={selectedItems.length !== files.length}
                checked={checkedAllItems || selectedItems.length > 0}
                onChange={handleFilesTableSelectAll} onClick={handleClickFilesTableSelectAll}
            />
        );
        return getHistoryDetailsTableColumnsSpecification(selectAllCheckbox)
    }, [checkedAllItems, handleFilesTableSelectAll, files,
        handleClickFilesTableSelectAll]);

    const handleExport = useCallback(() => {
        let selectedItems = [];
        Object.keys(checkedItems).forEach((key) => {
            if (checkedItems[key]) {
                selectedItems.push(key);
            }
        });
        if (selectedItems && selectedItems.length > 0) {
            dispatch(
                downloadHistoryCsv(selectedItems, "download", itemUuid, userId, accessToken, refreshToken, email, service)
            );
        } else {
            dispatch(downloadAllHistoryCsv("download", itemUuid, userId, accessToken, refreshToken, email, service));
        }
    }, [
        accessToken,
        checkedItems,
        dispatch,
        itemUuid,
        service,
        userId,
        refreshToken,
        email
    ]);

    const handleFilesSorting = useCallback(
        (column) => {
            const oldColumnIndex = filesColumns.findIndex(
                (columnWithOrdering) => column.key === columnWithOrdering.key,
            );

            const currentColumnSorting = column.sorting;
            let newColumnSorting = "none";
            if (currentColumnSorting === "none") {
                newColumnSorting = "descend";
            } else if (currentColumnSorting === "descend") {
                newColumnSorting = "ascend";
            }
            column.sorting = newColumnSorting;
            filesColumns[oldColumnIndex] = column;
            //setFilesSorting(`${column.sortingKey}.${newColumnSorting}`);
        },
        [filesColumns],
    );

    const handleFilesPageChange = useCallback(
        (page, checkedAllItems) => {
            setFilesPage(page);
            setCheckedAllItems(checkedAllItems);
        }, []
    );

    return (
        <>
            <Flex flexDirection="column">
                <Flex
                    alignItems="flex-end"
                    justifyContent="space-between"
                    mb="16px"
                    mt="20px"
                >
                    <Flex flexDirection="column" width={"400px"} />
                    <Flex alignItems="flex-end">
                        <Box>
                            <Button
                                kind="tertiary"
                                faIcon={faFileExport}
                                disabled={filesSize === 0}
                                onClick={handleExport}
                            >
                                { labelExportButton }
                            </Button>
                        </Box>
                    </Flex>
                </Flex>
                <Flex>
                    <Box width="100%">
                        <Table
                            service={service}
                            columns={filesColumns}
                            footerTotalsLabel={
                                    <FormattedMessage
                                        id="details.table.footer.total"
                                        values={{ total: <b>{filesSize}</b> }}
                                    />
                            }
                            items={files}
                            selectedItems={checkedItems}
                            size={filesSize}
                            totalSelected={totalSelected}
                            onSortingChange={handleFilesSorting}
                        />
                    </Box>
                </Flex>
                {filesSize > 0 ? (
                    <Flex width="100%" justifyContent="flex-end">
                        <Box>
                            <Pagination
                                checkedAllItems={checkedAllItems}
                                page={filesPage}
                                rowsPerPage={10}
                                size={filesSize}
                                onPageChange={handleFilesPageChange}
                            />
                        </Box>
                    </Flex>
                ) : null}
            </Flex>
            <UniversalSpinner open={filesLoading} />
        </>
    );
};
