import env, { setInputSource } from "@mondora/env";

export const NODE_ENV = env("NODE_ENV", { default: "dev" });
if (NODE_ENV !== "production") {
    require("dotenv").config();
} else {
    setInputSource(process.env);
}

export const ENV = env("REACT_APP_ENV", {
    required: true,
});

export const API_URL = env("REACT_APP_API_URL", { required: true });

export const ITEM = env("REACT_APP_ITEM", { required: ENV !== "production" });

export const ITEM_UUID = env("REACT_APP_ITEM_UUID", { required: ENV !== "production" });

export const LANGUAGE = env("REACT_APP_LANGUAGE", {
    required: ENV !== "production",
});

export const ACCESS_TOKEN = env("REACT_APP_ACCESS_TOKEN", {
    required: ENV !== "production",
});

export const USER_ID = env("REACT_APP_USER_ID", {
    required: ENV !== "production",
});

export const EMAIL = env("REACT_APP_EMAIL", {
    required: ENV !== "production",
});

export const REFRESH_TOKEN = env("REACT_APP_REFRESH_TOKEN", {
    required: ENV !== "production",
});

export const STUDIO = env("REACT_APP_STUDIO", {
    required: ENV !== "production",
    parse: (value) => value === "true",
});

export const REGISTRY_READ_API_URL = env("REACT_APP_REGISTRY_READ_API_URL", {
    required: true,
});

export const UPGRADE_INVOICING_PACKAGE_URL = env("REACT_APP_UPGRADE_INVOICING_PACKAGE_URL", {
    required: true
});

export const UPGRADE_CHECKUP_PACKAGE_URL = env("REACT_APP_UPGRADE_CHECKUP_PACKAGE_URL", {
    required: true
});

export const TS_PORTALE_API_URL = env("REACT_APP_TS_PORTALE_API_URL", {
    required: true
});

export const SIGNATURE_READ_API_URL = env("REACT_APP_SIGNATURE_READ_API_URL", {
    required: true
});

export const CONSUMPTION_DASHBOARD_URL = env("REACT_APP_CONSUMPTION_DASHBOARD_URL", {
    required: true
});

export const ARCHIVER_READ_API_URL = env("REACT_APP_ARCHIVER_READ_API_URL", {
    required: true
});

export const HISTORY_CONSUMPTIONS_ENABLED = env("REACT_APP_HISTORY_CONSUMPTIONS_ENABLED", {
    required: true
})