import {
    GET_FILES_METADATA_START,
    GET_FILES_METADATA_END,
    GET_FILES_METADATA_SUCCESS,
} from "../../actions/files";

const initialState = {
    loadings: 0,
    data: {
        numberOfPages: 0,
        size: 0,
        fileMetadataItemList: []
    },
};

export const filesReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_FILES_METADATA_START: {
            return {
                ...state,
                loadings: state.loadings + 1,
            };
        }
        case GET_FILES_METADATA_END: {
            return {
                ...state,
                loadings: state.loadings > 0 ? state.loadings - 1 : 0,
            };
        }
        case GET_FILES_METADATA_SUCCESS: {
            return {
                ...state,
                data: {
                    numberOfPages: action.numberOfPages,
                    size: action.size,
                    fileMetadataItemList: action.fileMetadataItemList
                },
            };
        }
        default: {
            return state;
        }
    }
};
