import { getSanitizedQueryString } from "../../utils";
import { getRestCall } from "../../utils/rest-api-call";
import { API_URL } from "../../env";

export const GET_CONNECTED_ITEMS_START = "GET_CONNECTED_ITEMS_START";
export const GET_CONNECTED_ITEMS_SUCCESS = "GET_CONNECTED_ITEMS_SUCCESS";
export const GET_CONNECTED_ITEMS_ERROR = "GET_CONNECTED_ITEMS_ERROR";

export const getConnectedItems = (service, item, accessToken, userId, refreshToken, email, from, to) => async (
    dispatch,
) => {
    dispatch({ type: GET_CONNECTED_ITEMS_START });
    try {
        const sanitizedQueryString = getSanitizedQueryString({
            userId,
            from,
            to
        });
        const response = await getRestCall(
            `${API_URL}/api/v1/consumptions/service/${service}/items/${item}/items?${sanitizedQueryString}`,
            accessToken,
            null,
            dispatch,
            userId,
            item,
            refreshToken,
            email,
            service.toUpperCase() === "INVOICING" ? "EIP" : "SIG"
        );
        const itemReturned = response.filter(p => p != null).sort(
            (a,b) => {
                if (a && b && a['value'] && b['value']) {
                    if (a['value'] > b['value']) {
                        return 1;
                    } else if (a['value'] < b['value']) {
                        return -1;
                    }
                }
                return 0;
            });
        dispatch({
            type: GET_CONNECTED_ITEMS_SUCCESS,
            items: itemReturned,
        });
    } catch (error) {
        console.error("could not get items", error);
        dispatch({ type: GET_CONNECTED_ITEMS_ERROR, payload: error });
    }

};
