import React, { useEffect, useState } from "react";
import { Box } from "reflexbox";
import { Button } from "../button";
import {
    DataLabel,
    DataValue,
    DrawerContent,
    Overlay,
    Drawer,
} from "../package-card/styled";
import { Header } from "../general/page-title/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FormattedMessage } from "react-intl";

export const DrawerSlotDetails = ({
    drawerOpen,
    toggleDrawer,
    slotDetails,
    selectedSlotId,
}) => {
    const [filteredSlotDetails, setFilteredSlotDetails] = useState([]);

    useEffect(() => {
        if (slotDetails && slotDetails.length > 0 && selectedSlotId !== null) {
            const detailsForSelectedSlot = slotDetails
                .filter((el) => el.slotId === selectedSlotId)
                .flatMap((el) =>
                    el.slotDetails.map((slot) => ({
                        slotId: el.slotId,
                        ...slot,
                    })),
                );

            setFilteredSlotDetails(detailsForSelectedSlot);
        }
    }, [slotDetails, selectedSlotId]);

    return (
        <div>
            <Drawer open={drawerOpen}>
                <Box
                    role="presentation"
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}
                >
                    <DrawerContent>
                        <Button kind="tertiary" onClick={toggleDrawer(false)}>
                            <FontAwesomeIcon icon={faArrowLeft} />
                        </Button>
                        <Header>
                            <FormattedMessage id="details.slot.title" />
                        </Header>
                        {filteredSlotDetails.map((el) => (
                            <React.Fragment key={el.name}>
                                <DataLabel >{el.name}:</DataLabel>
                                <DataValue >{el.value}</DataValue>
                            </React.Fragment>
                        ))}
                    </DrawerContent>
                </Box>
            </Drawer>
            <Overlay open={drawerOpen} onClick={toggleDrawer(false)} />
        </div>
    );
};
