import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const RootDiv = styled.div`
    visibility: hidden;
    position: relative;
    background: rgb(0, 80, 117);
    color: #fff;
    border-radius: 2px;
    padding: 4px;    
    margin-top: -31px;
    margin-left: -12px;
`;

const Arrow = styled.div`
    top: 100%;
    left: calc(50% - 5px);
    position: absolute;
    border-color: rgb(0, 80, 117) transparent transparent;
    border-style: solid;
    border-width: 5px;
`;

const Label = styled.div`
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 12px;
    text-align: center;
`;

export const Tooltip = ({ children, label, width }) => {
    const [show, setShow] = useState(false);
    return (
        <div>
            <RootDiv style={show ? {visibility: 'visible', width: width} : {}}>
                <Label>{label}</Label>
                <Arrow />
            </RootDiv>
            <div onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}>
                {children}
            </div>
        </div>

    );
};

Tooltip.propTypes = {
    label: PropTypes.node.isRequired,
    width: PropTypes.string
};
