import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { StandardTabs, Tab } from "@ts-digital/vrc";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { Details } from "../details";
import { Home } from "../home";
import { Switch, Route, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCompany } from "../../actions/company";
import { getPermissions } from "../../actions/permissions";
import { UniversalSpinner } from "../../components/universal-spinner";
import { getSubscriptionsByService } from "../../actions/subscriptions";
import { getConnectedItems } from "../../actions/items";
import { getDocumentTypes } from "../../actions/signature/documentTypes";
import { getStatistics } from "../../actions/statistics";

import PageTitle from "../../components/general/page-title";

const StyledStandardTabs = styled(StandardTabs)`
    width: 100%;
    border-bottom: solid 1px rgb(201, 217, 232);
    overflow-x: auto;
`;

const StyledTab = styled(Tab)`
    border: none;
`;

const TabContent = styled.div`
    padding: 40px;
`;

export const App = ({
    email,
    itemId,
    itemUuid,
    userId,
    accessToken,
    refreshToken,
    studio,
}) => {
    const dispatch = useDispatch();
    const {
        company,
        permissions,
        permissionsLoading,
        items,
        itemsLoading,
        subscriptions,
        subscriptionsLoading,
        statisticsLoading
    } = useSelector((state) => ({
        company: state.company.data,
        permissions: state.permissions.data,
        permissionsLoading: !!state.permissions.loadings,
        items: state.items.data,
        itemsLoading: !!state.items.loadings,
        subscriptions: state.subscriptions.data,
        subscriptionsLoading: !!state.subscriptions.loadings,
        statisticsLoading: !!state.statistics.loadings
    }));
    const history = useHistory();
    const [pastLocations, setPastLocations] = useState([]);

    function updatePastLocations(location, action) {
        switch (action) {
            case 'PUSH':
                // first location when app loads and when pushing onto history
                if (location.pathname !== "/details") {
                    pastLocations.push(location);
                }
                break;
            case 'REPLACE':
                // only when using history.replace
                pastLocations[pastLocations.length - 1] = location;
                break;
            case 'POP': {
                // happens when using the back button, or forward button
                pastLocations.pop();
                // location according to pastLocations
                const appLocation = pastLocations[pastLocations.length - 1];
                if (!(appLocation && appLocation.key === location.key)) {
                    // If the current location doesn't match what the app thinks is the current location,
                    // blow up the app history.
                    setPastLocations([location]);
                }
                break;
            }
            default:
        }
        setPastLocations(pastLocations);
    }
    history.listen(updatePastLocations);

    const [itemName, setItemName] = useState("");
    const [services, setServices] = useState([]);
    const [service, setService] = useState("");
    const [tabsIndex, setTabsIndex] = useState(0);
    const [filteredItem, setFilteredItem] = useState(null);
    const [statisticsPage, setStatisticsPage] = useState(0);
    const [statisticsSorting, setStatisticsSorting] = useState(null);

    useEffect(() => {
        if (services && services.length > 0) {
            setService(services[tabsIndex]);
        }
    }, [services, tabsIndex]);

    useEffect(() => {
        dispatch(getCompany(itemUuid, accessToken, userId, refreshToken, email));
    }, [itemUuid, accessToken, userId, dispatch, refreshToken, email]);

    useEffect(() => {
        dispatch(getPermissions(itemUuid, accessToken, refreshToken, email, userId));
    }, [accessToken, dispatch, itemUuid, userId, refreshToken, email]);

    useEffect(() => {
        if (permissions) {
            setServices(
                Object.entries(permissions).reduce(
                    (accumulator, [serviceName, enabled]) => {
                        if (enabled) {
                            accumulator.push(serviceName);
                        }
                        return accumulator;
                    },
                    [],
                ),
            );
        }
    }, [permissions]);

    useEffect(() => {
        if (service && service.trim().length > 0) {
            if (service.toUpperCase() === "SIGNATURE") {
                dispatch(getDocumentTypes(itemId, accessToken, userId,  refreshToken, email));
            }
            if (service.toUpperCase() === "INVOICING") {
                dispatch(getConnectedItems(service, itemId, accessToken, userId, refreshToken, email));
            }
            dispatch(getSubscriptionsByService(itemUuid, userId, accessToken, service, refreshToken, email));
        }
    }, [accessToken, dispatch, itemId, itemUuid, userId, service, refreshToken, email]);

    useEffect(() => {
        if (company && company.item) {
            setItemName(company.item.base.details.description 
                || `${company.item.base.details.firstName} ${company.item.base.details.lastName}`);
            items.push({
                "key": company.item.base.id,
                "fiscalCode": company.item.base.identifier.taxId,
                "vatNumber": company.item.base.identifier.vatNumber,
                "value": company.item.base.details.description
            });
        }
    }, [company, items]);

    const itemsLoaded = useSelector(state => state.items.loaded);

    useEffect(() => {
            if (itemsLoaded === 0) return;
            if (itemsLoaded === 1 && (service.toUpperCase() === "INVOICING")) {
                let filteredItemFiscalCode = "";
                if (filteredItem) {
                    const itemFiltered = items.filter(item => item.key === filteredItem.datasourceKey);
                    if (itemFiltered && itemFiltered.length > 0) {
                        filteredItemFiscalCode = itemFiltered[0].fiscalCode;
                    }
                }
                dispatch(
                    getStatistics(
                        itemId,
                        userId,
                        accessToken,
                        service,
                        statisticsPage,
                        10,
                        null,
                        null,
                        filteredItem && filteredItem.datasourceKey ?
                            filteredItem.datasourceKey + "," + filteredItemFiscalCode : null,
                        statisticsSorting,
                        false,
                        refreshToken,
                        email,
                        items
                    ),
                );
            }
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            accessToken,
            itemId,
            service,
            filteredItem,
            statisticsSorting,
            userId,
            refreshToken,
            email,
            items,
            itemsLoaded
        ]);

    const handleChangeTab = useCallback((selectedTabIndex) => {
        dispatch(getSubscriptionsByService(itemUuid, userId, accessToken, service, refreshToken, email));
        if (service.toUpperCase() === "SIGNATURE") {
            dispatch(getDocumentTypes(itemId, accessToken, userId,  refreshToken, email));
        }
        setTabsIndex(selectedTabIndex);
        history.replace('/');
    }, [history, accessToken, dispatch, email, itemId, itemUuid, refreshToken, service, userId]);

    const handleGoHome = useCallback(() => {
        history.replace('/');
    }, [history]);

    const handleGoBack = useCallback(() => {
        history.goBack();
    }, [history]);

    return (
        <>
            <Switch>
                <Route path="/details">
                    <PageTitle
                        title={<FormattedMessage id="metering.detail.title" />}
                        subtitle={<FormattedMessage id="metering.subtitle" />}
                        showOnBackLabel={false}
                        onBack={handleGoHome}
                    />
                </Route>
                <PageTitle
                    title={<FormattedMessage id="metering.title" />}
                    subtitle={<FormattedMessage id="metering.subtitle" />}
                    showOnBackLabel={false}
                    onBack={handleGoBack}
                />
            </Switch>
            <StyledStandardTabs activeIndex={tabsIndex} onChange={handleChangeTab}>
                {services.map((service) => (
                    <StyledTab key={service}>
                        <FormattedMessage id={`tabs.services.${service}`} />
                    </StyledTab>
                ))}
            </StyledStandardTabs>
            {
                permissionsLoading ||
                itemsLoading ||
                subscriptionsLoading ||
                statisticsLoading ? (
                    <UniversalSpinner
                        open={permissionsLoading ||
                            itemsLoading ||
                            subscriptionsLoading ||
                            statisticsLoading}
                    />
                ) : (
                    <TabContent>
                        <Switch>
                            <Route path="/details">
                                <Details
                                    itemId={itemId}
                                    itemUuid={itemUuid}
                                    items={items}
                                    userId={userId}
                                    email={email}
                                    accessToken={accessToken}
                                    packages={subscriptions[service]}
                                    refreshToken={refreshToken}
                                    service={service}
                                    itemName={itemName}
                                    studio={studio}
                                />
                            </Route>
                            <Home
                                company={company}
                                service={service}
                                packages={subscriptions[service]}
                                itemId={itemId}
                                itemUuid={itemUuid}
                                userId={userId}
                                email={email}
                                accessToken={accessToken}
                                refreshToken={refreshToken}
                                itemName={itemName}
                                items={items}
                                studio={studio}
                                filteredItem={filteredItem}
                                setFilteredItem={setFilteredItem}
                                statisticsPage={statisticsPage}
                                setStatisticsPage={setStatisticsPage}
                                statisticsSorting={statisticsSorting}
                                setStatisticsSorting={setStatisticsSorting}
                            />
                        </Switch>
                    </TabContent>
                )
            }
        </>
    );
};

App.propTypes = {
    itemId: PropTypes.string.isRequired,
    accessToken: PropTypes.string.isRequired,
};
