import {
    GAINSIGHT_VIEW_HOME,
    GAINSIGHT_VIEW_DETAILS_FROM_PACKAGE,
    GAINSIGHT_VIEW_DETAILS_FROM_ITEM,
    GAINSIGHT_EXPORT_CSV_STATISTICS,
    GAINSIGHT_EXPORT_CSV_DETAILS,
    GAINSIGHT_CHANGE_DATE_FILTER_DETAILS,
    GAINSIGHT_VIEW_OLD_BILLING_CYCLES_DETAILS,
    GAINSIGHT_FILTER_DETAILS_BY_ITEM
} from "../actions/gainsightEvents";

export const trackEvents = () => next => action => {
    const track = window.aptrinsic
        ? (event, details) => window.aptrinsic("track", `PORTALE_CONSUMPTIONS_DASHBOARD:${event}`, details)
        : () => null;

    switch (action.type) {
        case GAINSIGHT_VIEW_HOME:
            track("VIEW_HOME", action.details);
            break;
        case GAINSIGHT_VIEW_DETAILS_FROM_PACKAGE:
            track("VIEW_DETAILS_FROM_PACKAGE", action.details);
            break;
        case GAINSIGHT_VIEW_DETAILS_FROM_ITEM:
            track("VIEW_DETAILS_FROM_ITEM", action.details);
            break;
        case GAINSIGHT_EXPORT_CSV_STATISTICS:
            track("EXPORT_CSV_STATISTICS", action.details);
            break;
        case GAINSIGHT_EXPORT_CSV_DETAILS:
            track("EXPORT_CSV_DETAILS", action.details);
            break;
        case GAINSIGHT_CHANGE_DATE_FILTER_DETAILS:
            track("CHANGE_DATE_FILTER_DETAILS", action.details);
            break;
        case GAINSIGHT_VIEW_OLD_BILLING_CYCLES_DETAILS:
            track("VIEW_OLD_BILLING_CYCLES_DETAILS", action.details);
            break;
        case GAINSIGHT_FILTER_DETAILS_BY_ITEM:
            track("FILTER_DETAILS_BY_ITEM", action.details);
            break;
        default: {
            break;
        }
    }

    return next(action);
};
