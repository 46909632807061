import React from "react";
import PropTypes from "prop-types";

import { Root, Content, Icon } from "./styled";

export const FloatingActionButton = ({
    children,
    disabled,
    faIcon,
    ...rest
}) => {
    return (
        <Root disabled={disabled} {...rest}>
            <Content>
                {faIcon ? <Icon icon={faIcon} /> : ""} {children}
            </Content>
        </Root>
    );
};

FloatingActionButton.propTypes = {
    children: PropTypes.node,
    disabled: PropTypes.bool,
};
