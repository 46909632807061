import { getRestCall } from "../../../utils/rest-api-call";
import { SIGNATURE_READ_API_URL } from "../../../env";

export const GET_SIGNATURE_DOCUMENT_TYPES_START = "GET_SIGNATURE_DOCUMENT_TYPES_START";
export const GET_SIGNATURE_DOCUMENT_TYPES_END = "GET_SIGNATURE_DOCUMENT_TYPES_END";
export const GET_SIGNATURE_DOCUMENT_TYPES_SUCCESS = "GET_SIGNATURE_DOCUMENT_TYPES_SUCCESS";

export const getDocumentTypes = (item, accessToken, userId, refreshToken, email) => async (dispatch) => {
    dispatch({ type: GET_SIGNATURE_DOCUMENT_TYPES_START });
    try {
        const response = await getRestCall(
            `${SIGNATURE_READ_API_URL}/api/v1/documentTypes`,
            accessToken,
            null,
            dispatch,
            userId,
            item,
            refreshToken,
            email
        );
        dispatch({
            type: GET_SIGNATURE_DOCUMENT_TYPES_SUCCESS,
            documentTypes: response,
        });
    } catch (error) {
        console.error("error getting document documentTypes", error);
    } finally {
        dispatch({ type: GET_SIGNATURE_DOCUMENT_TYPES_END });
    }
};