import axios from "axios";
import uuidV4 from "uuid/dist/v4";
import flatten from "flat";

import { refreshToken } from "./auth-utils";

async function performCall(url, token, method, urlParams, body, userId, itemId, userLanguage = "it-IT", appId, contentType = "application/json") {
    let queryString = getQueryString(urlParams);

    queryString = queryString !== "" ? `?${queryString}` : "";

    const headers = {
        Authorization: `Bearer ${token ? token : ""}`,
        "Content-Type": contentType,
        "X-App-Name": "METERING_DASHBOARD",
        "X-App-Version": "1.0",
        "X-Request-Id": uuidV4(),
        "X-Correlation-Id": uuidV4(),
        "X-Forwarded-For": uuidV4()
    };

    if (appId) {
        headers["X-App-Id"] = appId;
    }

    if (userId) {
        headers["X-User-ID"] = userId;
    } else {
        headers["X-User-ID"] = 'metering';
    }

    if (itemId) {
        headers["X-Item-ID"] = itemId;
    }

    if (userLanguage) {
        headers["Accept-Language"] = userLanguage;
    }

    return await axios({
        url: `${url}${queryString}`,
        method: method,
        data: body && JSON.stringify(body),
        responseType: contentType === "application/octet-stream" ? 'blob' : "application/json",
        headers
    })
        .then(response => {
            return { ...response, ok: true };
        })
        .catch(error => {
            return { ...error.response, ok: false };
        });
}

export async function restApiCall(
    url,
    accessToken,
    method,
    urlParams,
    body,
    dispatch,
    userId,
    itemId,
    refresh_token,
    email,
    appId,
    contentType
) {
    let response = await performCall(
        url,
        accessToken,
        method,
        urlParams,
        body,
        userId,
        itemId,
        null,
        appId,
        contentType
    );

    if (!response.ok) {
        if (response.status === 401) {
            let token = "";
            try {
                token = await refreshToken(refresh_token, email, dispatch);
            } catch (e) {
                return;
            }
            response = await performCall(url, token, method, urlParams, body);
        }
    }

    return response.data;
}

export function headRestCall(
    url,
    accessToken,
    urlParams,
    dispatch,
    userId,
    itemId
) {
    return restApiCall(
        url,
        accessToken,
        "HEAD",
        urlParams,
        null,
        dispatch,
        userId,
        itemId
    );
}

export function getRestCall(
    url,
    accessToken,
    urlParams,
    dispatch,
    userId,
    itemId,
    refreshToken,
    email,
    appId,
    contentType
) {
    return restApiCall(
        url,
        accessToken,
        "GET",
        urlParams,
        null,
        dispatch,
        userId,
        itemId,
        refreshToken,
        email,
        appId,
        contentType
    );
}

export function postRestCall(
    url,
    accessToken,
    urlParams,
    body,
    dispatch,
    userId,
    itemId,
    refreshToken,
    email,
    appId
) {
    return restApiCall(
        url,
        accessToken,
        "POST",
        urlParams,
        body,
        dispatch,
        userId,
        itemId,
        refreshToken,
        email,
        appId
    );
}

export function deleteRestCall(
    url,
    accessToken,
    urlParams,
    body,
    dispatch,
    userId,
    itemId
) {
    return restApiCall(
        url,
        accessToken,
        "DELETE",
        urlParams,
        body,
        dispatch,
        userId,
        itemId
    );
}

export function patchRestCall(
    url,
    accessToken,
    urlParams,
    body,
    dispatch,
    userId,
    itemId
) {
    return restApiCall(
        url,
        accessToken,
        "PATCH",
        urlParams,
        body,
        dispatch,
        userId,
        itemId
    );
}

export function putRestCall(
    url,
    accessToken,
    urlParams,
    body,
    dispatch,
    userId,
    itemId
) {
    return restApiCall(
        url,
        accessToken,
        "PUT",
        urlParams,
        body,
        dispatch,
        userId,
        itemId
    );
}

/**
 * Trasform a nested object in a query string
 * @param params nested object
 */
function getQueryString(params) {
    return params
        ? Object.entries(flatten(params, { safe: true }))
            .filter(([_, value]) => value !== undefined && value !== null)
            .map(key => `${key[0]}=${encodeURIComponent(key[1] + "")}`)
            .join("&")
        : "";
}
