import React from "react";
import PropTypes from "prop-types";
import { RootContainer } from "./styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/pro-regular-svg-icons";

export const UniversalSpinner = ({ open }) => {
    return (
        <RootContainer open={open} justifyContent="center" alignItems="center">
            <FontAwesomeIcon icon={faCircleNotch} spin={open} />
        </RootContainer>
    );
};

UniversalSpinner.propTypes = {
    open: PropTypes.bool,
};
