import React from "react";
import PropTypes from "prop-types";
import { Flex, Box } from "reflexbox";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const RootFlex = styled(Flex)`
    background: ${(props) =>
        props.selected ? "rgb(185, 230, 246)" : "rgb(216, 216, 217)"};
    border-radius: 16px;
    color: rgb(90, 104, 114);
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    border: 1px solid
        ${(props) => (props.selected ? "#0090D1" : "rgb(216, 216, 217)")};
    box-sizing: border-box;
    cursor: pointer;
    transition: background 0.3s ease, border 0.3s ease;
    :hover {
        background: rgb(185, 230, 246);
        border: solid 1px
            ${(props) => (props.selected ? "#0090D1" : "rgb(185, 230, 246)")};
    }
`;

const Icon = styled(FontAwesomeIcon)`
    color: rgb(90, 104, 114);
    font-size: 12px;
`;

export const Chip = ({ selected, label, onClick, faIcon }) => {
    return (
        <RootFlex
            px="16px"
            py="2px"
            selected={selected}
            onClick={onClick}
            alignItems="center"
        >
            {faIcon && (
                <Box mr="8px">
                    <Icon icon={faIcon} />
                </Box>
            )}
            <Box>{label}</Box>
        </RootFlex>
    );
};

Chip.propTypes = {
    selected: PropTypes.bool,
    label: PropTypes.node.isRequired,
    onClick: PropTypes.func.isRequired,
    faIcon: PropTypes.object,
};
