import styled, { css } from "styled-components";
import { Flex, Box } from "reflexbox";

export const RootContainer = styled(Flex)`
    box-shadow: 0px 2px 8px 0px rgba(21, 41, 53, 0.32);
    width: 300px;
    background: #fff;
`;

export const NextPreviousIcon = styled(Box)`
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(0, 144, 209);
    cursor: pointer;
`;

export const WeekdayBox = styled(Box)`
    color: rgb(90, 104, 114);
    font-family: Cairo;
    font-size: 16px;
    font-weight: 700;
    height: 16px;
    width: 16px;
    line-height: 16px;
    text-align: center;
    box-sizing: initial !important;
`;

export const DayBox = styled(Box)`
    position: relative;
    color: ${(props) => (props.disabled ? "#D8D8D9" : "rgb(21, 41, 53)")};
    font-family: Roboto;
    font-size: 14px;
    font-weight: 700;
    height: 22px;
    width: 22px;
    line-height: 22px;
    text-align: center;
    box-sizing: initial !important;
    ${(props) => props.valid && "cursor: pointer"};
    background: ${(props) => {
        return props.selected && !props.rangeStart && !props.rangeEnd
            ? "rgb(222, 240, 247)"
            : "#fff";
    }};
    transition: color 0.3s ease;
    ${(props) =>
        !props.rangeStart &&
        !props.rangeEnd &&
        !props.disabled &&
        css`
            :hover {
                color: rgb(0, 144, 209);
            }
        `}
`;

export const AbsoluteRangeLimitIndicator = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 22px;
    height: 22px;
    background: linear-gradient(
        -26.56505117707799deg,
        rgb(0, 195, 234) 0%,
        rgb(0, 144, 209) 100%
    );
    ${(props) => {
        if (props.roundBorders) {
            return css`
                border-radius: 8px;
            `;
        }
        if (props.start) {
            return css`
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
            `;
        } else if (props.end) {
            return css`
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
            `;
        }
    }};
    color: rgb(255, 255, 255);
    font-family: Roboto;
    font-size: 14px;
    font-weight: bold;
    line-height: 22px;
    text-align: center;
    z-index: 1;
`;

export const AbsoluteRangeLimitBackground = styled.div`
    position: absolute;
    ${(props) =>
        props.end &&
        css`
            left: 0;
        `};
    ${(props) =>
        props.start &&
        css`
            right: 0;
        `};
    top: 0;
    height: 100%;
    width: 12px;
    background: rgb(222, 240, 247);
`;
