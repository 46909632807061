import { combineReducers } from "redux";
import { subscriptionsReducer } from "./subscriptions";
import { permissionsReducer } from "./permissions";
import { detailsReducer } from "./details";
import { statisticsReducer } from "./statistics";
import { itemsReducer } from "./items";
import { companyReducer } from "./company";
import { flowsReducer } from "./flows";
import { documentTypesReducer } from "./signature/documentTypes";
import { filesReducer } from "./files";

export const reducers = combineReducers({
    company: companyReducer,
    permissions: permissionsReducer,
    subscriptions: subscriptionsReducer,
    details: detailsReducer,
    statistics: statisticsReducer,
    items: itemsReducer,
    flows: flowsReducer,
    documentTypes: documentTypesReducer,
    historyDetails: filesReducer
});
