import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import { Box, Flex } from "reflexbox";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { CONSUMPTION_DASHBOARD_URL } from "../../env";
import rapidViewIllustration from "../../images/rapid-view-illustration.svg";
import { useSelector } from "react-redux";


const TitleText = styled(Box)`
    color: rgb(0, 80, 117);
    font-family: Cairo;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    text-transform: uppercase;
`;

const CompanyNameText = styled(Box)`
    color: rgb(0, 80, 117);
    font-family: Cairo;
    font-size: 34px;
    line-height: 40px;
    font-weight: 600;
`;

const KPISquare = styled(Box)`
    width: 12px;
    height: 12px;
    border-radius: 2px;
    background: ${(props) => props.color}; 
`;

const KPILabel = styled(Box)`
    color: rgb(0, 80, 117);
    font-family: Cairo;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.11px;
    line-height: 24px;
    height: 24px;
    width: 100px;
`;

const KPIValue = styled(Box)`
    color: rgb(21, 41, 53);
    font-family: Roboto;
    font-size: 48px;
    font-weight: bold;
    width: 100%;
    height: 57px;
    line-height: 48px;
`;

const UsedTextBox = styled(Box)`
    color: rgb(157, 166, 173);
    font-family: Cairo;
    font-size: 12px;
    font-weight: normal;
    height: 16px;
    letter-spacing: 0px;
    width: 52px;
`;

const KPIContainer = styled(Flex)`
    padding: 5px;
    flex-wrap: wrap;
`;

const RootContainer = styled(Flex)`
    padding: 5px;
    padding-bottom: 30px;
    border-bottom: 1px solid rgb(201, 217, 232);
`;

const IllustationContainer = styled(Flex)`
    padding-right: 50px;
`;

const KpiCard = styled(Flex)`
    box-shadow: 0px 2px 8px 0px rgba(21, 41, 53, 0.32);
    width: 160px;
    min-width: 160px;
    height: 160px;
`;

const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });

    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }

        // Add event listener
        window.addEventListener("resize", handleResize);

        // Call handler right away so state gets updated with initial window size
        handleResize();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount

    return windowSize;
};

export const RapidView = ({
    companyName
}) => {

    const windowSize = useWindowSize();

    const { docsSubscriptions, timestampSubscriptions, massiveSignatureSubscriptions, qualifiedSignatureSubscriptions  } =
        useSelector((state) => ({
            docsSubscriptions: state.subscriptions.data.signature &&
                state.subscriptions.data.signature.filter((s) => s.constraint  === "SIGNABLE_DOCUMENTS"),
            timestampSubscriptions: state.subscriptions.data.signature &&
                state.subscriptions.data.signature.filter((s) => s.constraint  === "TIMESTAMPS"),
            massiveSignatureSubscriptions: state.subscriptions.data.signature &&
                state.subscriptions.data.signature.filter((s) => s.constraint  === "MASSIVE_SIGNATURES"),
            qualifiedSignatureSubscriptions: state.subscriptions.data.signature &&
                state.subscriptions.data.signature.filter((s) => s.constraint  === "QUALIFIED_SIGNATURES")
        }));

    let numberOfDocuments = 0;
    let numberOfFeqAuto = 0;
    let numberOfFeqRemote = 0;
    let numberOfSMS = 0;
    let numberOfTimestamps = 0;

    if (docsSubscriptions) {
        docsSubscriptions.forEach((s) => numberOfDocuments += s.regularUsage + s.extraUsage);
        docsSubscriptions.forEach((s) =>
            {
                if (s.SMS) {
                    numberOfSMS += s.SMS.regularUsage + s.SMS.extraUsage;
                }
            });
        docsSubscriptions.forEach((s) =>
            {
                if (s.MASSIVE_SIGNATURES) {
                    numberOfFeqAuto += s.MASSIVE_SIGNATURES.regularUsage + s.MASSIVE_SIGNATURES.extraUsage;
                }
            }
        );
        docsSubscriptions.forEach((s) =>
            {
                if (s.QUALIFIED_SIGNATURES) {
                    numberOfFeqRemote += s.QUALIFIED_SIGNATURES.regularUsage + s.QUALIFIED_SIGNATURES.extraUsage;
                }
            }
        );
    }
    if (timestampSubscriptions) {
        timestampSubscriptions.forEach((s) => numberOfTimestamps += s.regularUsage + s.extraUsage);
    }
    if (massiveSignatureSubscriptions) {
        massiveSignatureSubscriptions.forEach((s) => numberOfFeqAuto += s.regularUsage + s.extraUsage);
    }
    if (qualifiedSignatureSubscriptions) {
        qualifiedSignatureSubscriptions.forEach((s) => numberOfFeqRemote += s.regularUsage + s.extraUsage);
    }

    return (
        <RootContainer>
        <Flex 
            flexWrap='wrap'
            width="100%"
            mb="20px"
        >
            <Flex
                flexDirection="column"
                width="100%"
            >
                <TitleText mb="8px">
                    <FormattedMessage id="rapid.view.title" />
                </TitleText>
                <CompanyNameText mb="24px">{companyName}</CompanyNameText>
            </Flex>
            <KPIContainer width="100%">
                <KpiCard flexWrap='wrap' mr="36px" mb="36px">
                    <KPIValue ml="15px" mt="15px">{numberOfDocuments}</KPIValue>
                    <KPISquare ml="15px" color={"#0d6c80"} />
                    <KPILabel ml="8px" mt="-6px"><FormattedMessage id="rapid.view.documents" /></KPILabel>
                    <UsedTextBox ml="35px" pt="4px">
                        <FormattedMessage id="package.card.total.used" />
                    </UsedTextBox>
                </KpiCard>
                <KpiCard flexWrap='wrap' mr="36px" mb="36px">
                    <KPIValue ml="15px" mt="15px">{numberOfFeqAuto}</KPIValue>
                    <KPISquare ml="15px" color={"#00aa00"} />
                    <KPILabel ml="8px" mt="-6px"><FormattedMessage id="rapid.view.feqauto" /></KPILabel>
                    <UsedTextBox ml="35px" pt="4px">
                        <FormattedMessage id="package.card.total.used" />
                    </UsedTextBox>
                </KpiCard>
                <KpiCard flexWrap='wrap' mr="36px" mb="36px">
                    <KPIValue ml="15px" mt="15px">{numberOfFeqRemote}</KPIValue>
                    <KPISquare ml="15px" color={"#753f83"} />
                    <KPILabel ml="8px" mt="-6px"><FormattedMessage id="rapid.view.feqremote" /></KPILabel>
                    <UsedTextBox ml="35px" pt="4px">
                        <FormattedMessage id="package.card.total.used" />
                    </UsedTextBox>
                </KpiCard>
                <KpiCard flexWrap='wrap' mr="36px" mb="36px">
                    <KPIValue ml="15px" mt="15px">{numberOfSMS}</KPIValue>
                    <KPISquare ml="15px" color={"#361d5b"} />
                    <KPILabel ml="8px" mt="-6px"><FormattedMessage id="rapid.view.sms" /></KPILabel>
                    <UsedTextBox ml="35px" pt="4px">
                        <FormattedMessage id="package.card.total.used" />
                    </UsedTextBox>
                </KpiCard>
                <KpiCard flexWrap='wrap' mr="36px" mb="36px">
                    <KPIValue ml="15px" mt="15px">{numberOfTimestamps}</KPIValue>
                    <KPISquare ml="15px" color={"#4763ae"} />
                    <KPILabel ml="8px" mt="-6px"><FormattedMessage id="rapid.view.timestamps" /></KPILabel>
                    <UsedTextBox ml="35px" pt="4px">
                        <FormattedMessage id="package.card.total.used" />
                    </UsedTextBox>
                </KpiCard>
            </KPIContainer>
        </Flex>
        { windowSize.width >= 1400 ?
            (
                <IllustationContainer>
                    <img alt="illustration" src={rapidViewIllustration && CONSUMPTION_DASHBOARD_URL + "/static/media/rapid-view-illustration.bcf0bc1e.svg"} />
                </IllustationContainer>
            ) : null
        }
        </RootContainer>
    );
}; 

RapidView.propType = {
    companyName: PropTypes.string.isRequired,
    numberOfDocuments: PropTypes.number.isRequired,
    numberOfFeqAuto: PropTypes.number.isRequired,
    numberOfFeqRemote: PropTypes.number.isRequired,
    numberOfSMS: PropTypes.number.isRequired,
    numberOfTimestamps: PropTypes.number.isRequired
};