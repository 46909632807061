import styled from "styled-components";
import { applyGradient } from "../../commons/styles/mixins";
import { Flex } from "reflexbox";

const sizeMultipliers = {
    small: 4,
    medium: 5,
    large: 6,
};

export const ElevatedFlex = styled(Flex)`
    z-index: 1;
`;

const StyledButton = styled.button`
    align-items: center;
    justify-content: center;
    position: relative;
    display: inline-flex;
    padding: 0 ${(props) => props.theme.spacing.unit * 2}px;
    border-radius: 2px;
    border: 0px;
    outline: none;
    cursor: pointer;
    transition: background 0.3s ease, color 0.3s ease;
    height: ${(props) =>
        props.theme.spacing.unit * sizeMultipliers[props.size]}px;
`;

const GradientButton = styled(StyledButton)`
    &:before {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 2px;
        content: "";
        opacity: 0;
        transition: opacity 0.3s ease;
    }
    & > span {
        position: relative;
        z-index: 1;
    }
`;

export const AlertPrimaryButton = styled(GradientButton)`
    ${(props) =>
        applyGradient(
            props.theme.colors.status.maximumRed,
            props.theme.colors.accent.rustyRed,
        )};
    background-color: ${(props) => props.theme.colors.status.rustyRed};
    color: ${(props) => props.theme.colors.background.white};
    &:before {
        background: ${(props) => props.theme.colors.status.maximumRed};
        z-index: 0;
    }
    &:hover:before {
        opacity: 1;
    }
    &:active {
        text-shadow: 1px 1px 0 rgba(184, 0, 0, 0.48);
    }
`;

export const AlertSecondaryButton = styled(StyledButton)`
    border: solid 1px ${(props) => props.theme.colors.status.rustyRed};
    color: ${(props) => props.theme.colors.accent.rustyRed};
    background: ${(props) => props.theme.colors.background.white};
    &:hover {
        background: ${(props) => props.theme.colors.accent.rustyRed};
        color: ${(props) => props.theme.colors.background.white};
    }
`;

export const AlertTertiaryButton = styled(StyledButton)`
    color: ${(props) => props.theme.colors.accent.rustyRed};
    background: none;
    border: none;
    &:hover {
        background: ${(props) => props.theme.colors.background.whiteSmoke};
    }
`;

export const DefaultPrimaryButton = styled(GradientButton)`
    ${(props) =>
        applyGradient(
            props.theme.colors.primary.cyanProgress,
            props.theme.colors.primary.richElectricBlue,
        )};
    color: ${(props) => props.theme.colors.background.white};
    text-shadow: 1px 1px 0 rgba(21, 41, 53, 0.24);
    &:before {
        background: ${(props) => props.theme.colors.primary.cyanProgress};
    }
    &:not(:disabled):hover:before {
        opacity: 1;
    }
    &:active {
        text-shadow: 1px 1px 0 rgba(21, 41, 53, 0.48);
    }
    &:disabled {
        text-shadow: none;
        background-image: none;
        background-color: ${(props) => props.theme.colors.primary.columbiaBlue};
        cursor: not-allowed;
    }
`;

export const DefaultSecondaryButton = styled(StyledButton)`
    border: solid 1px ${(props) => props.theme.colors.primary.richElectricBlue};
    color: ${(props) => props.theme.colors.primary.richElectricBlue};
    background: ${(props) => props.theme.colors.background.white};
    &:hover {
        background: ${(props) => props.theme.colors.primary.richElectricBlue};
        color: ${(props) => props.theme.colors.background.white};
    }
    &:disabled {
        background: ${(props) => props.theme.colors.background.white};
        color: ${(props) => props.theme.colors.primary.columbiaBlue};
        border-color: ${(props) => props.theme.colors.primary.columbiaBlue};
        cursor: not-allowed;
    }
`;

export const DefaultTertiaryButton = styled(StyledButton)`
    color: ${(props) => props.theme.colors.primary.richElectricBlue};
    background: none;
    border: none;
    &:hover {
        background: ${(props) => props.theme.colors.background.whiteSmoke};
    }
    &:disabled {
        background: none;
        color: ${(props) => props.theme.colors.primary.columbiaBlue};
        cursor: not-allowed;
    }
`;

export const GhostPrimaryButton = styled(StyledButton)`
    background: ${(props) => props.theme.colors.background.white};
    color: ${(props) => props.theme.colors.primary.richElectricBlue};
    &:hover {
        background: ${(props) => props.theme.colors.secondary.diamond};
    }
    &:disabled {
        color: ${(props) => props.theme.colors.primary.columbiaBlue};
        cursor: not-allowed;
        background: ${(props) => props.theme.colors.background.white};
    }
`;

export const GhostSecondaryButton = styled(StyledButton)`
    border: 1px solid ${(props) => props.theme.colors.primary.richElectricBlue};
    color: ${(props) => props.theme.colors.primary.richElectricBlue};
    background: none;
    &:hover {
        background: ${(props) => props.theme.colors.background.white};
        color: ${(props) => props.theme.colors.primary.richElectricBlue};
    }
    &:disabled {
        border: 1px solid ${(props) => props.theme.colors.primary.columbiaBlue};
        background: none;
        color: ${(props) => props.theme.colors.primary.columbiaBlue};
        cursor: not-allowed;
    }
`;

export const GhostTertiaryButton = styled(StyledButton)`
    border: transparent;
    color: ${(props) => props.theme.colors.background.white};
    background: none;
    &:hover {
        background: ${(props) => props.theme.colors.primary.darkImperialBlue};
        border: ${(props) => props.theme.colors.primary.darkImperialBlue};
        color: ${(props) => props.theme.colors.background.white};
    }
    &:disabled {
        border: none;
        background: none;
        color: ${(props) => props.theme.colors.primary.columbiaBlue};
        cursor: not-allowed;
    }
`;
