import React from "react";
import { Flex, Box } from "reflexbox";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import {StandAloneRootFlex, UsageConsumptionsTextBox, UsedTextBox} from "../../styled";

const FamilySquare = styled(Box)`
    width: 12px;
    height: 12px;
    border-radius: 2px;
    background: ${(props) => props.color};
`;

const FamilyLabel = styled(Box)`
    height: 24px;
    color: rgb(0, 80, 117);
    font-size: 16px;
    font-family: Cairo;
    font-weight: 600;
    letter-spacing: 0.11px;
    line-height: 24px;
    width: 120px;
    margin-left: 10px;
`;

const ContentValue = styled(Box)`
    height: 42px;
    color: rgb(21, 41, 53);
    font-size: 36px;
    font-family: Roboto;
    font-weight: bold;
`;

export const StandAloneCard = ({
    constraint,
    capacity,
    extraUsage,
    regularUsage    
}) => {
    
    return (
        <StandAloneRootFlex>
            <Flex flexWrap='wrap' width="200px" height="50px" ml="20px" mt="20px">
                <FamilySquare mr="8px" mt="5px" color={constraint === "GRAPHOMETRIC_SIGNATURES"
                    ? "#fc4e3d" : constraint === "QUALIFIED_SIGNATURES" ? "#753f83"
                    : constraint === "MASSIVE_SIGNATURES" ? "#00aa00" : ""} />
                <FamilyLabel>
                    {constraint === "GRAPHOMETRIC_SIGNATURES" ? (
                        <FormattedMessage id="package.card.grafometrica" />
                    ) : constraint === "QUALIFIED_SIGNATURES" ? (
                        <FormattedMessage id="package.card.feqremota" />
                    ) : constraint === "MASSIVE_SIGNATURES" ? (
                        <FormattedMessage id="package.card.feqauto" />
                    ) : ""}                    
                </FamilyLabel>
                <ContentValue mt="20px">
                {constraint === "GRAPHOMETRIC_SIGNATURES" ? (
                        <FormattedMessage id="package.card.grafometrica.value" />
                    ) : (
                        <Flex flexWrap='wrap'>
                            <UsageConsumptionsTextBox pr="200px"
                                {...(regularUsage + extraUsage) > capacity ? "extra" : null}>
                                { (regularUsage + extraUsage) + "/" + capacity}
                            </UsageConsumptionsTextBox>
                            <UsedTextBox mt="20px">
                                <FormattedMessage id="package.card.total.used" />
                            </UsedTextBox>
                        </Flex>                        
                    )}
                </ContentValue>
            </Flex>        
        </StandAloneRootFlex>
    );
};