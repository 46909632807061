export const getSanitizedQueryString = (params) =>
    Object.entries(params)
        .filter(([name, value]) => value !== null && value !== undefined)
        .map(([name, value]) =>
            {
                if (Array.isArray(value)) {
                    return value.map(v => `${name}=${encodeURIComponent(v)}`).join("&");
                } else {
                    return `${name}=${encodeURIComponent(value)}`;
                }
            })
        .join("&");

export function downloadBase64(base64, filename) {
    if (window.navigator.msSaveOrOpenBlob) {
        // Store Blob in IE
        fetch("data:application/pdf;base64," + base64)
            .then((res) => res.blob())
            .then((blob) => window.navigator.msSaveOrOpenBlob(blob, filename));
    } else {
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.href = "data:application/pdf;base64," + base64;
        a.download = filename;
        a.click();
        a.remove();
    }
}

export function download(bytes, filename) {
    if (window.navigator.msSaveOrOpenBlob) {
        // Store Blob in IE
        fetch("data:application/zip;base64," + bytes)
            .then((res) => res.blob())
            .then((blob) => window.navigator.msSaveOrOpenBlob(blob, filename));
    } else {
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.href = "data:application/zip;base64," + bytes;
        a.download = filename;
        a.click();
        a.remove();
    }
}

export function downloadTextFile(filename, text) {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
}

export function downloadStreamBase64(base64, filename) {
    if (window.navigator.msSaveOrOpenBlob) {
        // Store Blob in IE
        fetch(base64)
            .then((res) => res.blob())
            .then((blob) => window.navigator.msSaveOrOpenBlob(blob, filename));
    } else {
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.href = base64;
        a.download = filename;
        a.click();
        a.remove();
    }
}

export function getAppIdFromService (service) {
    switch (service.toUpperCase()) {
        case "INVOICING":
            return "EIP";
        case "SIGNATURE":
            return "SIG";
        case "CHECKUP":
            return "CRAM";
        case "ECOBONUS":
            return "NIUMABONUS";
        default:
            return "";
    }
}

export function getSyncConstraintsFromService (service) {
    switch (service.toUpperCase()) {
        case "SIGNATURE":
            return ["QUALIFIED_SIGNATURES", "MASSIVE_SIGNATURES", "GRAPHOMETRIC_SIGNATURES"];
        case "ECOBONUS":
            return ["LOW_VALUE_FILES", "HIGH_VALUE_FILES"];
        case "PAY":
            return ["TSPAY_COMPANIES"];
        case "AI":
            return ["AI_COMPANIES"];
        default:
            return [];
    }
}
