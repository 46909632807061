import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const StyledIcon = styled(FontAwesomeIcon)`
    color: ${({selected}) => (selected ? 'rgb(0, 80, 117)' : 'rgb(90, 104, 114)')};
    font-family: FontAwesome5Pro-Regular;
    font-size: 14px;
    font-weight: normal;
    height: 24px;
    width: 30px;
    letter-spacing: 0px;
    line-height: 24px;
    margin-left: 40px;
    margin-top: 5px;
`;
export const StyledLabel = styled.div`
    color: ${({selected}) => (selected ? 'rgb(0, 80, 117)' : 'rgb(90, 104, 114)')};
    font-size: 14px;
    font-family: Cairo;
    font-weight: 600;
    text-align: center;
    line-height: 40px;
    margin-left: 10px;
`;

const Container = styled.div`
    display: flex;
    cursor: pointer;
`;

const LabelContainer = styled.div`
    display: inline-flex;
    width: 200px;
    height: 60px;
`;

const Option = styled.div`
    width: 204px;
    height: 40px;
    background: ${({selected}) => (selected ? 'rgb(185, 230, 246)' : 'rgb(216, 216, 217)')}; 
    border: ${({selected}) => (selected ? '1px solid rgb(0, 144, 209)' : '1px solid rgb(216, 216, 217)')};
    border-radius: ${({isFirst, isLast})=>(isFirst ? '20px 0px 0px 20px' : isLast ? '0px 20px 20px 0px' : '0px')};
`;

export const ContentSwitcher = ({
    activeKey,
    onChange,
    options
}) => (
    <Container>
        {
            options.map((opt) => {
                return (<Option
                    isFirst={opt.index === 0}
                    isLast={opt.index === options.length - 1}
                    key={opt.key}
                    selected={opt.key === activeKey}
                    onClick={onChange}
                >
                    <LabelContainer>
                        <StyledIcon icon={opt.icon} />
                        <StyledLabel>{opt.label}</StyledLabel>
                    </LabelContainer>
                </Option>);
            })
        }
    </Container>
);

ContentSwitcher.propTypes = {
    activeKey: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.array
};
