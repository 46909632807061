import {
    GET_DETAILS_START,
    GET_DETAILS_END,
    GET_DETAILS_SUCCESS,
    SORT_DETAILS_SUCCESS,
    RESET_DETAILS_FILTER
} from "../../actions/details";

const initialState = {
    loadings: 0,
    data: {
        size: 0,
        details: [],
    },
};

export const detailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_DETAILS_START: {
            return {
                ...initialState,
                loadings: 1,
            };
        }
        case GET_DETAILS_END: {
            return {
                ...state,
                loadings: 0,
            };
        }
        case GET_DETAILS_SUCCESS: {
            return {
                ...state,
                loadings: 0,
                data: {
                    size: action.size,
                    details: action.details,
                    billingCycleStartDate: action.billingCycleStartDate,
                    billingCycleEndDate: action.billingCycleEndDate,
                    activationDate: action.activationDate
                },
            };
        }
        case SORT_DETAILS_SUCCESS: {
            return {
                ...state,
                loadings: 0,
                data: {
                    details: action.details,
                    size: action.details.length,
                    filtered: action.filtered,
                    billingCycleStart: action.billingCycleStart,
                    billingCycleEnd: action.billingCycleEnd,
                    activationDate: action.activationDate
                },
            };
        }
        case RESET_DETAILS_FILTER: {
            return {
                ...state,
                loadings: 0,
                data: {
                    details: action.details,
                    size: action.details.length,
                    filtered: false,
                    billingCycleStart: action.billingCycleStart,
                    billingCycleEnd: action.billingCycleEnd,
                    activationDate: action.activationDate
                },
            };
        }
        default: {
            return state;
        }
    }
};
