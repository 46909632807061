import React from "react";
import { FormattedMessage } from "react-intl";
import moment from "moment";

export const getStatisticsTableColumnsSpecification = (
    service,
    selectAllCheckbox,
) => {
    switch (service) {
        case "invoicing": {
            return [
                {
                    key: "selected",
                    title: (
                        selectAllCheckbox
                    ),
                    dataKey: "selected",
                    width: "5%",
                },
                {
                    key: "fiscalCode",
                    sorting: "none",
                    sortingKey: "fiscalCode",
                    title: (
                        <FormattedMessage id="statistics.table.column.company.code" />
                    ),
                    dataKey: "fiscalCode",
                    width: "10%",
                },
                {
                    key: "name",
                    sorting: "none",
                    sortingKey: "company",
                    title: (
                        <FormattedMessage id="statistics.table.column.company.name" />
                    ),
                    dataKey: "company",
                    width: "20%",
                },
                {
                    key: "invoiceSentAmount",
                    sorting: "none",
                    sortingKey: "invoice_sent",
                    title: (
                        <FormattedMessage id="statistics.table.column.company.amount.invoice.sent" />
                    ),
                    dataKey: "invoice_sent",
                    width: "10%",
                },
                {
                    key: "invoiceReceivedAmount",
                    sorting: "none",
                    sortingKey: "invoice_recv",
                    title: (
                        <FormattedMessage id="statistics.table.column.company.amount.invoice.received" />
                    ),
                    dataKey: "invoice_recv",
                    width: "10%",
                },
                {
                    key: "orderSentAmount",
                    sorting: "none",
                    sortingKey: "order_sent",
                    title: (
                        <FormattedMessage id="statistics.table.column.company.amount.order.sent" />
                    ),
                    dataKey: "order_sent",
                    width: "10%",
                },
                {
                    key: "orderReceivedAmount",
                    sorting: "none",
                    sortingKey: "order_recv",
                    title: (
                        <FormattedMessage id="statistics.table.column.company.amount.order.received" />
                    ),
                    dataKey: "order_recv",
                    width: "10%",
                },
                {
                    key: "receiptReceivedAmount",
                    sorting: "none",
                    sortingKey: "receipt_recv",
                    title: (
                        <FormattedMessage id="statistics.table.column.company.amount.receipt.received" />
                    ),
                    dataKey: "receipt_recv",
                    width: "10%",
                },
                {
                    key: "totalAmount",
                    sorting: "none",
                    sortingKey: "total",
                    title: (
                        <FormattedMessage id="statistics.table.column.company.amount" />
                    ),
                    dataKey: "total",
                    width: "10%",
                },
                {
                    key: "actions",
                    align: "right",
                    title: (
                        <FormattedMessage id="statistics.table.column.company.actions" />
                    ),
                    dataKey: "actions",
                    width: "5%",
                },
            ];
        }
        case "signature": {
            return [
                {
                    key: "selected",
                    title: (
                        selectAllCheckbox
                    ),
                    dataKey: "selected"
                },
                {
                    key: "fiscalCode",
                    sorting: "none",
                    title: (
                        <FormattedMessage id="statistics.table.column.company.code" />
                    ),
                    dataKey: "key",
                },
                {
                    key: "name",
                    sorting: "none",
                    title: (
                        <FormattedMessage id="statistics.table.column.company.name" />
                    ),
                    dataKey: "company",
                }
            ];
        }
        default: {
            return [];
        }
    }
};

export const getDetailsTableColumnsSpecification = (
    service,
    selectAllCheckbox,
    flowSelect,
    companyNameInputText,
    fiscalCodeInputText,
    vatNumberInputText,
    hubIdInputText,
    slotDetails
) => {
    switch (service) {
        case "invoicing": {
            return [
                {
                    key: "selected",
                    title: (
                        selectAllCheckbox
                    ),
                    dataKey: "selected",
                    searchEnabled: true,
                    width: "5%",
                },
                {
                    key: "author",
                    sorting: "none",
                    sortingKey: "author",
                    title: (
                        <FormattedMessage id="details.table.column.author" />
                    ),
                    dataKey: "author",
                    searchEnabled: true,
                    width: "14%",
                },
                {
                    key: "timestamp",
                    sorting: "none",
                    sortingKey: "timestamp",
                    title: (
                        <FormattedMessage id="details.table.column.timestamp" />
                    ),
                    dataKey: "timestamp",
                    searchEnabled: true,
                    width: "14%",
                    render: (value) =>
                        {
                            const fmt = 'DD/MM/YYYY HH:mm:ss';
                            let m = moment(value);
                            m.local();
                            return m.format(fmt);
                        }
                },
                {
                    key: "hubId",
                    title: <FormattedMessage id="details.table.column.id" />,
                    dataKey: "hubId",
                    searchEnabled: true,
                    width: "25%",
                },
                {
                    key: "number",
                    sorting: "none",
                    sortingKey: "number",
                    title: <FormattedMessage id="details.table.column.number" />,
                    dataKey: "number",
                    searchEnabled: true,
                    width: "14%",
                },
                {
                    key: "type",
                    title: <FormattedMessage id="details.table.column.type" />,
                    dataKey: "sent",
                    searchEnabled: true,
                    width: "14%",
                    render: (value) => (
                        <FormattedMessage
                            id={`details.table.column.type.${
                                value ? "sent" : "received"
                            }`}
                        />
                    ),
                },
                {
                    key: "flow",
                    sorting: "none",
                    sortingKey: "flow",
                    title: <FormattedMessage id="details.table.column.flow" />,
                    dataKey: "flow",
                    filter: flowSelect,
                    searchEnabled: true,
                    width: "14%",
                },
            ];
        }
        case "signature": {
            return [
                {
                    key: "selected",
                    title: (
                        selectAllCheckbox
                    ),
                    dataKey: "selected",
                    width: "5%",
                },
                {
                    key: "owner",
                    title: (
                        <FormattedMessage id="details.table.column.owner" />
                    ),
                    dataKey: "owner",
                    width: "10%",
                },
                {
                    key: "timestamp",
                    sorting: "none",
                    sortingKey: "timestamp",
                    title: (
                        <FormattedMessage id="details.table.column.timestamp" />
                    ),
                    dataKey: "timestamp",
                    width: "10%",
                    render: (value) =>
                        {
                            const fmt = 'DD/MM/YYYY HH:mm:ss';
                            let m = moment(value);
                            m.local();
                            return m.format(fmt);
                        }
                },
                {
                    key: "documentTypeTitle",
                    sorting: "none",
                    sortingKey: "documentTypeTitle",
                    title: <FormattedMessage id="details.table.column.documentType" />,
                    dataKey: "documentTypeTitle",
                    width: "25%",
                },
                {
                    key: "hubId",
                    title: <FormattedMessage id="details.table.column.id" />,
                    dataKey: "hubId",
                    width: "15%",
                },
                {
                    key: "signaturesCount",
                    title: <FormattedMessage id="details.table.column.signaturesCount" />,
                    dataKey: "signaturesCount",
                    width: "10%",
                },
                {
                    key: "signersCount",
                    title: <FormattedMessage id="details.table.column.signersCount" />,
                    dataKey: "signersCount",
                    width: "10%",
                },
                {
                    key: "otpCount",
                    title: (
                        <FormattedMessage id="details.table.column.sms" />
                    ),
                    dataKey: "otpCount",
                    width: "5%",
                },
                {
                    key: "hasTimestamp",
                    title: (
                        <FormattedMessage id="details.table.column.hasTimestamp" />
                    ),
                    dataKey: "hasTimestamp",
                    width: "10%",
                }
            ];
        }
        case "checkup": {
            return [
                {
                    key: "selected",
                    title: (
                        selectAllCheckbox
                    ),
                    dataKey: "selected",
                    width: "5%",
                },
                {
                    key: "author",
                    title: (
                        <FormattedMessage id="details.table.column.author" />
                    ),
                    dataKey: "author",
                    width: "20%",
                },
                {
                    key: "companyName",
                    title: (
                        <FormattedMessage id="details.table.column.company.name" />
                    ),
                    dataKey: "companyName",
                    width: "40%",
                },
                {
                    key: "timestamp",
                    sorting: "none",
                    sortingKey: "timestamp",
                    title: (
                        <FormattedMessage id="details.table.column.timestamp" />
                    ),
                    dataKey: "timestamp",
                    width: "20%",
                    render: (value) =>
                        {
                            const fmt = 'DD/MM/YYYY HH:mm:ss';
                            let m = moment(value);
                            m.local();
                            return m.format(fmt);
                        }
                    }
            ];
        }
        case "pay": {
            return [
                {
                    key: "selected",
                    title: (
                        selectAllCheckbox
                    ),
                    dataKey: "selected",
                    width: "5%",
                    searchEnabled: true,
                },
                {
                    key: "companyName",
                    title: (
                        <FormattedMessage id="details.table.column.company.name" />
                    ),
                    dataKey: "companyName",
                    width: "40%",
                    searchEnabled: true,
                    filter: companyNameInputText,
                    sorting: "none",
                    sortingKey: "companyName"
                },
                {
                    key: "author",
                    title: (
                        <FormattedMessage id="details.table.column.fiscal.code" />
                    ),
                    dataKey: "author",
                    width: "20%",
                    searchEnabled: true,
                    filter: fiscalCodeInputText
                },
                {
                    key: "vatNumber",
                    title: (
                        <FormattedMessage id="details.table.column.vat.number" />
                    ),
                    dataKey: "vatNumber",
                    width: "20%",
                    searchEnabled: true,
                    filter: vatNumberInputText
                },
                {
                    key: "timestamp",
                    sorting: "none",
                    sortingKey: "timestamp",
                    title: (
                        <FormattedMessage id="details.table.column.timestamp" />
                    ),
                    dataKey: "timestamp",
                    width: "20%",
                    searchEnabled: true,
                    render: (value) =>
                        {
                            const fmt = 'DD/MM/YYYY HH:mm:ss';
                            let m = moment(value);
                            m.local();
                            return m.format(fmt);
                        }
                },
                {
                    key: "hubId",
                    title: <FormattedMessage id="details.table.column.id.transactions" />,
                    dataKey: "hubId",
                    width: "15%",
                    searchEnabled: true,
                    filter: hubIdInputText
                },
                {
                    key: "transactionsCount",
                    title: <FormattedMessage id="details.table.column.transactionsCount" />,
                    dataKey: "transactionsCount",
                    width: "10%",
                    searchEnabled: true
                },
                {
                    key: "source",
                    title: <FormattedMessage id="details.table.column.source" />,
                    dataKey: "source",
                    width: "10%",
                    searchEnabled: true
                }
            ];
        }
        case "ai": {
            return [
                {
                    key: "selected",
                    title: (
                        selectAllCheckbox
                    ),
                    dataKey: "selected",
                    searchEnabled: true,
                    width: "5%",
                },
                {
                    key: "slotFiscalCode",
                    title: <FormattedMessage id="details.table.column.owner" />,
                    dataKey: "slotFiscalCode",
                    searchEnabled: true,
                    width: "20%"
                },
               
                {
                    key: "slotStatus",
                    title: (
                        <FormattedMessage id="details.table.column.slot.status" />
                    ),
                    dataKey: "slotStatus",
                    searchEnabled: true,
                    width: "20%",
                },
                {
                    key: "dateUpdated",
                    title: (
                        <FormattedMessage id="details.table.column.slot.activation.date" />
                    ),
                    dataKey: "dateUpdated",
                    searchEnabled: true,
                    width: "10%",
                    render: (value) =>
                        {
                            const fmt = 'DD/MM/YYYY';
                            let m = moment(value);
                            m.local();
                            return m.format(fmt);
                        }
                },
                {
                    key: "slotId",
                    searchEnabled: true,
                    width: "5%",
                },
                {
                    key: "freeDate",
                    title: (
                        <FormattedMessage id="details.table.column.slot.end.date.locked" />
                    ),
                    dataKey: "freeDate",
                    searchEnabled: true,
                    width: "20%",
                    render: (value) => {
                        if (!value) {
                            return null;
                        }
                        
                        const fmt = 'DD/MM/YYYY';
                        let m = moment(value);
                        m.local();
                        return m.format(fmt);
                    }
                },
              
                {
                    key: "details",
                    title: (
                        <FormattedMessage id="details.table.column.details" />
                    ),
                    width: "20%",
                    dataKey: "slotId",
                    searchEnabled: true,
                    render: (slotId) => slotDetails(slotId),
                },
                
            ];
        }
        default: {
            return [];
        }
    }
};

export const getHistoryDetailsTableColumnsSpecification = (
    selectAllCheckbox,
) => {
    return [
        {
            key: "selected",
            title: (
                selectAllCheckbox
            ),
            dataKey: "selected",
            searchEnabled: false,
            width: "5%"
        },
        {
            key: "fileName",
            sorting: "none",
            sortingKey: "none",
            title: (
                <FormattedMessage id="historyDetails.table.column.fileName" />
            ),
            dataKey: "fileName",
            searchEnabled: false,
            width: "40%",
        },
        {
            key: "period",
            sorting: "none",
            sortingKey: "none",
            title: (
                <FormattedMessage id="historyDetails.table.column.period" />
            ),
            dataKey: "period",
            searchEnabled: false,
            width: "30%"
        },
        {
            key: "createdAt",
            title: <FormattedMessage id="historyDetails.table.column.createdAt" />,
            dataKey: "createdAt",
            searchEnabled: false,
            width: "20%",
            render: (value) =>
            {
                const fmt = 'DD/MM/YYYY HH:mm:ss';
                let m = moment(value);
                m.local();
                return m.format(fmt);
            }
        },
        {
            key: "exportCsv",
            align: "right",
            title: (
                <FormattedMessage id="historyDetails.table.column.exportCsv" />
            ),
            dataKey: "exportCsv",
            searchEnabled: false,
            width: "5%"
        }
    ];
};