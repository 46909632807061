import React, { useState, useEffect } from "react";
import { Flex, Box } from "reflexbox";
import {
    faSort,
    faSortUp,
    faSortDown,
} from "@fortawesome/free-solid-svg-icons";
import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { Input } from "../../input";

const TableHead = styled.th`
    padding: 0;
`;

const UpperPart = styled(Flex)`
    background: #fff;
    box-sizing: border-box;
    border: 1px solid #fff;
    transition: border 0.3s ease;
    overflow: hidden;
    :hover {
        border: 1px solid #0090D1;
    }
`;

const LowerPart = styled(Flex)`
    background: rgb(242, 245, 248);
    height: 40px;
`;

const BorderBottom = styled.div`
    height: 1px;
    width: 100%;
    background: rgb(157, 166, 173);
`;

const Label = styled(Box)`
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    color: #005075;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

const Icon = styled(Box)`
    cursor: pointer;
`;

export const Head = ({
    children,
    sorting,
    icon,
    filter,
    searchEnabled,
    showInputSearch,
    onSortingChange,
}) => {
    const [faIcon, setFaIcon] = useState(null);

    useEffect(() => {
        let icon;
        switch (sorting) {
            case "none": {
                icon = faSort;
                break;
            }
            case "ascend": {
                icon = faSortUp;
                break;
            }
            case "descend": {
                icon = faSortDown;
                break;
            }
            default: {
                icon = null;
            }
        }
        setFaIcon(icon);
    }, [sorting]);

    return (
        <TableHead>
            <Flex flexDirection="column">
                <UpperPart
                    height="48px"
                    alignItems="center"
                    color="rgb(0, 80, 117)"
                    px="8px"
                    onClick={onSortingChange}
                >
                    <Label>{children}</Label>
                    <Flex ml="8px">
                        {sorting && faIcon && (
                            <Icon mr="8px">
                                <FontAwesomeIcon icon={faIcon} />
                            </Icon>
                        )}
                        {icon && (
                            <Icon>
                                <FontAwesomeIcon icon={icon} />
                            </Icon>
                        )}
                    </Flex>
                </UpperPart>
                { searchEnabled && (
                <LowerPart>
                    <Box width="100%">
                        {filter ? (
                           filter
                        ) : showInputSearch ? (
                            <Input
                                placeholder="Cerca..."
                                icon={faSearch}
                                border={false}
                            />
                        ) : null}
                    </Box>
                </LowerPart>
                )}
                <BorderBottom />
            </Flex>
        </TableHead>
    );
};
