import styled, { css } from "styled-components";

const legibility = css`
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
`;

export const typographyVariants = {
    h1Lead: styled.h1`
        font-family: "Cairo";
        font-weight: 700;
        font-size: 72px;
        ${legibility}
    `,
    h2Lead: styled.h2`
        font-family: "Cairo";
        font-weight: 700;
        font-size: 60px;
        ${legibility}
    `,
    h3Lead: styled.h3`
        font-family: "Cairo";
        font-weight: 700;
        font-size: 50px;
        ${legibility}
    `,
    h1: styled.h1`
        font-family: "Cairo";
        font-weight: 700;
        font-size: 42px;
        line-height: 1.33;
        ${legibility}
    `,
    h2: styled.h2`
        font-family: "Cairo";
        font-weight: 700;
        font-size: 34px;
        line-height: 1.4;
        ${legibility}
    `,
    h3: styled.h3`
        font-family: "Cairo";
        font-weight: 700;
        font-size: 24px;
        line-height: 1.67;
        ${legibility}
    `,
    h4: styled.h4`
        font-family: "Roboto";
        font-weight: 600;
        font-size: 20px;
        line-height: 1.6;
        ${legibility}
    `,
    h5: styled.h5`
        font-family: "Roboto";
        font-weight: 600;
        font-size: 16px;
        line-height: 1.33;
        ${legibility}
    `,
    subtitleLarge: styled.span`
        font-family: "Roboto";
        font-weight: 600;
        font-size: 24px;
        line-height: 1.33;
        ${legibility}
    `,
    subtitleRegular: styled.span`
        font-family: "Roboto";
        font-weight: 600;
        font-size: 20px;
        line-height: 1.2;
        ${legibility}
    `,
    bodyLarge: styled.span`
        font-family: "Roboto";
        font-weight: 500;
        font-size: 16px;
        line-height: 1.5;
        ${legibility}
    `,
    bodyRegular: styled.span`
        font-family: "Roboto";
        font-weight: 500;
        font-size: 14px;
        line-height: 1.72;
        ${legibility}
    `,
    buttonsAndCtas: styled.span`
        font-family: "Roboto";
        font-weight: 600;
        font-size: 16px;
        line-height: 1;
        ${legibility}
    `,
    descriptive: styled.span`
        font-family: "Roboto";
        font-weight: 500;
        font-size: 12px;
        line-height: 1.33;
        ${legibility}
    `,
    descriptiveTag: styled.span`
        font-family: "Cairo", sans-serif;
        font-weight: 600;
        font-size: 12px;
        line-height: 1.33;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: center;
        ${legibility}
    `,
    eyebrow: styled.span`
        font-family: "Roboto";
        font-weight: 600;
        font-size: 16px;
        text-transform: uppercase;
        line-height: 1.5;
        ${legibility}
    `
};
