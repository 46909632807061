import { getRestCall } from "../../utils/rest-api-call";
import { REGISTRY_READ_API_URL } from "../../env";

export const GET_COMPANY_START = "GET_COMPANY_START";
export const GET_COMPANY_END = "GET_COMPANY_END";
export const GET_COMPANY_SUCCESS = "GET_COMPANY_SUCCESS";

export const getCompany = (item, accessToken, userId, refreshToken, email) => async (dispatch) => {
    dispatch({ type: GET_COMPANY_START });
    try {
        const response = await getRestCall(
            `${REGISTRY_READ_API_URL}/api/v3/items/${item}?packageType=BASE`,
                accessToken,
                null,
                dispatch,
                userId,
                item,
                refreshToken,
                email
        );
        dispatch({
            type: GET_COMPANY_SUCCESS,
            company: response,
        });
    } catch (error) {
        console.error("error getting company", error);
    } finally {
        dispatch({ type: GET_COMPANY_END });
    }
};