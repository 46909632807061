import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const TableActionIcon = styled(FontAwesomeIcon)`
    color: rgb(0, 144, 209);
    width: 40px;
    cursor: pointer;
`;

export const TableDetailActionIcon = styled(FontAwesomeIcon)`
    cursor: pointer;
    color: rgb(0, 144, 209);
    letter-spacing: 0px;
    text-align: center;
    width: 40px;
    margin-left: 80px;
    vertical-align: middle;
`;