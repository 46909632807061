import styled from "styled-components";
import { applyGradient } from "../../commons/styles/mixins/";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const CheckboxContainer = styled.div`
    display: flex;
    align-items: center;
    position: relative;
`;

export const StyledLabel = styled.label`
    margin-left: 8px;
    cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
    color: ${props =>
        props.disabled
            ? props => props.theme.colors.text.quickSilver
            : "black"};
    display: flex;
`;

export const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
    opacity: 0;
    position: absolute;
    width: 16px;
    height: 16px;
    margin: 0;
    cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
`;

export const StyledCheckbox = styled.div`
    ${props =>
        props.checked
            ? applyGradient(
                  props.theme.colors.primary.cyanProgress,
                  props.theme.colors.primary.richElectricBlue
              )
            : ""};
    background: ${props => (props.disabled ? "none" : "")};
    background-color: ${props =>
        props.disabled && props.checked
            ? props.theme.colors.primary.columbiaBlue
            : "none"};
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    border: 1px solid
        ${props =>
            props.disabled
                ? props => props.theme.colors.primary.columbiaBlue
                : props => props.theme.colors.primary.richElectricBlue};
    border-radius: 3px;
    transition: background 0.3s ease;

    ${HiddenCheckbox}:focus + & {
        box-shadow: 0 0 0 1px
            ${props =>
                props.disabled
                    ? "none"
                    : props.theme.colors.primary.cyanProgress};
    }
`;

export const StyledCheckBoxIcon = styled(FontAwesomeIcon)`
    color: ${props => props.theme.colors.background.white};
    height: 14px;
`;

export const StyledSpan = styled.span`
    margin-left: 12px;
`;
