import React from "react";
import { FormattedMessage } from "react-intl";

export const getFilters = (service) => {
    switch (service) {
        case "invoicing": {
            return [
                {
                    key: "all",
                    title: (
                        <FormattedMessage id="details.filters.all" />
                    ),
                    dataKey: "all",
                },
                {
                    key: "sent",

                    title: (
                        <FormattedMessage id="details.filters.sent" />
                    ),
                    dataKey: "sent",
                },
                {
                    key: "received",
                    title: (
                        <FormattedMessage id="details.filters.received" />
                    ),
                    dataKey: "received",
                },

            ];
        }
        case "ai": {
            return [
                {
                    key: "all",
                    title: (
                        <FormattedMessage id="details.filters.all" />
                    ),
                    dataKey: "all",
                },
                {
                    key: "ATTIVA",
                    value: "ATTIVA",
                    title: (
                        <FormattedMessage id="details.table.column.slot.status.attive" />
                    ),
                    dataKey: "ATTIVA",
                },
                {
                    key: "SOSPESA",
                    value: "SOSPESA",
                    title: (
                        <FormattedMessage id="details.table.column.slot.status.sospese" />
                    ),
                    dataKey: "SOSPESA",
                }

            ];
        }
        case "signature": {
            return [
                {
                    key: "all",
                    title: (
                        <FormattedMessage id="details.filters.chips.all" />
                    ),
                    dataKey: "all",
                },
                {
                    key: "otpCount",
                    title: (
                        <FormattedMessage id="details.filters.chips.sms" />
                    ),
                    dataKey: "otpCount",
                },
                {
                    key: "hasTimestamp",
                    title: (
                        <FormattedMessage id="details.filters.chips.hasTimestamp" />
                    ),
                    dataKey: "hasTimestamp",
                }
            ];
        }
        case "checkup": {
            return [
                {
                    key: "all",
                    title: (
                        <FormattedMessage id="details.filters.chips.all" />
                    ),
                    dataKey: "all",
                },
                {
                    key: "deleted",
                    title: (
                        <FormattedMessage id="details.filters.chips.deleted" />
                    ),
                    dataKey: "deleted",
                }
            ];
        }
        case "pay": {
            return [
                {key: "companyName", value: ""},
                {key: "fiscalCode", value: ""},
                {key: "vatNumber", value: ""},
                {key: "hubId", value: ""}
            ];
        }
        default: {
            return [];
        }
    }
};

export const getHistoryFilters = () => {
            return [
                {
                    key: "all",
                    title: (
                        <FormattedMessage id="historyDetails.filters.all" />
                    ),
                    dataKey: "all",
                },
                {
                    key: "completed",

                    title: (
                        <FormattedMessage id="historyDetails.filters.completed" />
                    ),
                    dataKey: "completed",
                },
                {
                    key: "tobecompleted",
                    title: (
                        <FormattedMessage id="historyDetails.filters.tobecompleted" />
                    ),
                    dataKey: "tobecompleted",
                },

            ];
};